import { useMemo, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";
import { ChevronDown } from "lucide-react";
import { Button } from "../shadcn/ui/button";

type SelectOptions = {
  value: string;
  label: string;
};
interface FilterProps {
  items: SelectOptions[];
  value?: string[];
  placeholder?: string;
  onValueChange?: (value: string[]) => void;
}
export const DropdownWithMultiSelected = ({
  items,
  value,
  placeholder = "DropDown Menu",
  onValueChange,
}: FilterProps) => {
  const [internalValue, setInternalValue] = useState<string[]>([]);

  const handleItemClick = function (item: SelectOptions) {
    const newValue = selectedValues.includes(item.value)
      ? selectedValues.filter((value) => value !== item.value)
      : [...selectedValues, item.value];
    if (onValueChange) {
      onValueChange(newValue);
    } else {
      setInternalValue(newValue);
    }
  };

  const selectedValues = useMemo(() => {
    return value !== undefined ? value : internalValue;
  }, [value, internalValue]);

  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="adminGhost"
          className={`justify-between font-semibold ${internalValue.length > 0 ? "text-admins-text-overview text-xs" : ""}`}
        >
          {internalValue.length > 0 ? internalValue.join(", ") : placeholder}
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)]">
        {items.map((item) => {
          return (
            <DropdownMenuCheckboxItem
              key={item.value}
              checked={selectedValues.includes(item.value)}
              onCheckedChange={() => handleItemClick(item)}
              onSelect={(e) => e.preventDefault()}
            >
              {item.label}
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
