import { VariantProps, cva } from "class-variance-authority";
import { XCircle } from "lucide-react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { cn } from "../../../lib/utils";

const colorIconVariants = cva("", {
  variants: {
    colorIcon: {
      green: "text-[#2CAD8F]",
      yellow: "text-[#EEB932]",
      red: "text-[#CE1126]",
      black: "text-black",
      none: "",
    },
  },
  defaultVariants: {
    colorIcon: "green",
  },
});
const colorBGVariants = cva("flex flex-row p-1 space-x-2", {
  variants: {
    bg_color: {
      default: "bg-[#E9E9E9] rounded-md",
      white: "bg-white rounded-lg border border-[#5BB79D]",
      red: "text-[#CE1126]",
    },
  },
  defaultVariants: {
    bg_color: "default",
  },
});
interface CustomTagProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof colorIconVariants>,
    VariantProps<typeof colorBGVariants> {
  tag2Show: string | undefined;
}

export const CustomTag = ({
  tag2Show,
  className,
  colorIcon,
  bg_color,
  onClick,
}: CustomTagProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(colorBGVariants({ bg_color, className }))}
    >
      <p className="text-xs">{tag2Show}</p>
      <XCircle
        size={64}
        className={cn(colorIconVariants({ colorIcon }), "w-4 h-4")}
      />
    </div>
  );
};
