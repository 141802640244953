import { appStoreBadgeWhite, playStoreBadgeWhite } from "../../../assets";
import React from "react";
import { useTranslations } from "next-intl";
import Image from "next/image";

export const FooterLatitudR = () => {
  const t = useTranslations();

  return (
    <section className="w-full flex flex-wrap py-12 space-y-8 pb-10">
      <p className="w-full text-center text-white font-semibold text-2xl">
        ¡Descarga la app y recicla ya!
      </p>
      <div className="w-full flex justify-center items-center align-middle ">
        <Image
          src={appStoreBadgeWhite}
          alt="app_store"
          className="mx-3"
          style={{
            width: "150px",
            cursor: "pointer",
          }}
          onClick={() =>
            window.open("https://ecolana.page.link/Crty", "_blank")
          }
        />
        <Image
          src={playStoreBadgeWhite}
          alt="play_store"
          className="mx-3"
          style={{
            width: "150px",
            cursor: "pointer",
          }}
          onClick={() =>
            window.open("https://ecolana.page.link/Crty", "_blank")
          }
        />
      </div>
    </section>
  );
};
