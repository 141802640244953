import { logoEcolana, papalote_qr, validation_image } from "../../../assets";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/image";
import { Fragment, useState } from "react";
import { DialogAdmins } from "@/components/atoms";
import { Dialog, DialogContent } from "@/components/atoms/shadcn/ui/dialog";

export const NavbarPapalote = () => {
  const router = useRouter();
  let changeNavBar = router.pathname === "/" || router.pathname === "/empresas";
  const [isOpen, setIsOpen] = useState(false);
  function handleOpenQR() {
    setIsOpen(!isOpen);
  }
  return (
    <>
      <nav
        className={`absolute w-full bg-[#41b59a] max-h-[72px] py-2 ${
          !changeNavBar ? "rounded-b-[50px] shadow-xl" : ""
        }`}
        style={{ zIndex: 50 }}
      >
        <div className="flex flex-wrap min-h-[30px] items-center justify-between mx-auto m-1 px-14">
          <Link
            className="w-3/12 md:w-1/6 lg:w-1/6 xl:w-1/6 2xl:w-1/12 max-h-full"
            href={"/papalote"}
            prefetch={false}
          >
            <Image
              src={logoEcolana}
              alt="Ecolana_logo_navbar"
              width={382}
              height={76}
              sizes="100vw"
              priority
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </Link>
          <button
            className={`animate-bounce w-3/5 flex justify-end space-x-8`}
            id="navbar-default"
            onClick={handleOpenQR}
          >
            <p className="block py-1 px-2 bg-[#41b59a] text-white font-bold lg:text-2xl text-md rounded">
              Descarga y gana con Ecolana App
            </p>
          </button>
        </div>
      </nav>
      <Dialog open={isOpen} onOpenChange={handleOpenQR}>
        <DialogContent
          className="max-h-svh max-w-4xl w-fit items-center rounded-3xl overflow-y-auto"
          style={{ zIndex: 80 }}
        >
          <Image
            src={papalote_qr}
            className="w-full max-h-96 max-w-96"
            alt="qr_ecolana_app"
            style={{ objectFit: "contain" }}
          />
        </DialogContent>{" "}
      </Dialog>
    </>
  );
};
