import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { LucideIcon } from "lucide-react";

const floatingActionButtonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300",
  {
    variants: {
      variant: {
        default:
          "w-fit bg-white hover:bg-white text-black font-extrabold py-2 rounded-full shadow-lg text-lg md:text-xl px-5",
      },
      side: {
        bottom_right: "fixed bottom-20 right-4",
        top_left: "fixed top-28 left-14",
        bottom_left: "fixed left-14 bottom-20",
        top_right: "fixed top-28 right-4",
        mixed_tl_md_br:
          "fixed bottom-20 right-4 md:top-28 md:left-14 md:bottom-0",
      },
    },
    defaultVariants: {
      variant: "default",
      side: "top_left",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof floatingActionButtonVariants> {
  asChild?: boolean;
  label?: string;
  iconToShow?: LucideIcon | JSX.Element | undefined;
}

const FloatingActionButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, side, asChild = false, label, iconToShow, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          floatingActionButtonVariants({ variant, side, className })
        )}
        ref={ref}
        {...props}
      >
        {side == "top_right" ||
        side == "bottom_right" ||
        side == "mixed_tl_md_br" ? (
          <>{iconToShow}</>
        ) : null}
        {label}
        {side == "top_left" ||
        side == "bottom_left" ||
        side == "mixed_tl_md_br" ? (
          <>{iconToShow}</>
        ) : null}
      </Comp>
    );
  }
);
FloatingActionButton.displayName = "FAButton";

export { FloatingActionButton, floatingActionButtonVariants };

("Card for unique waste");
