import { Calendar } from "@/components/atoms/shadcn/ui/calendar";
import { cn } from "@/lib/utils";
import React, { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { Matcher } from "react-day-picker";

interface DatePickerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const DatePicker = ({ className }: DatePickerProps) => {
  const [value, setValue] = useState<Date>();
  return (
    <div className={cn("bg-white rounded-xl", className)}>
      <Calendar
        mode="single"
        selected={value}
        onSelect={(e) => setValue(e)}
        disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
        initialFocus
      />
    </div>
  );
};
