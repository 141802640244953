import { Button } from "@/components/atoms/shadcn/ui/button";
import type { PaginationInterface } from "@/hooks";
import { cn } from "@/lib/utils";
import {
  ChevronFirst,
  ChevronLast,
  ChevronLeft,
  ChevronRight,
  Ellipsis,
} from "lucide-react";
import React, { DetailedHTMLProps, HTMLAttributes, useState } from "react";
interface PaginationFooter
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  pagination: PaginationInterface;
  setPageSizeTable: (pageSize: number) => void;
  showPageSize?: boolean;
}
export const PaginationFooter = ({
  className,
  pagination,
  showPageSize = false,
  setPageSizeTable,
}: PaginationFooter) => {
  return (
    <div
      className={cn(
        className,
        "flex flex-wrap items-center w-full justify-between px-5"
      )}
    >
      <span className="flex text-center items-center gap-1">
        {pagination?.paginationLabel}
      </span>
      {showPageSize && (
        <select
          value={pagination.pageSize}
          onChange={(e) => {
            setPageSizeTable(Number(e.target.value));
            pagination.onChangePageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
      )}
      <div className="flex flex-row">
        <Button
          variant={"adminPagination"}
          onClick={() => pagination.firstPage()}
        >
          <ChevronFirst className="text-admins-text-active" />
        </Button>
        <Button
          variant={"adminPagination"}
          onClick={() => pagination.prevPage()}
        >
          <ChevronLeft className="text-admins-text-active" />
        </Button>
        <Button variant={"adminPagination"} onClick={() => {}} disabled={false}>
          {pagination?.currentPage}
        </Button>
        <Button variant={"adminPagination"} onClick={() => {}} disabled={false}>
          <Ellipsis />
        </Button>
        <Button
          variant={"adminPagination"}
          onClick={() => pagination.lastPage()}
          disabled={false}
        >
          {pagination.last_page}
        </Button>
        <Button
          variant={"adminPagination"}
          onClick={() => pagination.nextPage()}
          disabled={false}
        >
          <ChevronRight className="text-admins-text-active" />
        </Button>
        <Button
          variant={"adminPagination"}
          onClick={() => pagination.lastPage()}
          disabled={false}
        >
          <ChevronLast className="text-admins-text-active" />
        </Button>
      </div>
    </div>
  );
};
