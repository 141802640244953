import { ConvertData } from "@/interfaces";
import { colors_admin } from "@/styles/fullConfig";

type DataInput = {
  label: string[];
  value: {
    label: string;
    value: number[];
  }[];
};

function getCorrectColors(label_graph: string | string[], num_label: number) {
  const color =
    label_graph == "Aceptada"
      ? colors_admin.graphics.approved_validations
      : label_graph == "Pendiente"
      ? colors_admin.graphics.pending_validations
      : label_graph == "Rechazada"
      ? colors_admin.graphics.rejected_validations
      : label_graph == "No binario"
      ? colors_admin.graphics.third_gender
      : label_graph == "Masculino"
      ? colors_admin.graphics.male
      : label_graph == "Femenino"
      ? colors_admin.graphics.female
      : label_graph == "Otro"
      ? colors_admin.graphics.other_gender
      : label_graph == "origin" || label_graph == "badges"
      ? colors_admin.graphics.approved_validations
      : label_graph == "18 - 24" || label_graph == "18-24"
      ? colors_admin.graphics.age_range_1
      : label_graph == "25 - 34" || label_graph == "25-34"
      ? colors_admin.graphics.age_range_2
      : label_graph == "35 - 44" || label_graph == "35-44"
      ? colors_admin.graphics.age_range_3
      : label_graph == "45 - 54" || label_graph == "45-55"
      ? colors_admin.graphics.age_range_4
      : label_graph == "55+" || label_graph == "56+"
      ? colors_admin.graphics.age_range_5
      : label_graph == "Centros de acopio"
      ? colors_admin.collection_centers
      : label_graph == "Puntos verdes"
      ? colors_admin.green_points
      : label_graph == "Escaneos con insignia"
      ? colors_admin.graphics.scans_with_badge
      : label_graph == "Escaneos sin insignia"
      ? colors_admin.graphics.scans_without_badge
      : label_graph == "Escaneos totales"
      ? colors_admin.graphics.total_scans
      : label_graph == "Acopio exclusivo"
      ? colors_admin.collectors
      : // : colors_admin.graphics.approved_validations;
        Object.values(colors_admin.graphics)[num_label];
  return color;
}

function sortBySum(data: DataInput, topN: number): DataInput {
  const { label, value } = data;

  // Calcula las sumas para cada label
  const combined = label.map((lbl, i) => {
    const sum = value.reduce((acc, curr) => acc + curr.value[i], 0); // Suma de todos los géneros
    return {
      label: lbl,
      sum: sum,
      values: value.map((g) => g.value[i]), // Mantiene los valores en su orden original
    };
  });

  // Ordena por la suma en orden descendente
  combined.sort((a, b) => b.sum - a.sum);

  // Extrae los topN labels y los valores ordenados
  const sortedLabels = combined.slice(0, topN).map((item) => item.label);
  const sortedValues = value.map((_, index) => ({
    label: value[index].label,
    value: combined.slice(0, topN).map((item) => item.values[index]),
  }));

  // Retorna la estructura de salida
  const output: DataInput = {
    label: sortedLabels,
    value: sortedValues,
  };

  return output;
}

function getSortedIndices(arr: string[]): number[] {
  // Convertir el array de strings a números
  const numArray = arr.map(Number);

  // Crear un array de índices
  const indices = numArray.map((_, index) => index);

  // Ordenar los índices basándose en los valores del array original
  indices.sort((a, b) => numArray[a] - numArray[b]);

  return indices;
}

const labels_line: { [key: string]: string } = {
  "1": "Ene",
  "2": "Feb",
  "3": "Mar",
  "4": "Abr",
  "5": "May",
  "6": "Jun",
  "7": "Jul",
  "8": "Ago",
  "9": "Sep",
  "10": "Oct",
  "11": "Nov",
  "12": "Dic",
};

export const generateGraphic = (
  data: ConvertData | undefined | null,
  title: string,
  complex = false,
  horizontalStacked = false
) => {
  if (!data) return { labels: [], datasets: [] };
  if (horizontalStacked) {
    const values = data[title] as unknown as {
      label: string[];
      value: {
        label: string;
        value: number[];
      }[];
    };
    if (!values) return { labels: [], datasets: [] };
    const output_algorithm = sortBySum(values, 10);
    if (title == "validation_center_types") {
      const sorted_index = getSortedIndices(values?.label);
      return {
        labels: sorted_index.map(
          (uni_idx) => labels_line[values?.label[uni_idx]]
        ),
        datasets: values?.value?.map((item, idx) => ({
          label: item.label,
          data: sorted_index.map((uni_idx) => item.value[uni_idx]),
          backgroundColor: getCorrectColors(item.label, idx),
          borderColor: getCorrectColors(item.label, idx),
          tension: 0.2,
        })),
      };
    } else {
      return {
        labels: output_algorithm?.label.map(
          (uni_label) => labels_line[uni_label] ?? uni_label
        ),
        datasets: output_algorithm?.value.map((item, index) => ({
          label: labels_line[item.label] ?? item.label,
          data: item.value as number[],
          backgroundColor: getCorrectColors(item.label, index),
          borderColor: getCorrectColors(item.label, index),
          tension: 0.2,
        })),
      };
    }
  } else {
    let datasets_complex: any = [];
    if (!complex) {
      let labels_complex: string[] = [];
      let data_complex: number[] = [];
      let backgroundColor_complex: string[] = [];
      // Primero ordenamos los datos por valor en orden descendente
      const sortedData = data[title]
        ?.slice()
        .sort((a, b) => Number(b.value) - Number(a.value));

      sortedData?.forEach((item) => {
        typeof item.label == "string" ? labels_complex.push(item.label) : null;
        data_complex.push(Number(item.value));
        backgroundColor_complex.push(getCorrectColors(item.label, 1));
      });
      datasets_complex = [
        {
          label: title,
          data: data_complex,
          backgroundColor: backgroundColor_complex,
        },
      ];
      const return_complex_data = {
        labels: labels_complex,
        datasets: datasets_complex,
      };
      return return_complex_data;
    } else {
      datasets_complex =
        data[title]?.map((item, index) => ({
          label: item.label,
          data: item.value as number[],
          backgroundColor: getCorrectColors(item.label, index),
        })) ?? [];
      const return_data = {
        labels: data[title]?.map((item) => item.label) ?? [],
        datasets: datasets_complex,
      };
      return return_data;
    }
  }
};
