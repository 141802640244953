import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../atoms/shadcn/ui/carousel";
import { Card, CardContent } from "../../atoms/shadcn/ui/card";
import { FC, useRef } from "react";
import { CampaignProps, Campaigns } from "@/constants/campaignsData";
import Link from "next/link";
import { useTranslations } from "next-intl";
import Image from "next/image";
interface CardPerCampaignInterface {
  campaigns: CampaignProps[];
}
const CardPerCampaign: FC<CardPerCampaignInterface> = ({ campaigns }) => {
  const plugin = useRef(Autoplay({ delay: 2000, stopOnInteraction: true }));
  let sizeCampaigns: number = campaigns.length;
  let basisDenominator =
    sizeCampaigns % 2 > 0
      ? Math.floor(sizeCampaigns / 2 + 1)
      : sizeCampaigns / 2;
  let showCarousel = campaigns.length > 10;
  function getCarouselLGScreen() {
    return Array.from({ length: basisDenominator }).map((_, index) => (
      <CarouselItem className={`p-1 basis-1/5`} key={index}>
        <div className="p-1">
          <Card className="p-1 m-1">
            <CardContent className="flex aspect-square items-center justify-center p-6 align-middle">
              <Link
                href={`${
                  campaigns[index * 2].slug == "latitud-r"
                    ? "/latitud-r"
                    : `/campana/${campaigns[index * 2].slug}`
                }`}
              >
                <Image
                  src={campaigns[index * 2].logo}
                  className="w-full"
                  alt={`${campaigns[index * 2].name}_logo`}
                />
              </Link>
            </CardContent>
          </Card>
          <Card
            className={`p-1 m-1 ${
              campaigns[index * 2] == undefined ? "hidden" : ""
            }`}
          >
            <CardContent className="flex aspect-square items-center justify-center align-middle p-6">
              <Link
                href={`${
                  campaigns[index * 2].slug == "latitud-r"
                    ? "/latitud-r"
                    : `/campana/${campaigns[index * 2].slug}`
                }`}
              >
                <Image
                  src={campaigns[index * 2].logo}
                  className=" w-full"
                  alt={`${campaigns[index * 2].name}_logo`}
                />
              </Link>
            </CardContent>
          </Card>
        </div>
      </CarouselItem>
    ));
  }
  if (!showCarousel) {
    return campaigns.map((_, index) => (
      <div className={`p-5  basis-1/${basisDenominator}`} key={index}>
        <div className="rounded-3xl shadow-md shadow-[#00000040] flex aspect-square items-center justify-center align-middle bg-white border-2 border-[#d0d0d0]">
          <Link
            href={`${
              campaigns[index].slug == "latitud-r"
                ? "/latitud-r"
                : `/campana/${campaigns[index].slug}`
            }`}
            className="w-3/4 flex items-center justify-center"
          >
            <Image
              src={campaigns[index].logo}
              className="w-full"
              alt={`${campaigns[index].name}_logo`}
            />
          </Link>
        </div>
      </div>
    ));
  } else {
    return (
      <Carousel
        opts={{
          loop: true,
          align: "start",
        }}
        plugins={[plugin.current]}
        className="w-full"
      >
        <CarouselContent>{getCarouselLGScreen()}</CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    );
  }
};

const CarouselItemPerCampaign: FC<CardPerCampaignInterface> = ({
  campaigns,
}) => {
  let sizeCampaigns: number = campaigns.length;
  let denominator =
    sizeCampaigns % 2 > 0
      ? Math.floor(sizeCampaigns / 2 + 1)
      : sizeCampaigns / 2;
  return Array.from({ length: denominator }).map((_, index) => (
    <CarouselItem className="basis-1/2" key={index}>
      <div className="p-1 space-y-10">
        <div className="rounded-3xl shadow-md shadow-[#00000040] flex aspect-square items-center justify-center align-middle bg-white border-2 border-[#d0d0d0]">
          <Link
            href={`${
              campaigns[index * 2].slug == "latitud-r"
                ? "/latitud-r"
                : `/campana/${campaigns[index * 2].slug}`
            }`}
            className="w-3/4 flex items-center justify-center"
          >
            <Image
              src={campaigns[index * 2].logo}
              className="w-full"
              alt={`${campaigns[index * 2].name}_logo`}
            />
          </Link>
        </div>
        <div
          className={`rounded-3xl shadow-md shadow-[#00000040] flex aspect-square items-center justify-center align-middle bg-white border-2 border-[#d0d0d0] ${
            campaigns[index * 2 + 1] == undefined ? "hidden" : ""
          }`}
        >
          {campaigns[index * 2 + 1] != undefined && (
            <Link
              href={`${
                campaigns[index * 2 + 1].slug == "latitud-r"
                  ? "/latitud-r"
                  : `/campana/${campaigns[index * 2 + 1].slug}`
              }`}
              className="w-3/4 flex items-center justify-center"
            >
              <Image
                src={campaigns[index * 2 + 1].logo}
                className="w-full"
                alt={`${campaigns[index * 2 + 1].name}_logo`}
              />
            </Link>
          )}
        </div>
      </div>
    </CarouselItem>
  ));
};

export const CardsCampaigns: FC = () => {
  const plugin = useRef(Autoplay({ delay: 2000, stopOnInteraction: true }));
  const campaignsActive: CampaignProps[] = Campaigns.filter(
    (uniCampaign) => uniCampaign.active
  );

  const t = useTranslations();

  return (
    <section className="flex flex-wrap justify-center w-full justify-items-center transition-all duration-500 ease-in-out bg-transparent px-14">
      <div className="py-14 w-full">
        <h1 className="h-fit w-full text-balance text-3xl lg:text-5xl text-center text-[#2cad8f] font-extrabold ">
          {t("cardsCampaignTitle")}
        </h1>
      </div>
      <div className="hidden lg:flex w-full flex-wrap  justify-center justify-items-center ">
        <CardPerCampaign campaigns={campaignsActive} />
      </div>
      <div className="flex w-full justify-center items-center lg:hidden">
        <Carousel
          opts={{
            loop: true,
            align: "start",
          }}
          plugins={[plugin.current]}
          className="w-full"
        >
          <CarouselContent>
            <CarouselItemPerCampaign campaigns={campaignsActive} />
          </CarouselContent>
          <CarouselPrevious className="bg-white fill-white" />
          <CarouselNext className="bg-white fill-white" />
        </Carousel>
      </div>
    </section>
  );
};
