import {
  Carousel,
  CarouselContent,
  CarouselPrevious,
  CarouselNext,
  CarouselItem,
} from "@/components/atoms/shadcn/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import React, { useRef } from "react";
import { MarkerCardCarousel } from "@/components/atoms";

export const CarouselWithMarkerCards = ({
  markers = Array.from(Array(11).keys()),
  pluginFlag = false,
}: {
  markers?: Array<Number>;
  pluginFlag?: Boolean;
}) => {
  const plugin = useRef(Autoplay({ delay: 2000, stopOnInteraction: true }));
  return (
    <div className="flex w-full justify-center px-16 md:px-10 xl:px-24 items-center">
      <Carousel
        opts={{
          loop: true,
          align: "start",
        }}
        plugins={pluginFlag ? [plugin.current] : []}
        className="w-full"
      >
        <CarouselContent className="items-center">
          {markers.map((_, idx) => (
            <CarouselItem key={`${idx}-marker`} className="flex justify-center  w-full min-w-28">
              <MarkerCardCarousel nameCollectionCenter={String(idx)} />
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious className="bg-white fill-white" />
        <CarouselNext className="bg-white fill-white" />
      </Carousel>
    </div>
  );
};
