import { DialogAdmins } from "@/components/atoms";
import { Label } from "@/components/atoms/shadcn/ui/label";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { RejectedValidation } from "@/interfaces/validationsInterfaces";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { RejectedOptionsId } from "@/interfaces";

type DialogRejectedProps = {
  isOpen: boolean;
  onClose?: (() => void) | undefined;
  control: Control<RejectedValidation>;
  watch: UseFormWatch<RejectedValidation>;
  errors: FieldErrors<RejectedValidation>;
  rejectedOptions: RejectedOptionsId[];
  handleSubmit?: () => void;
  handleSubmitNewRejectedOption?: () => void;
};
export const DialogRejected = ({
  isOpen,
  onClose,
  control,
  watch,
  errors,
  rejectedOptions,
  handleSubmit,
  handleSubmitNewRejectedOption,
}: DialogRejectedProps) => {
  const new_category = watch("new_rejected_category");
  const rejected_category = watch("rejected_category");
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Motivo de rechazo"
      variant={"red"}
    >
      <div className="grid grid-cols-4 gap-6">
        <div className="col-span-2  flex flex-col justify-center gap-2">
          <Label
            className="px-3 text-admins-text-active text-sm"
            htmlFor="textArea"
          >
            Crear un nuevo motivo de rechazo
          </Label>
          <Controller
            name="new_rejected_category"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Textarea
                  placeholder="Nuevo motivo de rechazo"
                  variant={"adminWhiteBorder"}
                  className="rounded-lg p-2"
                  name={name}
                  value={value}
                  onChange={onChange}
                />
                <p>{errors.new_rejected_category?.message}</p>
              </>
            )}
          />
        </div>
        <div className="col-span-2 flex flex-col justify-center items-center">
          <Label
            htmlFor="rejected_category"
            className=" text-admins-text-active"
          >
            Selecciona el motivo de rechazo
          </Label>
          <Controller
            name="rejected_category"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <SelectWithDefault
                  value={value}
                  name={name}
                  setValue={onChange}
                  selectedItems={rejectedOptions}
                  placeHolder="Seleccionar"
                  className="w-full"
                  variant={"adminGreen"}
                  disabled={new_category.length > 0}
                />
                <p>{errors.rejected_category?.message}</p>
              </>
            )}
          />
        </div>
        <Button
          onClick={handleSubmitNewRejectedOption}
          variant={"adminGray"}
          className="col-span-2 "
          disabled={new_category == "" }
        >
          Guardar Nuevo motivo
        </Button>

        <Button
          onClick={handleSubmit}
          variant={"adminGray"}
          className="col-span-2 "
          disabled={rejected_category == ""}
        >
          Rechazar
        </Button>
      </div>
    </DialogAdmins>
  );
};
