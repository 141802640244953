import { Toaster } from "../../atoms/shadcn/ui/toaster";
import Head from "next/head";

import { AvenirNextedium, GothamRounded } from "@/fonts";
import { Sidebar } from "@/components/molecules";

export const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Head>
        <title>Ecolana | Admins</title>
        <meta name="description" content="Ecolana" />
      </Head>
      <main
        className={`flex flex-col min-h-svh min-w-svw ${AvenirNextedium.variable} ${GothamRounded.variable} bg-white `}
      >
        <Sidebar />
        <section className="flex-grow sm:ml-64 min-h-screen bg-white relative">
          <div className="z-0 flex w-full absolute bg-[#5BB79D] h-44"></div>
          {children}
        </section>
      </main>
      <Toaster />
    </>
  );
};
