import { ecolanitaLatitud } from "../../../assets";
import { StepCard } from "../../atoms/StepCard/StepCard";
import { app_home_url } from "@/constants";
import Image from "next/image";
type textStepType = {
  textCard: string;
  isBtn?: boolean;
  textBtn?: string;
  linkBtn?: string;
};

const textStepsLatitudR: textStepType[] = [
  {
    textCard: "Descarga ecolana app",
    isBtn: true,
    textBtn: "Descarga",
    linkBtn: app_home_url,
  },
  {
    textCard: "Separa tus residuos",
    isBtn: false,
  },
  {
    textCard: "Identifica al recolector de base con chaleco empadronado",
    isBtn: false,
  },
  {
    textCard:
      "Escanea el código QR que te muestre y conoce los residuos que recibe",
    isBtn: false,
  },
  {
    textCard: "Entrega tus residuos y selecciónalos en ecolana app",
    isBtn: false,
  },
  {
    textCard: "Ingresa el pesaje que el recolector te indique en la app",
    isBtn: false,
  },
  {
    textCard: "¡Listo! ¡Ganaste ecolanitas por ayudar a tu recolector!",
    isBtn: false,
  },
];
export const StepsLatitudR = () => {
  return (
    <section className="flex flex-col items-center justify-center space-y-10 py-12 px-4">
      <h2 className="text-center font-extrabold text-2xl lg:text-2xl text-[#19dc69]">
        ¿CÓMO FUNCIONA?
      </h2>
      <div className="w-full flex flex-wrap justify-center align-middle">
        {textStepsLatitudR.map((uniStep, idx: number) => (
          <StepCard
            key={`${idx}_${uniStep}`}
            numStep={idx + 1}
            textCard={uniStep.textCard}
            isBtn={uniStep.isBtn}
            textBtn={uniStep.textBtn}
            linkBtn={uniStep.linkBtn}
          />
        ))}
      </div>
      <h2 className="w-1/2 text-center font-extrabold text-xl text-[#19dc69]">
        Intercambia tus ecolanitas por boletos del cine gratis y más beneficios
      </h2>
      <Image className="aspect-square w-28" src={ecolanitaLatitud} alt="" />
    </section>
  );
};
