import { Input, InputProps } from "@/components/atoms/shadcn/ui/input";
import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { Search } from "lucide-react";
import React, { FC } from "react";
import { useDebounceCallback } from "usehooks-ts";
interface SearcherProps
  extends InputProps,
    VariantProps<typeof SearcherVariants> {
  iconFirst?: boolean;
}

export const SearcherVariants = cva("w-96 items-center justify-around", {
  variants: {
    variantType: {
      adminGray:
        "bg-[#F1F1F1]  text-[#8A8F96] flex flex-wrap rounded-2xl px-3 focus-visible:outline-none ring-offset-transparent bg-admins-input-disabled_bg placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
      adminWhite:
        "focus-visible:outline-none ring-offset-transparent bg-white placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
      adminGhost:
        "bg-transparent text-[#8A8F96] flex flex-wrap rounded-2xl px-3 focus-visible:outline-none ring-offset-transparent  placeholder:text-admins-text-inactive text-admins-text-inactive disabled:cursor-not-allowed disabled:opacity-50",
    },
  },
  defaultVariants: {
    variantType: "adminGray",
  },
});

export const Searcher: FC<SearcherProps> = ({
  onChange,
  placeholder = "Buscar",
  iconFirst,
  variant = "adminGhost",
  variantType,
  className,
  ...props
}) => {
  const debounced = useDebounceCallback(onChange ? onChange : () => {}, 500);
  return (
    <div className={cn(SearcherVariants({ variantType, className }))}>
      <Input
        className={`flex w-10/12 ${iconFirst ? "order-2" : "order-1"} `}
        variant={variant}
        placeholder={placeholder}
        onChange={debounced}
        {...props}
      />
      <Search
        className={`text-[#9DA1AA] w-5 h-5 ${
          iconFirst ? "order-1" : "order-2"
        }`}
      />
    </div>
  );
};
