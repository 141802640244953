import { CustomTag } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Sheet, SheetContent } from "@/components/atoms/shadcn/ui/sheet";
import {
  ControlledSelectWithDefault,
  ControlledSelectWithSearch,
  Searcher,
} from "@/components/molecules";
import { CampaignsOptions } from "@/constants/campaignsData";
import { FormFilterValuesCCInventory } from "@/hooks";
import { States, Waste, WasteId } from "@/interfaces";
import { Control, Controller, UseFormReset, useForm } from "react-hook-form";
type FilterSideBarProps = {
  isOpen?: boolean;
  handleCloseSheet: () => void;
  control: Control<FormFilterValuesCCInventory, any>;
  states: States[];
  wastes: WasteId[];
  campaigns: CampaignsOptions[];
  handleResetFilterBar: () => void;
  handleSearch: () => void;
};
export const FilterSideBar = ({
  isOpen,
  handleCloseSheet,
  control,
  campaigns,
  wastes,
  states,
  handleResetFilterBar,
  handleSearch,
}: FilterSideBarProps) => {
  return (
    <Sheet open={isOpen} onOpenChange={handleCloseSheet}>
      <SheetContent
        className="overflow-y-auto"
        side={"left"}
        bg={"transparent"}
      >
        <ContentFilterSideBar
          handleCloseSheet={handleCloseSheet}
          control={control}
          campaigns={campaigns}
          wastes={wastes}
          states={states}
          handleResetFilterBar={handleResetFilterBar}
          handleSearch={handleSearch}
        />
      </SheetContent>
    </Sheet>
  );
};

export const ContentFilterSideBar = ({
  control,
  handleResetFilterBar,
  campaigns,
  wastes,
  states,
  handleSearch,
}: FilterSideBarProps) => {
  return (
    <div className="flex flex-col gap-6 rounded-lgp-2 ">
      <div className="w-full flex flex-wrap justify-end">
        <Button
          variant={"adminGhost"}
          className="font-bold hover:text-admins-text-active"
          onClick={() => handleResetFilterBar()}
        >
          Borrar
        </Button>
      </div>
      <div className="flex flex-wrap gap-2 w-full">
        <Label className="text-admins-text-active">Filtrar por Nombre/ID</Label>
        <Controller
          name={"search"}
          control={control}
          render={({ field: { onChange } }) => (
            <Searcher className="w-full lg:w-96" onChange={onChange} />
          )}
        />
      </div>
      <div className="flex flex-wrap gap-2 w-full">
        <Label className="text-admins-text-active">
          Filtrar por Tipo de Centro
        </Label>
        <ControlledSelectWithDefault
          control={control}
          name="type_collection_center_id"
          placeholder="Tipo de centro"
          selectedItems={[
            { id: "centro_acopio", name: "Centro de Acopio" },
            { id: "punto_verde", name: "Punto Verde" },
            { id: "acopio_exclusivo", name: "Acopio Exclusivo" },
          ]}
        />
      </div>
      <div className="flex flex-wrap gap-2 w-full">
        <Label className="text-admins-text-active">Filtrar por Residuo</Label>
        <ControlledSelectWithSearch
          name="wastes"
          control={control}
          selectedItems={wastes}
          placeholder="Residuos"
          addToList
        />
        <div className="w-full flex flex-wrap gap-2">
          <Controller
            control={control}
            name="wastes"
            render={({ field: { name, onChange, value } }) => (
              <>
                {wastes
                  .filter((uni_waste) => value.includes(uni_waste.id))
                  .map((uniSelected, index) => (
                    <CustomTag
                      bg_color={"white"}
                      colorIcon={"black"}
                      onClick={() =>
                        onChange(value.filter((uni) => uni !== uniSelected.id))
                      }
                      className="cursor-pointer"
                      tag2Show={uniSelected.name}
                      key={`waste_${uniSelected.name}_customTag_${index}`}
                    />
                  ))}
              </>
            )}
          />
        </div>
      </div>
      <div className="flex flex-wrap gap-2 w-full">
        <Label className="text-admins-text-active">Filtrar por Estado</Label>
        <ControlledSelectWithDefault
          control={control}
          name="state"
          placeholder="Estado"
          selectedItems={states}
        />
      </div>
      <div className="flex flex-wrap gap-2 w-full">
        <Label className="text-admins-text-active">Filtrar por Campaña</Label>
        <ControlledSelectWithDefault
          name="campaign"
          control={control}
          selectedItems={campaigns}
          placeholder="Campaña"
        />
        {/* <div className="w-full flex flex-wrap gap-2">
          <Controller
            control={control}
            name="campaign"
            render={({ field: { name, onChange, value } }) => (
              <>
                {campaigns
                  .filter((uni_waste) => value.includes(uni_waste.id))
                  .map((uniSelected, index) => (
                    <CustomTag
                      bg_color={"white"}
                      colorIcon={"black"}
                      onClick={() =>
                        onChange(value.filter((uni) => uni !== uniSelected.id))
                      }
                      className="cursor-pointer"
                      tag2Show={uniSelected.name}
                      key={`${uniSelected.name}_customTag_${index}_campaign`}
                    />
                  ))}
              </>
            )}
          />
        </div> */}
      </div>

      <Button
        variant={"adminGreen"}
        className="font-bold w-full hover:text-admins-text-active"
        onClick={handleSearch}
      >
        Buscar
      </Button>
    </div>
  );
};
