import { Product } from "@/interfaces";
import React from "react";
import { Control, Controller, useWatch } from "react-hook-form";
import { ValidationsImages } from "../ValidationsImages/ValidationsImages";
import { InputImages } from "../InputImages/InputImages";
import { validation_image } from "@/assets";

type ControlledInputImageProps = {
  control: Control<Product, any>;
  idx: number;
  isEditable?: boolean;
};

export function ControlledInputImageProduct({
  idx,
  control,
  isEditable,
}: ControlledInputImageProps) {
  const output_product = useWatch({ name: "product_wastes", control });
  if (output_product && !output_product[idx]?.image && isEditable) {
    return (
      <Controller
        name={`product_wastes.${idx}.file`}
        control={control}
        render={({ field: { onChange, value } }) => {
          return <InputImages value={value} onChange={onChange} />;
        }}
      />
    );
  }
  return (
    <Controller
      name={`product_wastes.${idx}.image`}
      control={control}
      render={({ field: { value } }) => {
        return <ValidationsImages image={value ?? validation_image} />;
      }}
    />
  );
}
