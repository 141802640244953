import { FooterRe, NavbarRe } from "@/components/organisms";
import { AvenirNextedium, GothamRounded } from "@/fonts";
import { greenLogoEcolana } from "@/assets";
import Head from "next/head";
import { DataScripts } from "@/components/DataScripts";
export const ReLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Head>
        <title>Trash-Later| Mapa</title>
        <meta
          name="description"
          content="Conoce Trsh-Later. El primer traductor de basura de Néstle."
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <meta name="og:title" content="Trash-Later| Mapa" />
        <meta
          name="og:description"
          content="Conoce Trsh-Later. El primer traductor de basura de Néstle."
        />
        <meta name="og:image" content={greenLogoEcolana.src} />
        <meta name="og:url" content={`https://ecolana.com.mx/re`} />
        <meta name="og:type" content="website" />
        <meta name="og:locale" content="es_MX" />
        <meta name="og:site_name" content="Ecolana" />
        <meta name="twitter:title" content="Trash-Later| Mapa" />
        <meta
          name="twitter:description"
          content="Conoce Trsh-Later. El primer traductor de basura de Néstle."
        />
        <meta name="twitter:image" content={greenLogoEcolana.src} />
        <meta name="twitter:card" content="summary" />
      </Head>
      <main
        className={`flex flex-col w-full min-h-svh ${AvenirNextedium.variable} ${GothamRounded.variable}
        `}
      >
        <NavbarRe />
        <div className="flex-grow">{children}</div>
        <FooterRe />
      </main>
      <DataScripts />
    </>
  );
};
