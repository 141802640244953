import { CardPerWaste } from "@/components/atoms";
import { Waste } from "@/interfaces/wastesInterface";
import { cn } from "@/lib/utils";
import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { HTMLAttributes } from "react";
const wasteSectionsVariants = {
  recyclable: {
    sectionTitle: "Reciclables",
    colorText: "#2CAD8F",
  },
  specialHandling: {
    sectionTitle: "Manejo especial",
    colorText: "#EEB932",
  },
  nonRecyclable: {
    sectionTitle: "No reciclables",
    colorText: "#CE1126",
  },
  acceptedWastes: {
    sectionTitle: "RECICLA ESTOS RESIDUOS JUNTO CON TU RECICLADOR DE BASE",
    colorText: "#283255",
  },
  none: {
    sectionTitle: "Tipos de residuos",
    colorText: "#000000",
  },
};

const wasteColorVariants = cva("", {
  variants: {
    colorTitle: {
      default: "text-black",
      recyclable: "text-[#2CAD8F]",
      specialHandling: "text-[#EEB932]",
      nonRecyclable: "text-[#CE1126]",
      acceptedWastes: "text-[#283255]",
    },
  },
  defaultVariants: {
    colorTitle: "default",
  },
});

interface WasteSectionsProps
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof wasteColorVariants> {
  typeOfWaste:
    | "recyclable"
    | "specialHandling"
    | "nonRecyclable"
    | "acceptedWastes"
    | "none";
  wastes: Waste[] | [];
  subtitleOn?: boolean;
}

export const WasteSections = ({
  colorTitle,
  typeOfWaste,
  wastes,
  subtitleOn = true,
  className,
}: WasteSectionsProps) => {
  const configStyles = wasteSectionsVariants[typeOfWaste];
  return (
    <section
      className={cn(
        "bg-white flex flex-wrap gap-8 justify-center px-5",
        className
      )}
    >
      <div className="text-center w-full ">
        <h2
          className={cn(
            wasteColorVariants({ colorTitle }),
            "font-extrabold text-2xl "
          )}
        >
          {configStyles.sectionTitle}
        </h2>
        {subtitleOn && (
          <p className="text-[#666] text-xl font-medium">
            Selecciona el residuo que quieres conocer
          </p>
        )}
      </div>
      {wastes.map((waste: Waste, idx: number) => (
        <Link
          key={`${idx}-${typeOfWaste}-web`}
          href={`/diccionario/${waste.slug}`}
        >
          <CardPerWaste
            key={`${idx}-${typeOfWaste}`}
            hoverBgColor={
              typeOfWaste == "recyclable" || typeOfWaste == "acceptedWastes"
                ? "green"
                : typeOfWaste == "specialHandling"
                ? "yellow"
                : typeOfWaste == "nonRecyclable"
                ? "red"
                : "none"
            }
            nameWaste={`${waste.name}`}
            imgWaste={waste.icon}
          />
        </Link>
      ))}
    </section>
  );
};
