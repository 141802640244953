import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../atoms/shadcn/ui/carousel";
import {
  adn40Logo,
  entrepreneurLogo,
  forbesLogo,
  foroTvLogo,
  heraldoMexicoLogo,
  theHappeningLogo,
  elUniversalLogo,
} from "../../../assets";
import { useRef } from "react";
import { useTranslations } from "next-intl";
import Image from "next/image";

const pressArray = [
  {
    img: adn40Logo,
    class: "w-2/3",
  },
  {
    img: forbesLogo,
    class: "",
  },
  {
    img: entrepreneurLogo,
    class: "",
  },
  {
    img: foroTvLogo,
    class: "w-2/3",
  },
  {
    img: heraldoMexicoLogo,
    class: "",
  },
  {
    img: theHappeningLogo,
    class: "",
  },
  {
    img: elUniversalLogo,
    class: "",
  },
];

const PressItems = () => {
  return pressArray.map((_, index) => (
    <CarouselItem
      className="flex basis-1/2 lg:basis-1/5 pl-10 lg:pl-28 items-center justify-center max-h-36"
      key={index}
    >
      <Image
        className={`${pressArray[index].class}`}
        src={pressArray[index].img}
        alt=""
      />
    </CarouselItem>
  ));
};

export const CarouselPress = () => {
  const t = useTranslations();

  const plugin = useRef(Autoplay({ delay: 2000, stopOnInteraction: true }));
  return (
    <section className="flex flex-col px-0 lg:px-5 py-10 bg-transparent">
      <h2 className="basis-full my-8 text-center text-2xl lg:text-5xl text-ecolana-titlesGreen font-bold leading-5">
        {t("carouselPressTitle")}
      </h2>
      <div className="flex w-full flex-wrap  justify-center justify-items-center px-2 lg:px-14">
        <Carousel plugins={[plugin.current]} opts={{ loop: true }}>
          <CarouselContent className="-ml-2 md:-ml-4">
            <PressItems />
          </CarouselContent>
          <CarouselPrevious className="bg-white fill-white hidden lg:flex" />
          <CarouselNext className="bg-white fill-white hidden lg:flex" />
        </Carousel>
      </div>
    </section>
  );
};
