import {
  wazeIcon,
  googlemapsIcon,
  ecolanitaMapDialogImg,
  centroAcopioLogo,
} from "../../../assets";
import { CustomTag } from "@/components/atoms";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/atoms/shadcn/ui/tabs";
import { baseURL, app_home_url } from "@/constants";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import Link from "next/link";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { PropsCenter } from "@/interfaces/collectionCenterInterface";
import {
  faLeaf,
  faLocationDot,
  faMoneyBill1,
  faPhone,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { CarouselAdvertisement } from "../CarouselAdvertisement/CarouselAdvertisement";
import Image from "next/image";
type MobileInfoWastesProps = {
  collectionCenter: PropsCenter;
  isBlockOtherLinks?: boolean;
};
export const MobileInfoWastes = ({
  collectionCenter,
  isBlockOtherLinks = false,
}: MobileInfoWastesProps) => {
  return (
    <Tabs defaultValue="detailsWasteCenter" className="w-full pt-3 lg:hidden">
      <TabsList className="w-full justify-between rounded-2xl p-0">
        <TabsTrigger
          variant={"ecolanaTab"}
          className="w-1/2"
          value="detailsWasteCenter"
        >
          Detalles del centro
        </TabsTrigger>
        <TabsTrigger
          variant={"ecolanaTab"}
          className="w-1/2"
          value="benefitsCampaigns"
        >
          Beneficios y campañas
        </TabsTrigger>
      </TabsList>
      <TabsContent className="flex flex-col" value="detailsWasteCenter">
        <div className="w-full flex flex-wrap items-center justify-around space-y-3">
          <div className="w-2/12">
            <Image className="w-full" src={centroAcopioLogo} alt="" />
          </div>
          <h2 className="w-10/12 justify-center items-center flex min-h-12 text-center font-bold text-ecolana-darkGreen ">
            {collectionCenter?.name}
          </h2>

          <div className="w-1/2 flex justify-center items-center text-xs">
            <p>
              <FontAwesomeIcon
                className="text-ecolana-darkGreen"
                icon={faLocationDot}
              />{" "}
              {collectionCenter?.address}
            </p>
          </div>
          {collectionCenter?.phone !== "" && (
            <>
              <Separator
                className="h-[100px] w-[2px] rounded-xl"
                orientation="vertical"
              />
              <div className="w-2/2 flex justify-center items-center text-xs">
                <p>
                  <FontAwesomeIcon
                    className="text-ecolana-darkGreen"
                    icon={faPhone}
                  />{" "}
                  {collectionCenter?.phone}
                </p>
              </div>
            </>
          )}
        </div>
        <div className="w-full text-center">
          <h4 className="text-xs">{collectionCenter?.schedule}</h4>
        </div>
        {!isBlockOtherLinks && (
          <>
            <div className="flex flex-wrap w-full pt-4 justify-center space-x-8">
              <div className="w-1/12 text-end ">
                <Image
                  src={wazeIcon}
                  className="w-fit mt-1"
                  alt=""
                  onClick={() =>
                    window.open(`${collectionCenter?.wazeLink}`, "_blank")
                  }
                />
              </div>
              <div className="w-1/12 text-start">
                <Image
                  src={googlemapsIcon}
                  className="w-fit"
                  alt=""
                  onClick={() =>
                    window.open(`${collectionCenter?.mapsLink}`, "_blank")
                  }
                />
              </div>
            </div>
            <div className="w-full flex flex-wrap pt-4 justify-center space-y-4 space-x-4 ">
              <h3 className="w-full text-ecolana-darkGreen text-md text-center font-semibold">
                Pon tu granito de arena, ¡Comparte!
              </h3>
              <div className="w-2/12">
                <FontAwesomeIcon
                  icon={faShareNodes}
                  className=" text-ecolana-wasteGreen"
                  size="sm"
                />
              </div>
              <div className="w-2/12">
                <Link
                  href={`https://www.facebook.com/sharer/sharer.php?u=${baseURL}/mapa/${collectionCenter?.id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className=" text-ecolana-wasteGreen"
                    size="sm"
                  />
                </Link>
              </div>
              <div className="w-2/12">
                <Link
                  href={`https://twitter.com/intent/tweet?url=${baseURL}/mapa/${collectionCenter?.id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    className=" text-ecolana-wasteGreen "
                    size="sm"
                  />
                </Link>
              </div>
              <div className="w-2/12">
                <Link
                  href={`https://twitter.com/intent/tweet?url=${baseURL}/mapa/${collectionCenter?.id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className=" text-ecolana-wasteGreen"
                    size="sm"
                  />
                </Link>
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col w-full items-start md:w-2/3 text-lg text-left space-y-4">
          {/*
          <h3 className=" text-ecolana-wasteGreen text-md text-left w-full">
            ¿Cómo reciclar aquí?
          </h3>
          <p className="text-xs">
            IMPORTANTE: Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Debitis et ea quia eum vero aliquam vel cumque assumenda
            maxime praesentium?
            </p>*/}
        </div>
        <div className="flex flex-wrap pt-4 space-y-4">
          <h3 className=" text-ecolana-wasteGreen text-md text-left w-full">
            Residuos que reciben:
          </h3>
          <div className=" w-full flex flex-wrap gap-2 justify-start">
            {collectionCenter?.wastes.map((uniWaste, index) => (
              <CustomTag tag2Show={uniWaste} colorIcon={"green"} key={index} />
            ))}
          </div>
        </div>
      </TabsContent>
      <TabsContent value="benefitsCampaigns">
        <div className="w-full flex flex-wrap space-y-3 items-center justify-around">
          <p className="w-full text-center text-xs">
            {collectionCenter?.benefit == null && "No hay beneficios"}
          </p>
          <div className="flex items-start justify-around">
            {(collectionCenter.benefit == "Ambiental" ||
              collectionCenter.benefit == "Ambos") && (
              <div className="flex flex-wrap  w-5/12">
                <h3 className="  text-ecolana-wasteGreen w-full text-md text-center font-bold">
                  <FontAwesomeIcon
                    className="text-ecolana-darkGreen"
                    size="lg"
                    icon={faLeaf}
                  />{" "}
                  Ambiental
                </h3>
                <p className="w-full text-center text-xs">
                  Al traer tus residuos estás siendo parte importante de la
                  transformación de residuos en nuevos productos o en su
                  correcta disposición. ¡Sigue siendo un héroe para nuestro
                  planeta!
                </p>
              </div>
            )}
            {collectionCenter.benefit == "Ambos" && (
              <Separator
                className="h-[150px] w-[2px] rounded-xl"
                orientation="vertical"
              />
            )}
            {(collectionCenter.benefit == "Efectivo" ||
              collectionCenter.benefit == "Ambos") && (
              <div className="flex flex-wrap  w-5/12">
                <h3 className="  text-ecolana-wasteGreen w-full text-md text-center font-bold">
                  <FontAwesomeIcon
                    className="text-ecolana-darkGreen"
                    size="lg"
                    icon={faMoneyBill1}
                  />{" "}
                  Efectivo
                </h3>
                <p className="w-full text-center text-xs">
                  Recibe dinero por los residuos que llevas; ten en cuenta que
                  el pago es por kilo y van desde centavos según sea el material
                </p>
              </div>
            )}
          </div>
          {/*<div className="w-full text-center text-sm flex flex-wrap justify-center items-center">
            <Image
              src={ecolanitaMapDialogImg}
              className="aspect-square w-1/12 "
              alt=""
            />
            <h2 className="w-9/12  text-ecolana-wasteGreen text-md">
              Ecolanitas
            </h2>
            <p className="w-full text-xs">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
              earum incidunt soluta consequuntur veritatis pariatur nisi
              suscipit mollitia nihil quasi.
            </p>
          </div>
          <h3 className="text-ecolana-wasteGreen text-xxs font-bold">
            Gana más con nuestras campañas en Ecolana App
          </h3>
          <CarouselAdvertisement className="w-full" isBanner={false} />
          */}
          {!isBlockOtherLinks && (
            <Button
              variant={"ecolanaLearnMore"}
              className="w-2/3 py-2 text-md font-extrabold rounded-3xl"
              onClick={() => window.open(app_home_url, "_blank")}
            >
              Descarga y gana
            </Button>
          )}
        </div>
      </TabsContent>
    </Tabs>
  );
};
