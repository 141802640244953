import { ValidationsImages } from "../ValidationsImages/ValidationsImages";
import { RowInputData } from "../RowInputData/RowInputData";
import { DialogAdmins } from "@/components/atoms";
import { Ticket } from "@/interfaces/ticketsInterface";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Input } from "@/components/atoms/shadcn/ui/input";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { ShoppingPlacesId } from "@/interfaces";
import { ValidationGroupButton } from "../ValidationGroupButton/ValidationGroupButton";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

type ViewDetailsDialogProps = {
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
  data: string;
  handleRemove: () => void;
  handleRejected: () => void;
  isEditable?: boolean;
  fields: FieldArrayWithId<Ticket, "list_sku", "id">[];
  control: Control<Ticket, any>;
  errors: FieldErrors<Ticket>;
  handleSubmit: () => void;
  shopping_places: ShoppingPlacesId[];
};
export const ViewDetailsDialog = ({
  isOpen = false,
  onClose = () => {},
  data,
  handleRemove,
  handleRejected,
  isEditable,
  fields,
  control,
  errors,
  handleSubmit,
  shopping_places,
}: ViewDetailsDialogProps) => {
  return (
    <DialogAdmins
      title="Detalles"
      variant={"green"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Controller
        name={"evidence"}
        control={control}
        render={({ field: { name, onChange, value } }) => (
          <TransformWrapper>
            <TransformComponent>
              <ValidationsImages
                image={value == "" ? undefined : value}
                className="w-full"
              />
            </TransformComponent>
          </TransformWrapper>
        )}
      />

      <div className="grid grid-cols-10 w-full gap-3 text-ecolana-black text-center px-3">
        <p className=" col-start-2 col-span-3 font-semibold">SKU</p>
        <p className=" col-span-3 font-semibold ">Marca</p>
        <p className=" col-span-3 font-semibold ">DESCRIPCIÓN</p>
      </div>
      <div className="grid grid-cols-10 w-full gap-3 text-ecolana-black text-center px-3 overflow-y-auto max-h-48 mb-7">
        {fields.map((item, idx) => (
          <Controller
            key={`fields_${item.id}_${idx}`}
            name={`list_sku.${idx}._destroy`}
            control={control}
            render={({ field: { name, value, onChange } }) =>
              !value ? (
                <RowInputData
                  key={`${item.id}_validation_row_${idx}`}
                  handleRemoveSkuFromList={onChange}
                  control={control}
                  isActive={isEditable}
                  idx={idx}
                  errors={errors}
                />
              ) : (
                <></>
              )
            }
          />
        ))}
      </div>
      <div className="grid grid-cols-3 gap-4 justify-center px-4">
        <Label
          htmlFor="date_register"
          className="text-admins-text-active flex items-center justify-center"
        >
          Fecha de Registro
        </Label>
        <Controller
          name="date_insert"
          control={control}
          render={({ field: { name, value } }) => (
            <Input
              name={name}
              value={value}
              variant={"adminGray"}
              className="col-span-2"
              disabled
            />
          )}
        />
        <Label
          htmlFor="purchase_place"
          className="text-admins-text-active flex items-center justify-center"
        >
          Lugar de compra
        </Label>
        <Controller
          name={"place"}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <>
              <SelectWithDefault
                value={value}
                name={name}
                setValue={onChange}
                selectedItems={shopping_places}
                placeHolder="Seleccionar una opción..."
                className="col-span-2"
                variant={isEditable ? "adminGreen" : "adminDisabled"}
                disabled={!isEditable}
              />
              <p className="text-ecolana-wasteRed col-span-3 font-semibold">
                {errors.place?.message}
              </p>
            </>
          )}
        />
      </div>
      {isEditable && (
        <ValidationGroupButton
          handleSubmit={handleSubmit}
          handleRemove={handleRemove}
          handleRejected={handleRejected}
        />
      )}
    </DialogAdmins>
  );
};
