import { toast } from "@/components/atoms/shadcn/ui/use-toast";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "tinacms";
import { ControlledInput } from "../ControlledInput/ControlledInput";
import { ControlledRadioGroup } from "../ControlledRadioGroup/ControlledRadioGroup";
import { RadioGroupItem } from "@/components/atoms/shadcn/ui/radio-group";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { useTranslations } from "next-intl";
import { api_v1 } from "@/shared/api";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { dataServices } from "@/hooks";
interface ContactCollectorProps {
  state: string;
  cp: string;
  age: number;
  name: string;
  email: string;
  collect: "true" | "false" | undefined;
}
const schema = z.object({
  state: z.string({
    required_error: "MUNICIPIO/ESTADO: Es un campo es requerido, ",
  }),
  cp: z.string({ required_error: "CÓDIGO POSTAL: Es un campo es requerido, " }),
  age: z.coerce
    .number({ required_error: "EDAD: Es un campo es requerido, " })
    .positive("EDAD: Ingrese una edad válida"),
  name: z.string({ required_error: "NOMBRE: Es un campo es requerido, " }),
  email: z
    .string({ required_error: "EMAIL: Es un campo es requerido," })
    .email("EMAIL: Por favor, ingrese un email válido"),
  collect: z.enum(["true", "false"], {
    required_error: "COMENTARIO: Es un campo es requerido",
  }),
});
export const FormAskForCollector = () => {
  const t = useTranslations();
  const [showMessage, setShowMessage] = useState<string>("");
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactCollectorProps>({
    resolver: zodResolver(schema),
    defaultValues: {
      state: "",
      cp: "",
      age: 0,
      name: "",
      email: "",
      collect: "false",
    },
  });

  async function onSubmit(data: ContactCollectorProps) {
    const response = await dataServices({
      url: `/leads/latitud_r`,
      requestType: "POST",
      apiVersion: "v1",
      body: data,
      contentType: "multipart/form-data",
    });
    if (response.status == 200) {
      toast({
        title: "Información enviada",
        description: `Se ha enviado la información correctamente.`,
        variant: "warning",
      });
      reset();
    } else {
      toast({
        title: "Error al enviar",
        description: `Vuelva a intentar a enviar la información más tarde.`,
        variant: "destructive",
      });
    }
  }

  return (
    <div className="w-11/12 md:w-[45%]">
      <div className="flex bg-white rounded-3xl flex-wrap w-full py-4 justify-between text-center items-center px-5">
        <div className="w-full md:w-7/12 mb-3">
          <Label>{t("inputState")}</Label>
          <ControlledInput
            type="text"
            label={t("inputState")}
            className="bg-transparent text-black font-bold  placeholder:text-black"
            control={control}
            name={"state"}
          />
        </div>
        <div className="text-center w-full md:w-4/12 mb-3">
          <Label>{t("inputPostalCode")}</Label>
          <ControlledInput
            className="bg-transparent text-black font-bold placeholder:text-black"
            type="text"
            label={t("inputPostalCode")}
            control={control}
            name={"cp"}
          />
        </div>
        <div className="text-center w-full md:w-4/12 mb-3">
          <Label>{t("inputAge")}</Label>
          <ControlledInput
            className="bg-transparent text-black font-bold"
            type="number"
            label={t("inputAge")}
            control={control}
            name={"age"}
          />
        </div>
        <div className="w-full md:w-7/12 mb-3">
          <Label>{t("inputName")}</Label>
          <ControlledInput
            className="bg-transparent text-black font-bold placeholder:text-black"
            type="text"
            label={t("inputName")}
            control={control}
            name={"name"}
          />
        </div>
        <div className="w-full mb-3">
          <Label>{t("inputEmail")}</Label>
          <ControlledInput
            className="w-full bg-transparent text-black font-bold"
            type="email"
            label={t("inputEmail")}
            control={control}
            name={"email"}
          />
        </div>
        <div className="w-full mb-3 mx-2">
          <p>¿Te gustaría que pasaran por tus residuos a tu casa?</p>
        </div>
        <ControlledRadioGroup
          name="collect"
          control={control}
          className="w-full flex flex-row my-3 mx-2 justify-evenly"
          //register={null} // Si no necesitas registrar manualmente
        >
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="true" id="Si_Option" />
            <Label htmlFor="Si_Option">Si</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem value="false" id="No_Option" />
            <Label htmlFor="No_Option">No</Label>
          </div>
        </ControlledRadioGroup>

        <Button
          className="my-3 mx-auto bg-[#19dc69] text-white font-extrabold text-xl rounded-3xl px-20"
          onClick={handleSubmit(onSubmit)}
        >
          {t("btnSend")}
        </Button>
        <p className={`font-semibold text-white text-center`}>
          {errors.state?.message}
          {errors.cp?.message}
          {errors.age?.message}
          {errors.name?.message}
          {errors.email?.message}
          {errors.collect?.message}
          {showMessage}
        </p>
      </div>
    </div>
  );
};
