import { SquareButtonImage } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { CampaignProps, Campaigns } from "@/constants/campaignsData";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";
const CampaignActive: CampaignProps[] = Campaigns.filter(
  (uniCampaign) => uniCampaign.active
);
import parse from 'html-react-parser';

export const CampaignTabs = () => {
  const [idCampaignSelected, setIdCampaignSelected] = useState(0);
  return (
    <section className="w-full flex flex-col p-6">
      <h2 className="font-extrabold text-center text-3xl lg:text-5xl text-ecolana-titlesGreen my-5">
        Conoce qué empresas ya reciclan
      </h2>
      <div className="flex flex-col">
        <div className="flex flex-wrap justify-center z-30 gap-2 bg-[#FCFCFC] border-[#FCFCFC]  border-2 rounded-3xl py-[2vh] shadow-lg">
          {CampaignActive.map((uniCampaign, idx) => (
            <SquareButtonImage
              key={`${idx}_button_campaign`}
              className={`w-1/6 md:w-1/12 ${
                idx == idCampaignSelected ? "bg-[#2CAD8F] shadow-xl" : ""
              } `}
              hoverColor={"green"}
              srcImg={uniCampaign.logo}
              onClick={() => setIdCampaignSelected(idx)}
            />
          ))}
        </div>
        <div className="flex flex-wrap justify-center z-20 pt-[6vh] border-[#D0D0D0] rounded-3xl min-h-[65vh] border-2 shadow-lg bg-white -mt-[5vh]">
          <div className="flex items-center justify-center w-full md:w-1/2">
            <Image
              src={CampaignActive[idCampaignSelected].logo}
              className="w-1/2"
              alt=""
            />
          </div>
          <div className="w-full md:w-1/2 flex flex-col justify-center items-center space-y-5 p-4 text-center">
            <h2 className="text-[#006936] text-3xl font-bold pb-4">
              ¿Que reciclamos?
            </h2>
            <p className="text-2xl font-semibold">
              {CampaignActive[idCampaignSelected].recycleText}
            </p>
            <p className="text-xl">
              {parse(CampaignActive[idCampaignSelected].description || " ")}
            </p>
            <Link
              href={`${
                CampaignActive[idCampaignSelected].slug == "latitud-r"
                  ? "/latitud-r"
                  : `/campana/${CampaignActive[idCampaignSelected].slug}`
              }`}
              className="w-1/2"
            >
              <Button className="w-full" variant={"ecolanaLearnMore"}>
                Conoce más
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
