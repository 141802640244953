import { ColumnDef } from "@tanstack/react-table";
import { CCInventory } from "@/interfaces";
import { Star } from "lucide-react";

export const columnMKT_CCs = (): ColumnDef<CCInventory>[] => {
  return [
    {
      accessorKey: "name_CC",
      header: "Nombre/ID",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p className="font-bold">{row.original.name}</p>
          <p>ID: {row.original.id}</p>
          {row.original?.stars && (
            <div className="flex flex-row justify-center w-full gap-4">
              <p>{row.original?.stars.toFixed(1)}</p>
              <Star className="text-ecolana-lightGreen" />
            </div>
          )}
        </div>
      ),
    },
    {
      accessorKey: "state",
      header: "Dirección",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p>{row.original?.state_name}</p>
        </div>
      ),
    },
    {
      accessorKey: "type_CC",
      header: "Tipo",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p>{row.original.type_cc_name}</p>
        </div>
      ),
    },
    {
      accessorKey: "wastes",
      header: "Residuos",
      cell: ({ row }) => (
        <p>
          {row.original?.wastes.length > 0
            ? `${row.original?.wastes
                .map((item) => item.waste_name)
                .join(", ")}`
            : ""}
        </p>
      ),
    },
  ];
};
