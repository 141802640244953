import React, { FC, useEffect, useRef, useState } from "react";
import { banner_test_1, banner_test_2 } from "@/assets";
import { Button } from "../shadcn/ui/button";
import Image from "next/image";
import { X } from "lucide-react";
import { Carousel, CarouselContent, CarouselItem } from "../shadcn/ui/carousel";
import Autoplay from "embla-carousel-autoplay";

interface Props {
  position?: "sticky" | "absolute";
}

const imgCarouselBanner = [
  {
    img: banner_test_1,
    url: "https://cmtevents.com/main.aspx?ev=241119&pu=306438",
  },
  {
    img: banner_test_2,
    url: "https://cmtevents.com/main.aspx?ev=241126&pu=306437",
  },
];

export const StickyBanner: FC<Props> = ({ position = "absolute" }) => {
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowBanner(true);
    }, 1000);
  }, []);
  const plugin = useRef(Autoplay({ delay: 4000, stopOnInteraction: true }));

  return (
    <section
      className={` w-full z-[30] bottom-[0svh] transition-opacity ease-out duration-500 overflow-hidden  justify-around items-center bg-green-700 p-4 ${position} ${
        showBanner
          ? "flex lg:flex-row flex-col h-auto opacity-70"
          : "h-0 opacity-0"
      }`}
    >
      <div
        className="absolute lg:hidden top-1 right-1 bg-gradient-to-tr from-[#41B59A] to-[#1C7D6B] rounded-full min-w-fit p-1"
        onClick={() => setShowBanner(false)}
      >
        <X className="text-white font-bold w-5 h-5" />
      </div>
      <div className="w-fit flex flex-row lg:flex-col justify-between items-center lg:items-start space-y-0 lg:space-y-16 px-10">
        {/* <h2 className="lg:w-full text-white font-extrabold text-md lg:text-3xl text-center lg:text-balance pb-4">
          Participa en las campañas de empresas que apoyan el reciclaje
        </h2> */}
        <Button
          onClick={() => setShowBanner(false)}
          variant={"ecolanaLearnMore"}
          className="min-w-fit hidden lg:flex lg:w-2/6 text-md"
        >
          Cerrar
        </Button>
      </div>
      <Carousel
        opts={{
          align: "start",
        }}
        plugins={[plugin.current]}
        className="w-full"
      >
        <CarouselContent>
          {imgCarouselBanner?.map((uniImage, idxImage) => (
            <CarouselItem key={idxImage} className="basis-full min-h-[24svh]">
              <a
                className="w-full h-full cursor-pointer"
                href={uniImage.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  src={uniImage.img}
                  alt={"advertisement"}
                  className="w-full h-full"
                  style={{ objectFit: "contain" }}
                />
              </a>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </section>
  );
};
