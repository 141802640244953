import { DatePickerWithRange } from "@/components/atoms";
import { CalendarIcon, DownloadIcon } from "lucide-react";
import React from "react";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { DateRange } from "react-day-picker";

export const FilterPerYear = ({
  value,
  singleOnChange,
  rangeOnChange,
}: {
  value: string | DateRange;
  singleOnChange?: (value: string) => void;
  rangeOnChange?: (date: DateRange | undefined) => void;
}) => {
  return (
    <div className="bg-admins-input-disabled_bg flex justify-around px-2 rounded-2xl w-fit min-w-fit items-center justify-self-center">
      {rangeOnChange && typeof value === "object" && (
        <DatePickerWithRange setValue={rangeOnChange} value={value} />
      )}
      {singleOnChange && typeof value === "string" && (
        <div
          className={
            "flex flex-row justify-start items-center text-left font-norma text-muted-foreground"
          }
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          <span>Filtrar por</span>{" "}
          <SelectWithDefault
            variant={"ghost"}
            placeHolder="Año"
            setValue={singleOnChange}
            value={value}
            selectedItems={[
              { id: "2022", name: "Año 2022" },
              { id: "2023", name: "Año 2023" },
              { id: "2024", name: "Año 2024" },
            ]}
          />
        </div>
      )}
      <DownloadIcon className="text-admins-text-active px-1" />
    </div>
  );
};
