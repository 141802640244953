import { SelectWithSearch } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { FormFilterValues } from "@/hooks/useMapData";
import { States, Towns } from "@/interfaces/statesTownsInterfaces";
import { Control, Controller } from "react-hook-form";

type CampaignFilterMarkersProps = {
  states: States[];
  towns: Towns[];
  control: Control<FormFilterValues>;
  handleSearch: () => void;
};

export const CampaignFilterMarkers = ({
  states,
  towns,
  control,
  handleSearch,
}: CampaignFilterMarkersProps) => {
  return (
    <section
      id={"map-campaign"}
      className="w-full flex flex-wrap space-y-5 py-6 items-center justify-center"
    >
      <h3 className="w-full text-xl 2xl:text-3xl text-center text-ecolana-titlesGreen">
        ¿Dónde reciclar?
      </h3>
      <div className="w-full flex lg:flex-row flex-col gap-4 justify-around items-center ">
        <Controller
          name={"state"}
          render={({ field }) => (
            <SelectWithSearch
              placeHolder={"Estado"}
              value={field.value}
              setValue={field.onChange}
              name={field.name}
              selectedItems={states}
              className="w-2/3 lg:w-1/3 2xl:text-2xl"
            />
          )}
          control={control}
        />
        <Controller
          name={"town"}
          render={({ field }) => (
            <SelectWithSearch
              placeHolder={"Municipio"}
              selectedItems={towns}
              className="w-2/3 lg:w-1/3 2xl:text-2xl"
              value={field.value}
              setValue={field.onChange}
              name={field.name}
            />
          )}
          control={control}
        />
      </div>
      <Button
        onClick={handleSearch}
        className="w-2/3 lg:w-1/3 py-2 bg-ecolana-mediumBlue text-xl 2xl:text-3xl font-extrabold rounded-3xl"
      >
        Buscar
      </Button>
    </section>
  );
};
