import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
  },
};

export const dataChart = {
  labels: ["Masculino", "Femenino", "Otro", "No Binario"],
  datasets: [
    {
      label: "genders",
      data: [6614, 1633, 76, 9901],
      backgroundColor: ["#A363E7", "#00F0A9", "#F3F306", "#48D3E4"],
    },
  ],
};

export const DoughnutChart = ({
  data = dataChart,
}: {
  data?: ChartData<"doughnut", number[], unknown>;
}) => {
  return (
    <div className="w-1/2 flex justify-center items-center">
      <Doughnut
        data={data}
        options={options}
      />
    </div>
  );
};
