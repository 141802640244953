import { CardBGGradient } from "@/components/atoms";
import { cardBGGradientVariants } from "@/components/atoms/CardBGGradient/CardBGGradient";
import { cn } from "@/lib/utils";
import { VariantProps } from "class-variance-authority";
import { DownloadIcon, LucideIcon } from "lucide-react";
import { DetailedHTMLProps, HTMLAttributes } from "react";

interface CardGradientDataProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof cardBGGradientVariants> {
  scans_number: string;
  icon_to_show?: LucideIcon | JSX.Element | undefined;
  text_card?: string;
  showDownloadIcon?: boolean;
}

export const CardGradientData = ({
  scans_number,
  icon_to_show,
  text_card,
  variant,
  className,
  onClick,
  showDownloadIcon = false,
}: CardGradientDataProps) => {
  return (
    <CardBGGradient
      className={cn(className, "min-h-20 min-w-20")}
      variant={variant}
      onClick={onClick}
    >
      <div className="flex flex-wrap p-4 items-end gap-5">
        {icon_to_show && (
          <div className="flex bg-white rounded-lg p-1 justify-center items-center">
            <>{icon_to_show}</>
          </div>
        )}
        <p
          className={`w-full text-left text-lg xl:text-2xl font-bold  ${
            variant == "greenActive" || variant == "greenGradient"
              ? "text-white"
              : "text-admins-text-active"
          }`}
        >
          {text_card}
        </p>
        <p
          className={`w-full text-left text-xl font-extrabold ${
            variant == "greenActive" || variant == "greenGradient"
              ? "text-white"
              : "text-admins-text-active"
          }`}
        >
          {scans_number}
        </p>
        {showDownloadIcon && (
          <DownloadIcon
            className={`w-6 h-6  ${
              variant == "greenActive" || variant == "greenGradient"
                ? "text-white"
                : "text-admins-text-active"
            }`}
          />
        )}
      </div>
    </CardBGGradient>
  );
};
