import { ColumnDef } from "@tanstack/react-table";

export const columnCCCommentHistory = (): ColumnDef<{
  id?: string;
  description?: string;
  created_at?: string;
  score?: string;
}>[] => {
  return [
    // {
    //   accessorKey: "name_CC",
    //   header: "Usuario",
    //   cell: ({ row }) => (
    //     <div className="flex flex-col justify-center">
    //       <p>{row.original.user_name}</p>
    //       <p>{row.original.id_user}</p>
    //       <p>{row.original.id_user}</p>
    //     </div>
    //   ),
    // },
    {
      accessorKey: "created_at",
      header: "Fecha",
    },
    {
      accessorKey: "score",
      header: "Calificación",
    },

    {
      accessorKey: "description",
      header: "Comentario",
      cell: ({ row }) => {
        return <p>{row.original.description}</p>;
      },
    },
  ];
};
