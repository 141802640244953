import { Input } from "@/components/atoms/shadcn/ui/input";
import { Ticket } from "@/interfaces";
import { Trash } from "lucide-react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";

type RowInputDataProps = {
  data_validations?: { sku: string; branch: string; description: string };
  isActive?: boolean;
  control: Control<Ticket, any>;
  handleRemoveSkuFromList: (value: boolean) => void;
  idx: number;
  errors: FieldErrors<Ticket>;
};

export const RowInputData = ({
  control,
  handleRemoveSkuFromList,
  isActive,
  idx,
  errors,
}: RowInputDataProps) => {
  return (
    <>
      {isActive && (
        <Trash
          onClick={() => handleRemoveSkuFromList(true)}
          className="text-admins-button-red justify-self-center"
        />
      )}
      <Controller
        name={`list_sku.${idx}.sku`}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <div
            className={`${isActive ? "" : "col-start-2"} col-span-3 flex-col`}
          >
            <Input
              name={name}
              value={value}
              variant={"adminGray"}
              onChange={onChange}
              className={`w-full`}
              disabled={!isActive}
            />
            <p className="text-ecolana-wasteRed font-semibold">
              {errors.list_sku ? errors.list_sku[idx]?.sku?.message : null}
            </p>
          </div>
        )}
      />
      <Controller
        name={`list_sku.${idx}.brand`}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <div className={`col-span-3 flex-col`}>
            <Input
              name={name}
              value={value}
              variant={"adminGray"}
              onChange={onChange}
              className={`w-full`}
              disabled={!isActive}
            />
            <p className="text-ecolana-wasteRed font-semibold">
              {errors.list_sku ? errors.list_sku[idx]?.brand?.message : null}
            </p>
          </div>
        )}
      />
      <Controller
        name={`list_sku.${idx}.description`}
        control={control}
        render={({ field: { name, value, onChange } }) => (
          <div className={`col-span-3 flex-col`}>
            <Input
              name={name}
              value={value}
              variant={"adminGray"}
              onChange={onChange}
              className={`w-full`}
              disabled={!isActive}
            />
            <p className="text-ecolana-wasteRed font-semibold">
              {errors.list_sku
                ? errors.list_sku[idx]?.description?.message
                : null}
            </p>
          </div>
        )}
      />
    </>
  );
};
