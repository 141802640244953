import { DialogAdmins } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  useWatch,
} from "react-hook-form";
import { Sku, WasteCategoriesId, WasteId, product_wastes } from "@/interfaces";
import { ControlledInputImageSKU } from "../ControlledInputImage/ControlledInputImageSKU";
import { GridViewProductImages } from "../GridViewProductImages/GridViewProductImages";
import { CirclePlus } from "lucide-react";
import { GridViewSKUImages } from "../GridViewProductImages/GridViewSKUImages";

type DialogConfirmationProps = {
  isOpen: boolean;
  onClose?: ((idx?: undefined) => void) | undefined;
  control: Control<Sku, any>;
  errors_sku: FieldErrors<Sku>;
  fields: FieldArrayWithId<Sku, "product_wastes", "id">[];
  handleSaveImageSKU: () => void;
  wastes: WasteId[];
  waste_categories: WasteCategoriesId[];
  handleAddNewProductWaste: () => void;
};

export const DialogViewImages = ({
  isOpen,
  onClose,
  control,
  errors_sku,
  handleSaveImageSKU,
  fields,
  wastes,
  waste_categories,
  handleAddNewProductWaste,
}: DialogConfirmationProps) => {
  const sku = useWatch({ control, name: "sku" });
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Imagen"
      variant={"green"}
    >
      <div className="flex w-full items-center justify-center gap-2">
        <p className="text-admins-text-active font-bold">SKU:</p>
        <p className="text-admins-text-inactive bg-admins-input-disabled_bg rounded-lg p-3">
          {sku}
        </p>
      </div>
      <div className="w-2/3 flex flex-col gap-4 items-center justify-center py-6">
        <ControlledInputImageSKU
          errors_sku={errors_sku}
          control={control}
          baseImage={true}
          idx={0}
        />
      </div>
      <div className="w-full flex flex-wrap gap-4 justify-center items-center">
        <Button
          className="mx-10 my-7 justify-self-start bg-admins-button-green rounded-lg text-lg w-fit"
          onClick={handleAddNewProductWaste}
        >
          <CirclePlus className="text-white mx-4 w-5 h-5" />
          Agregar Categoría/Residuo
        </Button>

        <GridViewSKUImages
          wastes={wastes}
          waste_categories={waste_categories}
          control={control}
          fields={fields}
          errors={errors_sku}
          isEditable={true}
        />
      </div>
      <div className="w-full flex justify-end py-4">
        <Button
          onClick={handleSaveImageSKU}
          className="w-1/4 text-lg"
          variant={"adminGray"}
        >
          Guardar
        </Button>
      </div>
    </DialogAdmins>
  );
};
