import { ColumnDef, Row } from "@tanstack/react-table";
import { faker } from "@faker-js/faker";
import {
  Copy,
  Eye,
  ImagePlus,
  Pencil,
  SaveIcon,
  Trash,
  Trash2,
  TrashIcon,
  X,
} from "lucide-react";
import { ControlledInput, SelectWithDefault } from "../molecules";
import {
  Controller,
  type Control,
  FieldArrayWithId,
  FieldErrors,
  UseFormHandleSubmit,
  useWatch,
} from "react-hook-form";
import {
  Company,
  Sku,
  TypeMeasure,
  WasteCategoriesId,
  WasteId,
} from "@/interfaces";
type columnSkuProps = {
  handleAddProductWaste: () => void;
  handleEditRow: (row: Row<Sku>) => void;
  handleDuplicateRow: (row: Row<Sku>) => void;
  handleCloseDialog: (id?: string) => void;
  handleSaveRow: (data: Sku) => void;
  handleCancelEdit: (row: Row<Sku>) => void;
  handleViewImages: (row: Row<Sku>) => void;
  control: Control<Sku, any>;
  handleSubmit: UseFormHandleSubmit<Sku, undefined>;
  errors_sku: FieldErrors<Sku>;
  isEditable?: boolean;
  isDuplicate?: boolean;
  fields: FieldArrayWithId<Sku, "product_wastes", "id">[];
  companys: Company[];
  type_measure: TypeMeasure[];
  wastes: WasteId[];
  wastes_categories: WasteCategoriesId[];
};
export const columnSku = ({
  handleAddProductWaste,
  handleEditRow,
  handleDuplicateRow,
  handleCloseDialog,
  handleSaveRow,
  handleCancelEdit,
  handleViewImages,
  companys,
  type_measure,
  wastes,
  wastes_categories,
  control,
  handleSubmit,
  errors_sku,
  isEditable,
  isDuplicate,
  fields,
}: columnSkuProps): ColumnDef<Sku>[] => {
  return [
    {
      accessorKey: "company",
      header: "Empresa",
      cell: ({ row }) => {
        return isEditable && !isDuplicate && row.getIsSelected() ? (
          <>
            <ControlledInput
              type="text"
              variant={"adminWhiteBorder"}
              control={control}
              name={"company_name"}
            />
            <p className="text-ecolana-wasteRed col-span-3 font-semibold"></p>
          </>
        ) : (
          <p> {row.original.company_name} </p>
        );
      },
    },
    {
      accessorKey: "brand",
      header: "Marca",
      cell: ({ row }) => {
        return isEditable && !isDuplicate && row.getIsSelected() ? (
          <>
            <ControlledInput
              type="text"
              variant={"adminWhiteBorder"}
              control={control}
              name={"brand"}
            />
            <p className="text-ecolana-wasteRed col-span-3 font-semibold">
              {errors_sku.brand?.message}
            </p>
          </>
        ) : (
          <p>{row.original.brand}</p>
        );
      },
    },
    {
      accessorKey: "sub_brand",
      header: "Submarca",
      cell: ({ row }) => {
        return isEditable && !isDuplicate && row.getIsSelected() ? (
          <>
            <ControlledInput
              type="text"
              variant={"adminWhiteBorder"}
              control={control}
              name={"sub_brand"}
            />
            <p className="text-ecolana-wasteRed col-span-3 font-semibold">
              {errors_sku.sub_brand?.message}
            </p>
          </>
        ) : (
          <p>{row.original.sub_brand}</p>
        );
      },
    },

    {
      accessorKey: "sku",
      header: "SKU",
      cell: ({ row }) => {
        return isEditable && !isDuplicate && row.getIsSelected() ? (
          <>
            <ControlledInput
              type="number"
              variant={"adminWhiteBorder"}
              control={control}
              name={"sku"}
            />
            <p className="text-ecolana-wasteRed col-span-3 font-semibold">
              {errors_sku.sku?.message}
            </p>
          </>
        ) : (
          <p>{row.original.sku}</p>
        );
      },
    },
    {
      accessorKey: "measure",
      header: "Medida",
      cell: ({ row }) => {
        return isEditable && !isDuplicate && row.getIsSelected() ? (
          <>
            <ControlledInput
              type="number"
              variant={"adminWhiteBorder"}
              control={control}
              name={"measure"}
            />
            <p className="text-ecolana-wasteRed col-span-3 font-semibold">
              {errors_sku.measure?.message}
            </p>
          </>
        ) : (
          <p>{row.original.measure}</p>
        );
      },
    },
    {
      accessorKey: "type_measure",
      header: "Tipo de Medida",
      cell: ({ row }) => {
        return isEditable && !isDuplicate && row.getIsSelected() ? (
          <Controller
            key={`${row.id}_${row.original.type_measure}`}
            name={"type_measure"}
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <SelectWithDefault
                  value={value}
                  name={name}
                  setValue={onChange}
                  selectedItems={type_measure}
                  placeHolder="Tipo de Medida"
                  className="w-full"
                />
                <p className="text-ecolana-wasteRed col-span-3 font-semibold">
                  {errors_sku.type_measure?.message}
                </p>
              </>
            )}
          />
        ) : (
          <p>{row.original.type_measure_name}</p>
        );
      },
    },
    {
      accessorKey: "waste_category",
      header: "Categoria Residuo",
      cell: ({ row }) => {
        return isEditable && row.getIsSelected() ? (
          <div className="grid grid-cols-1 gap-2 items-center justify-center w-full">
            {fields.map((item, index) => {
              const destroy = useWatch({
                control,
                name: `product_wastes.${index}._destroy`,
              });
              return (
                !destroy && (
                  <Controller
                    key={`${item.id}_${index}_${row.id}_wasteCategory`}
                    name={`product_wastes.${index}.waste_category_id`}
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <div className="col-span-5">
                        <SelectWithDefault
                          key={`waste_id_${index}_${item.id}`}
                          value={String(value)}
                          name={name}
                          setValue={onChange}
                          selectedItems={wastes_categories}
                          placeHolder="Categoría de Residuo"
                          className="col-span-5"
                        />
                        <p className="text-ecolana-wasteRed font-semibold">
                          {errors_sku.product_wastes
                            ? errors_sku.product_wastes[index]
                                ?.waste_category_id?.message
                            : null}
                        </p>
                      </div>
                    )}
                  />
                )
              );
            })}
          </div>
        ) : (
          <p>
            {row.original.product_wastes.length > 0
              ? `${row.original.product_wastes
                  .map((item) => item.waste_category_name)
                  .join(",")}`
              : ""}
          </p>
        );
      },
    },
    {
      accessorKey: "waste",
      header: "Residuo",
      cell: ({ row }) => {
        return isEditable && row.getIsSelected() ? (
          <div className="grid grid-cols-6 gap-2 items-center justify-center w-full">
            {fields.map((item, index) => {
              const destroy = useWatch({
                control,
                name: `product_wastes.${index}._destroy`,
              });
              return (
                !destroy && (
                  <>
                    <Controller
                      key={`${item.id}_${index * Math.random()}_${
                        row.id
                      }_wastename`}
                      name={`product_wastes.${index}.waste_id`}
                      control={control}
                      render={({ field: { name, onChange, value } }) => (
                        <div className="col-span-5">
                          <SelectWithDefault
                            key={`waste_id_${index * Math.random()}_${
                              item.id
                            }}_wastes`}
                            value={String(value)}
                            name={name}
                            setValue={onChange}
                            selectedItems={wastes}
                            placeHolder="Residuo"
                            className="col-span-5"
                          />
                          <p className="text-ecolana-wasteRed font-semibold">
                            {errors_sku.product_wastes
                              ? errors_sku.product_wastes[index]?.waste_id
                                  ?.message
                              : null}
                          </p>
                        </div>
                      )}
                    />
                    <Controller
                      key={`${item.id}_${index}_${row.id}_destroy`}
                      name={`product_wastes.${index}._destroy`}
                      control={control}
                      render={({ field: { name, onChange, value } }) => (
                        <Trash2
                          key={`waste_id_${index * Math.random()}_${
                            item.id
                          }}_wastesTrash`}
                          onClick={() => onChange(true)}
                          className="hover:text-admins-button-red cursor-pointer"
                        />
                      )}
                    />
                  </>
                )
              );
            })}
          </div>
        ) : (
          <p>
            {row.original.product_wastes.length > 0
              ? `${row.original.product_wastes
                  .map((item) => item.waste_name)
                  .join(",")}`
              : ""}
          </p>
        );
      },
    },
    {
      accessorKey: "image",
      header: "Imagen",
      cell: ({ row }) => {
        return (
          <div className="flex flex-wrap justify-center">
            {row.original.image ? (
              <Eye
                className="hover:text-ecolana-wasteYellow cursor-pointer"
                onClick={() => handleViewImages(row)}
              />
            ) : (
              <ImagePlus onClick={() => handleViewImages(row)} />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "actions",
      header: "Acciones",
      cell: ({ row }) => {
        return (
          <div className="grid grid-cols-3 gap-4 w-full">
            {row.getIsSelected() && isEditable ? (
              <>
                <SaveIcon
                  className="text-admins-button-green"
                  onClick={handleSubmit((data) => {
                    handleSaveRow(data);
                  })}
                />
                <X
                  onClick={() => handleCancelEdit(row)}
                  className="text-admins-button-red cursor-pointer"
                />
                <Copy
                  className="text-admins-text-inactive hover:text-admins-button-green cursor-pointer"
                  onClick={() => handleAddProductWaste()}
                />
              </>
            ) : (
              <>
                <Pencil
                  className="hover:text-admins-button-green cursor-pointer"
                  onClick={() => handleEditRow(row)}
                />
                <Trash2
                  onClick={() => handleCloseDialog(row.original.id)}
                  className="hover:text-admins-button-red cursor-pointer"
                />
                <Copy
                  className="text-admins-text-inactive hover:text-admins-button-green cursor-pointer"
                  onClick={() => handleDuplicateRow(row)}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];
};
