import { logoEcolana } from "../../../assets";
import Link from "next/link";
const pages = ["Inicio", "Mapa", "Diccionario", "Empresas"];
import { useRouter } from "next/router";
import { Button } from "../../atoms/shadcn/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "../../atoms/shadcn/ui/sheet";
import Image from "next/image";
export const Navbar = () => {
  const router = useRouter();
  let changeNavBar = router.pathname === "/" || router.pathname === "/empresas";

  return (
    <nav
      className={`absolute w-full bg-[#41b59a] max-h-[72px] py-2 ${
        !changeNavBar ? "rounded-b-[50px] shadow-xl" : ""
      }`}
      style={{ zIndex: 50 }}
    >
      <div className="flex flex-wrap min-h-[48px] items-center justify-between mx-auto m-1 px-14">
        <Link className="w-1/2 md:w-1/3 lg:w-1/6 xl:w-1/6 2xl:w-1/12 max-h-full" href={"/"} prefetch={false}>
          <Image
            src={logoEcolana}
            alt="Ecolana_logo_navbar"
            width={382}
            height={76}
            sizes="100vw"
            priority
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Link>

        <Sheet key={"navBar-sheet"}>
          <SheetTrigger asChild>
            <Button
              type="button"
              className="lg:hidden w-1/5"
              variant={"ecolanaNavBar"}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-10"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </Button>
          </SheetTrigger>
          <SheetContent
            side={"top"}
            className="w-full h-full items-center justify-center justify-items-center bg-[#41b59a]"
          >
            <div className="flex flex-col h-full justify-center">
              <div className="flex flex-col p-4 lg:p-0 mt-20 bg-[#41b59a] lg:flex-row lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0">
                {pages.map((page) => (
                  <SheetClose key={`${page}-link`} asChild>
                    <Link
                      prefetch={false}
                      href={page === "Inicio" ? "/" : `/${page.toLowerCase()}`}
                      className="py-2 px-3 bg-transparent text-white text-center font-bold text-4xl mt-7"
                    >
                      {page}
                    </Link>
                  </SheetClose>
                ))}
              </div>
            </div>
          </SheetContent>
        </Sheet>
        <div
          className={`hidden w-3/5 lg:flex justify-end space-x-8`}
          id="navbar-default"
        >
          {pages.map((page) => (
            <div key={page}>
              <Link
                prefetch={false}
                href={page === "Inicio" ? "/" : `/${page.toLowerCase()}`}
                className="block py-1 px-2 bg-[#41b59a] text-white font-bold lg:text-2xl text-sm rounded"
              >
                {page}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};
