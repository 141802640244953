import { Button } from "@/components/atoms/shadcn/ui/button";
import { cn } from "@/lib/utils";
import { DetailedHTMLProps, HTMLAttributes } from "react";

interface ValidationGroupButton
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  handleRemove: () => void;
  handleRejected: () => void;
  handleSubmit: () => void;
}

export const ValidationGroupButton = ({
  handleRemove,
  handleRejected,
  handleSubmit,
  className,
  ...props
}: ValidationGroupButton) => {
  return (
    <div {...props} className={cn("grid grid-cols-5 gap-4", className)}>
      <Button
        onClick={handleRejected}
        variant={"adminGray"}
        className="col-span-2"
      >
        Rechazar
      </Button>
      <Button
        onClick={() => handleRemove()}
        variant={"adminGray"}
        className="col-start-4 col-span-2"
      >
        Eliminar
      </Button>
      <Button
        onClick={handleSubmit}
        variant={"adminGray"}
        className="col-span-5"
      >
        Validar
      </Button>
    </div>
  );
};
