import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  WasteValidationsContainer,
  BarChartHorizontal,
  BarChartVertical,
  DoughnutChart,
  BarChartHorizontalStacked,
} from "@/components/molecules";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { useQuery } from "@tanstack/react-query";
import { fetcherOverviewDemographics } from "@/pages/admins/escaner/overview";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { Session } from "next-auth";
import { generateGraphic } from "@/shared/functions/generateGraphic";

type EscanerDemographicsProps = {
  session: Session | null;
};

export const EscanerDemographics = ({ session }: EscanerDemographicsProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "states",
      "sub_brands_per_age",
      "waste_category_per_gender",
      "wastes_per_age",
      "brands_per_age",
      "waste_category_per_age",
      "ages",
      "brands_per_gender",
      "gender",
      "wastes_per_gender",
      "subbrands_per_gender",
      "towns",
    ],
    keysYear: [],
  });
  const { data: stats, refetch } = useQuery({
    queryKey: ["demographicsScan"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDemographics({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });

  useCheckRefetch(refetch, global, ranges, years);

  if (!stats || !control) return null;
  return (
    <TabsContent value="Demographics">
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController control={control} name={"globalRange"} />
        </div>
      </div>
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        <WasteValidationsContainer
          title="Género"
          subtitle="*Considerando usuarios que han escaneado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.gender"}
              control={control}
            />
          }
        >
          <DoughnutChart
            data={generateGraphic(stats as any, "gender") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Edad"
          subtitle="*Considerando usuarios que han escaneado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.ages"}
              control={control}
            />
          }
        >
          <BarChartVertical
            data={generateGraphic(stats as any, "ages") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Ubicaciones de usuarios (TOP 10)"
          subtitle="*Estado"
          filter={
            <FilterPerYearController
              name={"graphsRanges.states"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "states") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Ubicaciones de usuarios (TOP 10)"
          subtitle="*Municipio"
          filter={
            <FilterPerYearController
              name={"graphsRanges.towns"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "towns") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Marcas más escaneadas por género (TOP 10)"
          subtitle="*Considerando usuarios que han escaneado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.brands_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "brands_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Marcas más escaneadas por edad (TOP 10)"
          subtitle="*Considerando usuarios que han escaneado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.brands_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(stats as any, "brands_per_age", true, true) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Submarcas más escaneadas por género (TOP 10)"
          subtitle="*Considerando usuarios que han escaneado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.subbrands_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "subbrands_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Submarcas más escaneadas por edad (TOP 10)"
          subtitle="*Considerando usuarios que han escaneado una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.sub_brands_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "sub_brands_per_age",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Categorías más escaneadas por género (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.waste_category_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "waste_category_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Categorías más escaneadas por edad (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.waste_category_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "waste_category_per_age",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Residuos más escaneadas por género (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.wastes_per_gender"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(
                stats as any,
                "wastes_per_gender",
                true,
                true
              ) as any
            }
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Residuos más escaneadas por edad (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.wastes_per_age"}
              control={control}
            />
          }
        >
          <BarChartHorizontalStacked
            data={
              generateGraphic(stats as any, "wastes_per_age", true, true) as any
            }
          />
        </WasteValidationsContainer>
      </div>
    </TabsContent>
  );
};
