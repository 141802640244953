import { logoRE, logoTrashLater } from "../../../assets";
import Link from "next/link";
const pages = [
  {
    textBtn: "Trash-Later",
    linkRedirect: "https://trashlater.ecolana.com.mx",
  },
  {
    textBtn: "Centros de Acopio",
    linkRedirect: "",
  },
  {
    textBtn: "Iniciativa Nestlé®",
    linkRedirect: "https://trashlater.ecolana.com.mx/iniciativa-nestle",
  },
  {
    textBtn: "",
    imgBtn: logoRE,
    linkRedirect: "",
  },
];
import { Button } from "../../atoms/shadcn/ui/button";
import { useState } from "react";
import Image from "next/image";
export const NavbarRe = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav
      className={`bg-[url('/images/re/green_bg_full.jpg')] border-transparent z-50 `}
    >
      <div
        key={"navBar-mainDiv"}
        className="flex flex-wrap items-center justify-between mx-auto px-4 md:px-10 py-4 md:py-2 transition-all ease-linear duration-700"
      >
        <div className="w-1/3 md:w-2/12">
          <Image
            src={logoTrashLater}
            alt="Trash Later Logo"
            className="w-fit h-[40px]"
          />
        </div>
        <Button
          type="button"
          className="sm:hidden"
          variant={"reNavBar"}
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </Button>
        <div
          className={`${
            isOpen ? "flex w-full" : "hidden"
          } md:flex md:w-10/12 flex-wrap justify-end`}
          id="navbar-default"
        >
          {pages.map((page, idx) => (
            <Link
              key={`${idx}_${page.textBtn}`}
              href={page.linkRedirect}
              className="py-2 px-3 bg-transparent text-white font-bold flex items-center text-center space-y-3 w-full md:w-fit"
            >
              {page.textBtn != "" && `${page.textBtn}`}
              {page.textBtn == "" && (
                <Image src={page.imgBtn!} className="w-[30px]" alt="" />
              )}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};
