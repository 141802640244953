import { DialogAdmins } from "@/components/atoms";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { CompanyNew } from "@/interfaces";
import { InputImages } from "../InputImages/InputImages";

type DialogNewCompanyProps = {
  isOpen: boolean;
  onClose?: (() => void) | undefined;
  control: Control<CompanyNew, any>;
  errors: FieldErrors<CompanyNew>;
  handleSubmit: () => void;
};

export const DialogNewCompany = ({
  isOpen,
  onClose,
  control,
  errors,
  handleSubmit,
}: DialogNewCompanyProps) => {
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Crear nueva empresa"
      variant={"green"}
    >
      <div className="grid grid-cols-2 gap-4 w-2/3">
        <div className="col-span-2 flex flex-col justify-center gap-2">
          <Label htmlFor="company_name" className=" text-admins-text-active">
            Escribe el nombre de la empresa que deas crear
          </Label>
          <Controller
            name="company_name"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                {" "}
                <Textarea
                  placeholder="Escribe el nombre de la empresa"
                  value={value}
                  name={name}
                  onChange={onChange}
                  className="w-full"
                  variant={"adminWhiteBorder"}
                />
                <p>{errors.company_name?.message}</p>
              </>
            )}
          />
        </div>
        <div className="col-span-2 flex flex-col justify-center gap-2">
          <Label
            className="px-3 text-admins-text-active text-sm"
            htmlFor="image"
          >
            Imagen de la empresa
          </Label>
          <Controller
            name="file"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <InputImages value={value} onChange={onChange} />
                <p>{errors.file?.message}</p>
              </>
            )}
          />
        </div>
        <Button
          onClick={handleSubmit}
          variant={"adminGray"}
          className="col-span-2 "
        >
          Guardar
        </Button>
      </div>
    </DialogAdmins>
  );
};
