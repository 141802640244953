import { Input } from "@/components/atoms/shadcn/ui/input";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import {
  ControlledInput,
  RowInputData,
  SelectWithDefault,
  ValidationGroupButton,
  ValidationsImages,
} from "@/components/molecules";
import { MKTValidationInterface, ShoppingPlacesId } from "@/interfaces";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const FormValidationMKT = ({
  data,
  handleRejected,
  handleRemove,
  isEditable = true,
  control,
  errors,
  handleSubmit,
}: {
  data: MKTValidationInterface;
  handleRejected: () => void;
  handleRemove: () => void;
  isEditable: boolean;
  control: Control<MKTValidationInterface, any>;
  errors: FieldErrors<MKTValidationInterface>;
  handleSubmit: () => void;
}) => {
  return (
    <div
      key={`scan_${data.id_validation}_${data.id_user}`}
      className="w-full grid grid-cols-6 p-2"
    >
      <div className=" col-span-2 flex flex-wrap justify-center items-center gap-4">
        <Controller
          name={"evidence"}
          control={control}
          render={({ field: { value } }) => (
            <TransformWrapper>
              <TransformComponent>
                <ValidationsImages image={value == "" ? undefined : value} />
              </TransformComponent>
            </TransformWrapper>
          )}
        />
        {isEditable && (
          <ValidationGroupButton
            handleRejected={handleRejected}
            handleRemove={handleRemove}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
      <div className="col-span-4 flex flex-wrap justify-center py-4">
        <div className="grid grid-cols-3 gap-4 justify-center w-full px-4">
          <Label
            htmlFor="date_register"
            className="text-admins-text-active flex items-center justify-center"
          >
            Nombre de la Actividad:
          </Label>
          <ControlledInput
            name="name_activity"
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active flex items-center justify-center"
          >
            Descripción de la actividad
          </Label>
          <ControlledInput
            name="description"
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
        </div>
        <div className="grid grid-cols-8 w-full gap-3 text-ecolana-black text-center  px-3 py-6">
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active col-span-2 flex items-center justify-center"
          >
            Fecha de Registro
          </Label>
          <ControlledInput
            name={"date_insert"}
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active col-span-2 flex items-center justify-center"
          >
            ID de Usuario
          </Label>

          <ControlledInput
            name={"id_user"}
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />

          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active col-span-2 flex items-center justify-center"
          >
            Nombre de Usuario
          </Label>
          <ControlledInput
            name={"user_name"}
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
        </div>

        {isEditable && (
          <div className="flex flex-col w-3/4 justify-center">
            <p className="text-center text-xl text-ecolana-black">
              Agrega comentarios
            </p>
            <div className="flex flex-col w-full space-y-3">
              <Label
                className="px-3 text-admins-text-active text-sm"
                htmlFor="textArea"
              >
                Comentarios
              </Label>
              <Controller
                name={"comments"}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <>
                    <Textarea
                      name={name}
                      onChange={onChange}
                      value={value}
                      placeholder="Comentarios"
                      variant={"adminWhiteBorder"}
                      className="rounded-lg p-2"
                      id="textArea"
                      disabled={!isEditable}
                    />
                    <p className="text-ecolana-red py-3">{errors.comments?.message}</p>
                  </>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
