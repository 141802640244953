import { Dialog, DialogContent } from "@/components/atoms/shadcn/ui/dialog";
import {
  CollectionCenter,
  PropsCenter,
} from "@/interfaces/collectionCenterInterface";
import { DesktopInfoWastes, MobileInfoWastes } from "@/components/molecules";
import { useEffect, useState } from "react";
import { getCollectionCenterData } from "@/shared/functions/getCollectionCenterData";

interface Props {
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
  collectionCenter?: CollectionCenter | null;
  isBlockOtherLinks?: boolean;
}

export const DialogInfoWasteCenter = ({
  isOpen = false,
  onClose = () => {},
  collectionCenter,
  isBlockOtherLinks = false,
}: Props) => {
  const [collectionCenterData, setCollectionCenterData] = useState<PropsCenter>(
    {
      address: "",
      wastes: [],
      benefit: null,
      schedule: "",
      name: "",
      mapsLink: "",
      wazeLink: "",
      phone: "",
      id: "",
      description: "",
    }
  );
  useEffect(() => {
    if (collectionCenter != null) {
      let newData = getCollectionCenterData(collectionCenter);
      setCollectionCenterData(newData);
    }
  }, [collectionCenter]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className="max-h-svh max-w-4xl w-11/12 md:w-10/12 items-center rounded-3xl overflow-y-auto"
        style={{ zIndex: 80 }}
      >
        <DesktopInfoWastes
          isBlockOtherLinks={isBlockOtherLinks}
          collectionCenter={collectionCenterData}
        />
        <MobileInfoWastes
          isBlockOtherLinks={isBlockOtherLinks}
          collectionCenter={collectionCenterData}
        />
      </DialogContent>
    </Dialog>
  );
};
