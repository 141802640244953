import { ColumnDef } from "@tanstack/react-table";
import {  MKTRanking } from "@/interfaces";

export const columnMKT_Ranking = (): ColumnDef<MKTRanking>[] => {
  return [
    {
      accessorKey: "name_id_user",
      header: "Nombre/ID",
      cell: ({ row }) => (
        <div className="flex flex-col justify-center">
          <p className="font-bold">{row.original.name}</p>
          <p>ID: {row.original.id}</p>
        </div>
      ),
    },
    {
      accessorKey: "lastname_user",
      header: "Apellidos",
    },
    {
      accessorKey: "phone",
      header: "Teléfono",
    },
    {
      accessorKey: "email",
      header: "email",
    },
    {
      accessorKey: "birth_date",
      header: "Fecha de nacimiento",
    },
    {
      accessorKey: "gender",
      header: "Género",
    },
    {
      accessorKey: "state",
      header: "Estado",
    },
    {
      accessorKey: "town",
      header: "Municipio",
    },
    {
      accessorKey: "kg_measurements",
      header: "Kilogramos",
    },
    {
      accessorKey: "num_validations",
      header: "No. Validaciones",
    },
  ];
};
