import React, { FC, RefObject, useEffect, useMemo } from "react";
import Map, { Layer, MapRef, NavigationControl, Source } from "react-map-gl";

const defaultLocale = {
  "AttributionControl.ToggleAttribution": "Alternar atribución",
  "AttributionControl.MapFeedback": "Comentarios del mapa",
  "FullscreenControl.Enter": "Entrar en pantalla completa",
  "FullscreenControl.Exit": "Salir de pantalla completa",
  "GeolocateControl.FindMyLocation": "Encontrar mi ubicación",
  "GeolocateControl.LocationNotAvailable": "Ubicación no disponible",
  "LogoControl.Title": "Logo de Mapbox",
  "Map.Title": "Mapa",
  "NavigationControl.ResetBearing": "Restablecer orientación al norte",
  "NavigationControl.ZoomIn": "Acercar",
  "NavigationControl.ZoomOut": "Alejar",
  "ScrollZoomBlocker.CtrlMessage":
    "Usa ctrl + desplazamiento para acercar el mapa",
  "ScrollZoomBlocker.CmdMessage": "Usa ⌘ + desplazamiento para acercar el mapa",
  "TouchPanBlocker.Message": "Usa dos dedos para mover el mapa",
};

interface MapComponentProps {
  handleDialog: (id: string) => void;
  collectionCenters: any[];
  mapRef: RefObject<MapRef>;
  customMarker?: string;
  scrollZoom?: boolean;
  initialCenter?: { longitude: number; latitude: number; zoom: number };
}

const MapComponent: FC<MapComponentProps> = ({
  handleDialog,
  collectionCenters,
  customMarker,
  mapRef,
  scrollZoom = false,
  initialCenter = {
    longitude: -102.4256922299597,
    latitude: 24.13686865831984,
    zoom: 4,
  },
}) => {
  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current?.getMap();
      map.loadImage(
        customMarker
          ? customMarker
          : collectionCenters[0]?.pin
          ? collectionCenters[0].pin
          : "https://ecospace.sfo3.digitaloceanspaces.com/assets/acopio.png",
        (error, image) => {
          if (error) throw error;
          // @ts-ignore
          map.addImage("cat", image);
          map.on("click", "marker", (e) => {
            if (e.features && e.features.length > 0) {
              const feature = e.features[0];
              if (!feature.properties) return;
              if (!feature.properties.id) return;
              handleDialog(feature.properties.id);
            }
          });
        }
      );
    }
  }, [mapRef.current]);

  const collectionCentersToGeoJson = useMemo(() => {
    return {
      type: "FeatureCollection" as any,
      features: collectionCenters?.map((center: any) => ({
        type: "Feature" as any,
        properties: {
          id: center.id,
        },
        geometry: {
          type: "Point" as any,
          coordinates: [center.lng, center.lat],
        },
      })),
    };
  }, [collectionCenters]);
  if (!collectionCentersToGeoJson) return null;
  return (
    <Map
      id="myMapA"
      ref={mapRef}
      initialViewState={initialCenter}
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      locale={defaultLocale}
      scrollZoom={scrollZoom}
    >
      <Source type="geojson" data={collectionCentersToGeoJson}>
        <Layer
          type="symbol"
          id="marker"
          layout={{
            "icon-image": "cat",
            "icon-size": 0.9,
            "icon-offset": [0, -25],
          }}
        ></Layer>
      </Source>

      <NavigationControl position="bottom-right" />
    </Map>
  );
};

export default MapComponent;
