import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Product, WasteCategoriesId, WasteId } from "@/interfaces";
import { ControlledInputImageProduct } from "../ControlledInputImage/ControlledInputImageProduct";
import { Trash } from "lucide-react";

type GridViewProductImagesProps = {
  control: Control<Product, any>;
  fields: FieldArrayWithId<Product, "product_wastes", "id">[];
  errors: FieldErrors<Product>;
  isEditable: boolean;
  wastes: WasteId[];
  waste_categories: WasteCategoriesId[];
};

export const GridViewProductImages = ({
  fields,
  control,
  isEditable,
  wastes,
  errors,
  waste_categories,
}: GridViewProductImagesProps) => {
  return (
    <div className=" grid grid-cols-2 gap-4">
      <p className="col-span-2">{errors.product_wastes?.message}</p>
      {fields.map((item, idx) => {
        return (
          <Controller
            key={`Controller_ ${item.id}_waste_product_${idx}`}
            name={`product_wastes.${idx}._destroy`}
            control={control}
            render={({ field: { name, onChange, value } }) =>
              !value ? (
                <div
                  key={`${item.id}_waste_product_${idx}`}
                  className="grid grid-cols-2 gap-4 rounded-lg shadow-lg p-3"
                >
                  {isEditable && (
                    <p className="col-span-2 flex items-end justify-end">
                      <Controller
                        name={`product_wastes.${idx}._destroy`}
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                          <>
                            <Trash
                              onClick={() => onChange(true)}
                              className="text-admins-button-red justify-self-center"
                            />
                          </>
                        )}
                      />
                    </p>
                  )}
                  <ControlledInputImageProduct
                    control={control}
                    idx={idx}
                    isEditable={isEditable}
                  />
                  <div className="grid grid-cols-1">
                    <div className="flex flex-col items-center justify-center gap-2">
                      <Label
                        htmlFor={`waste_category_id_${idx}`}
                        className=" text-admins-text-active"
                      >
                        Categoría
                      </Label>
                      <Controller
                        name={`product_wastes.${idx}.waste_category_id`}
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                          <SelectWithDefault
                            id={`waste_category_id_${idx}`}
                            value={value}
                            name={name}
                            setValue={onChange}
                            selectedItems={waste_categories}
                            placeHolder="Seleccionar"
                            className="w-full"
                            variant={
                              isEditable ? "adminGreen" : "adminDisabled"
                            }
                            disabled={!isEditable}
                          />
                        )}
                      />
                      <p className="text-ecolana-wasteRed font-semibold">
                        {errors.product_wastes
                          ? errors.product_wastes[idx]?.waste_category_id
                              ?.message 
                          : null}
                      </p>
                    </div>
                    <div className="flex flex-col items-center justify-center gap-2">
                      <Label
                        htmlFor={`waste_id_${idx}`}
                        className=" text-admins-text-active"
                      >
                        Residuo
                      </Label>
                      <Controller
                        name={`product_wastes.${idx}.waste_id`}
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                          <SelectWithDefault
                            id={`waste_id_${idx}`}
                            value={value}
                            name={name}
                            setValue={onChange}
                            selectedItems={wastes}
                            placeHolder="Seleccionar"
                            className="w-full"
                            variant={
                              isEditable ? "adminGreen" : "adminDisabled"
                            }
                            disabled={!isEditable}
                          />
                        )}
                      />
                      <p className="text-ecolana-wasteRed font-semibold">
                        {errors.product_wastes
                          ? errors.product_wastes[idx]?.waste_id?.message
                          : null}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )
            }
          />
        );
      })}
    </div>
  );
};
