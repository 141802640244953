import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "@/components/atoms/shadcn/ui/card";
import { Button } from "@/components/atoms/shadcn/ui/button";
import {
  mockupAhorra,
  mockupEncuentra,
  mockupGana,
  mockupNiveles,
} from "../../../assets";
import { useTranslations } from "next-intl";
import Image, { StaticImageData } from "next/image";
import { app_home_url, CDN_URL } from "@/constants";
const texttoShow = {
  participa: (
    <p>
      Participa en nuestros
      <span className="text-ecolana-darkGreen"> RETOS </span>y aprende hasta
      convertirte en un dios reciclador{" "}
    </p>
  ),
  aprende: (
    <p>
      Si no sabes qué material tienes en tu mano
      <span className="text-ecolana-darkGreen"> DESCÚBRELO </span>
      con nuestro escaner.
    </p>
  ),
  ubica: (
    <p>
      Encuentra el lugar
      <span className="text-ecolana-darkGreen"> MÁS CERCANO </span>
      para reciclar eso que tanto has buscado.
    </p>
  ),
  conoce: (
    <p>
      Conoce más sobre tu consumo
      <span className="text-ecolana-darkGreen"> REGISTRANDO </span>
      tus tickets de compra.
    </p>
  ),
};

export const CardPhoneOptions = () => {
  const t = useTranslations();
  const [imageShowed, setImageShowed] =
    useState<StaticImageData>(mockupNiveles);
  const [isActive, setIsActive] = useState<string>("participa");
  const [textShowed, setTextShowed] = useState(texttoShow.participa);

  function buttonChangeImage(imageName: StaticImageData) {
    setImageShowed(imageName);
  }

  return (
    <section className="flex flex-wrap w-full h-full justify-center justify-items-center align-bottom mt-10 mb-0 lg:mb-12 transition-all ease-in-out duration-150 max-h-svh">
      <Card
        className="w-full lg:w-4/6 justify-center shadow-md lg:rounded-[60px] bg-white rounded-none"
        style={{
          backgroundImage: `url(${
            CDN_URL + "/assets_web/images/landing/wave-1.png)"
          }, url(${CDN_URL + "/assets_web/images/landing/wave-2.png)"}`,
          backgroundSize: "20%, 20%, 100%, 100%, 50px",
          backgroundRepeat: "no-repeat, no-repeat, no-repeat, repeat-x, repeat",
          backgroundPosition: "100% 0%, 0% 102%, center bottom",
        }}
      >
        <CardHeader className="space-y-8 lg:space-y-5">
          <h1 className="text-center text-xl lg:text-3xl font-extrabold text-ecolana-titlesGreen mt-5">
            {t("cardPhoneOptionsTitle")}
          </h1>
          <CardDescription className="text-center font-normal text-md lg:text-xl">
            {t("cardPhoneOptionsDescriptionPt1")}
            <span className="text-ecolana-darkGreen font-extrabold">
              {t("cardPhoneOptionsDescriptionStrong")}
            </span>
            {t("cardPhoneOptionsDescriptionPt2")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-wrap justify-center justify-items-center">
            <div className="flex flex-col w-1/3 order-2 lg:order-1 lg:w-1/4 items-center lg:pb-0">
              <Image className="w-1/2" src={imageShowed} alt={`Mockup Image`} />
            </div>
            <div className="order-3 lg:order-2 w-full lg:w-1/3 flex items-center justify-center text-center font-bold text-lg lg:text-2xl my-4 text-[#1B2C32]">
              {textShowed}
            </div>
            <div className="flex flex-wrap order-1 w-11/12 lg:order-3 lg:w-2/3 justify-around px-3">
              <Button
                variant={"ecolanaPhonev2"}
                size={"xl"}
                className={`my-4 lg:my-6 ${
                  isActive === "participa" ? "text-[#229C79]" : ""
                }`}
                onClick={() => {
                  setIsActive("participa");
                  buttonChangeImage(mockupNiveles);
                  setTextShowed(texttoShow.participa);
                }}
              >
                {t("cardPhoneOptionsBtnNiveles")}
              </Button>
              <Button
                variant={"ecolanaPhonev2"}
                size={"xl"}
                className={`my-4 lg:my-6 ${
                  isActive === "aprende" ? "text-[#229C79]" : ""
                }`}
                onClick={() => {
                  setIsActive("aprende");
                  buttonChangeImage(mockupGana);
                  setTextShowed(texttoShow.aprende);
                }}
              >
                {t("cardPhoneOptionsBtnGana")}
              </Button>
              <Button
                variant={"ecolanaPhonev2"}
                size={"xl"}
                className={`my-4 lg:my-6 ${
                  isActive === "ubica" ? "text-[#229C79]" : ""
                }`}
                onClick={() => {
                  setIsActive("ubica");
                  buttonChangeImage(mockupEncuentra);
                  setTextShowed(texttoShow.ubica);
                }}
              >
                {t("cardPhoneOptionsBtnEncuentra")}
              </Button>
              <Button
                variant={"ecolanaPhonev2"}
                size={"xl"}
                className={`my-4 lg:my-6 ${
                  isActive === "conoce" ? "text-[#229C79]" : ""
                }`}
                onClick={() => {
                  setIsActive("conoce");
                  buttonChangeImage(mockupAhorra);
                  setTextShowed(texttoShow.conoce);
                }}
              >
                {t("cardPhoneOptionsBtnAhorra")}
              </Button>
            </div>
          </div>
        </CardContent>
        <CardFooter className="justify-items-center justify-center align-middle items-center">
          <div className="flex flex-col items-center">
            <div className="text-md lg:text-xl w-3/4 font-extralight text-center text-[#1B2C32] pb-5 lg:pb-8">
              <p>{t("cardPhoneOptionsFooter")}</p>
            </div>
            <Button
              variant={"ecolanaLetsStart"}
              className="w-1/2 lg:w-1/3 text-sm lg:text-2xl transition duration-300 ease-in-out hover:scale-110 h-10 lg:h-14"
              onClick={() => window.open(app_home_url, "_blank")}
            >
              {t("cardPhoneOptionsFooterBtn")}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </section>
  );
};
