import { ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { ValidationsImages } from "../molecules";
import { Product, RowProduct } from "@/interfaces/productInterfaces";
import Image from "next/image";
import { validation_image } from "@/assets";

export const columnProduct = (
  getRowInformation: (data: Product) => void
): ColumnDef<Product>[] => {
  return [
    {
      accessorKey: "image",
      header: "Imagen",
      cell: ({ row }) => {
        return (
          <Image
            src={row.original.image ? row.original.image : validation_image}
            width={100}
            height={100}
            alt={`evidence_${row.original.sku}_${row.original.brand}`}
          />
        );
      },
    },
    {
      accessorKey: "date_register",
      header: "Fecha",
    },
    {
      accessorKey: "id_user",
      header: "Usuario",
      cell: ({ row }) => (
        <div className=" flex flex-col justify-center items-center">
          <p className="font-bold">{`# ${row.original.id_user}`}</p>
          <p>{`${row.original.user_name ? row.original.user_name : ""}`}</p>
        </div>
      ),
    },
    {
      accessorKey: "brand_name",
      header: "Marca",
    },
    {
      accessorKey: "company_name",
      header: "Empresa",
    },
    {
      accessorKey: "sub_brand_name",
      header: "Submarca",
    },
    {
      accessorKey: "sku",
      header: "SKU",
    },
    {
      accessorKey: "measure",
      header: "Medida",
    },
    {
      accessorKey: "type_measure_name",
      header: "Tipo de medida",
    },
    {
      accessorKey: "category",
      header: "Categoría/s",
      cell: ({ row }) => (
        <p>
          {row.original.product_wastes.length > 0
            ? `${row.original.product_wastes
                .map((item) => item.waste_category_name)
                .join(", ")}`
            : ""}
        </p>
      ),
    },
    {
      accessorKey: "waste",
      header: "Residuo/s",
      cell: ({ row }) => (
        <p>
          {row.original.product_wastes.length > 0
            ? `${row.original.product_wastes
                .map((item) => item.waste_name)
                .join(", ")}`
            : ""}
        </p>
      ),
    },
    {
      accessorKey: "details",
      header: "Detalles",
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => getRowInformation(row.original)}
            variant={"adminUnderlineGhost"}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];
};
export const columnProductRejected = (
  getRowInformation: (data: Product) => void
): ColumnDef<Product>[] => {
  return [
    {
      accessorKey: "date_register",
      header: "Fecha",
    },
    {
      accessorKey: "id_user",
      header: "Usuario",
      cell: ({ row }) => (
        <div className=" flex flex-col justify-center items-center">
          <p className="font-bold">{`# ${row.original.id_user}`}</p>
          <p>{`${row.original.user_name ? row.original.user_name : ""}`}</p>
        </div>
      ),
    },
    {
      accessorKey: "company_name",
      header: "Empresa",
    },
    {
      accessorKey: "brand_name",
      header: "Marca",
    },
    {
      accessorKey: "sub_brand_name",
      header: "Submarca",
    },
    {
      accessorKey: "sku",
      header: "SKU",
    },
    {
      accessorKey: "measure",
      header: "Medida",
      cell: ({ row }) => <p>{row.original.measure}</p>,
    },
    {
      accessorKey: "type_measure_name",
      header: "Tipo de medida",
    },
    {
      accessorKey: "category",
      header: "Categoría/s",
      cell: ({ row }) => (
        <p>
          {row.original.product_wastes.length > 0
            ? `${row.original.product_wastes
                .map((item) => item.waste_category_name)
                .join(", ")}`
            : ""}
        </p>
      ),
    },
    {
      accessorKey: "waste",
      header: "Residuo/s",
      cell: ({ row }) => (
        <p>
          {row.original.product_wastes.length > 0
            ? `${row.original.product_wastes
                .map((item) => item.waste_name)
                .join(", ")}`
            : ""}
        </p>
      ),
    },
    {
      accessorKey: "details",
      header: "Detalles",
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => getRowInformation(row.original)}
            variant={"adminUnderlineGhost"}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];
};

export const columnsProductGrid = (
  getIdCell: (row: Row<RowProduct>, idx: number) => void
): ColumnDef<RowProduct>[] => {
  return Array(3)
    .fill(0)
    .map((_, idx) => {
      return {
        header: `product_rows_${idx}`,
        columns: [
          {
            id: `expand_${idx}`,
            cell: ({ row }) => {
              return row.original[idx] ? (
                <ValidationsImages
                  image={
                    row.original[idx].image
                      ? row.original[idx].image
                      : undefined
                  }
                  variant={"greenGradient"}
                  left_text={row.original[idx].company_name}
                  right_text={row.original[idx].sku}
                  onClick={() => getIdCell(row, idx)}
                />
              ) : null;
            },
          },
        ],
      };
    });
};
