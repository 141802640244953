import React, { useState } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";

import { cn } from "@/lib/utils";
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/ui/popover";
import { Button } from "../shadcn/ui/button";
import { Calendar } from "../shadcn/ui/calendar";

export interface DatePickerWithRangeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  setValue: (date: DateRange | undefined) => void;
  value?: DateRange;
}

export function DatePickerWithRange({
  className,
  setValue,
  value,
}: DatePickerWithRangeProps) {
  const [placeHolderMessage, setPlaceholderMessage] =
    useState("Filtrar por fecha");

  const handleChange = (date: DateRange | undefined) => {
    if (date && date.from && date.to) {
      setPlaceholderMessage(
        `${format(date.from, "dd/MM/yyyy")} - ${format(date.to, "dd/MM/yyyy")}`
      );
    } else {
      setPlaceholderMessage("Filtrar por fecha");
    }
    setValue(date);
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"adminGhost"}
            className={cn(
              "justify-start text-left font-normal",
              !value && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            <p className="text-xs">
              <span className="text-base">{placeHolderMessage}</span>
            </p>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={value?.to || new Date()}
            selected={value}
            onSelect={handleChange}
            numberOfMonths={1}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
