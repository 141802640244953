import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import Image, { StaticImageData } from "next/image";
import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
  forwardRef,
} from "react";
interface FlipperCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  sideA: ReactNode;
  sideB: ReactNode;
}

interface ViewSideInterface
  extends HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof viewSideProps> {
  imgShow?: StaticImageData;
  text?: string;
  sideText?: "top" | "right" | "left" | "bottom" | null;
}

const viewSideProps = cva(
  "text-center items-center align-middle justify-center w-full h-full gap-4",
  {
    variants: {
      orientation: {
        horizontal: "flex flex-row",
        vertical: "flex flex-col",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      orientation: "horizontal",
    },
  }
);
export const FlipperCard: FC<FlipperCardProps> = ({
  sideA,
  sideB,
  className,
}) => {
  return (
    <div
      className={cn(
        "group h-full w-full flex align-middle justify-center",
        className
      )}
    >
      <div className="relative h-full w-full [transform-style:preserve-3d] group-hover:[transform:rotateY(180deg)] transition-all duration-500 ">
        <div
          className={`absolute inset-0 [backface-visibility:hidden] flex items-center justify-center`}
        >
          {sideA}
        </div>
        <div
          className={`absolute inset-0 h-full w-full [backface-visibility:hidden] [transform:rotateY(180deg)]  flex items-center justify-center`}
        >
          {sideB}
        </div>
      </div>
    </div>
  );
};

export const ViewSideFlipperCard = forwardRef<
  HTMLDivElement,
  ViewSideInterface
>(({ className, orientation, imgShow, sideText = "top", text }, ref) => {
  const Comp = "div";
  return (
    <Comp className={cn(viewSideProps({ orientation, className }))} ref={ref}>
      {sideText == "top" || sideText == "left" ? (
        <p className="text-ecolana-darkGreen text-lg lg:text-xl font-semibold">{text}</p>
      ) : null}
      {imgShow && (
        <div className="flex w-[12vh] h-[12vh] aspect-square rounded-full bg-[#a0dacd] p-4 items-center justify-center">
          <Image src={imgShow} alt=""/>
        </div>
      )}
      {sideText == "bottom" || sideText == "right" ? (
        <p className="text-ecolana-darkGreen text-lg lg:text-xl font-semibold">{text}</p>
      ) : null}
    </Comp>
  );
});

ViewSideFlipperCard.displayName = "ViewSideFlipperCard";
