import { FlipperCard, ViewSideFlipperCard } from "@/components/atoms";
import {
  ServicesEnterprises,
  serviceEnterprisesProps,
} from "@/constants/servicesEnterprises";
import Image, { StaticImageData } from "next/image";
import React, { FC } from "react";
interface CardMobileInterface {
  img: StaticImageData;
  text: string;
}

const CardForMobile: FC<CardMobileInterface> = ({ img, text }) => (
  <div
    key={`${text}_title`}
    className="w-full min-h-[24vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md flex flex-col text-center text-[#005142] font-semibold items-center space-y-4"
  >
    <div className="flex w-[12vh] h-[12vh] rounded-full bg-[#a0dacd] p-4">
      <Image src={img} alt="" />
    </div>
    <p className="text-ecolana-darkGreen text-md lg:text-2xl font-semibold">{text}</p>
  </div>
);

const GridFlipperCard = (data: serviceEnterprisesProps) => (
  <div
    key={`${data.sideA.text}_title`}
    className={`${data.sideA.gridOption} w-full min-h-[24vh] min-w-[12vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md`}
  >
    <FlipperCard
      sideA={
        <ViewSideFlipperCard
          imgShow={data.sideA.img}
          text={data.sideA.text}
          sideText={data.sideA.sideText}
          orientation={data.sideA.orientation}
        />
      }
      sideB={
        <ViewSideFlipperCard
          text={data.sideB.text}
          sideText={data.sideB.sideText}
        />
      }
    />
  </div>
);

export const ServiceTable = () => {
  return (
    <section className="flex- flex-col pt-12 px-4">
      <h2 className="text-ecolana-titlesGreen font-bold text-center text-3xl lg:text-5xl my-5 mb-12">
        Nuestros servicios
      </h2>
      {/*
      <div className="hidden lg:grid grid-cols-4 gap-4 overflow-x-auto">
        <div className="col-span-2 w-full min-h-fit p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md">
          <FlipperCard sideA={SideAHorizontal()} sideB={SideBHorizontal()} />
        </div>
        <div className="w-full min-h-[12vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md">
          <FlipperCard sideA={SideAVertical()} sideB={SideBVertical()} />
        </div>
        <div className="w-full min-h-[12vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md">
          <FlipperCard sideA={SideAVertical()} sideB={SideBVertical()} />
        </div>
        <div className="w-full min-h-[12vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md">
          <FlipperCard
            className="pt-[6vh] min-h-[12vh]"
            sideA={SideAVertical()}
            sideB={SideBVertical()}
          />
        </div>
        <div className="row-span-2 w-full min-h-[24vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md">
          <FlipperCard sideA={SideAVertical()} sideB={SideBVertical()} />
        </div>

        <div className="col-span-2 w-full min-h-[12vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md">
          <FlipperCard
            className="pt-[6vh] min-h-[12vh]"
            sideA={SideAHorizontal()}
            sideB={SideBHorizontal()}
          />
        </div>

        <div className="w-full min-h-[12vh] p-3 bg-white border-2 border-[#2cad8f8c] rounded-2xl shadow-md">
          <FlipperCard sideA={SideAVertical()} sideB={SideBVertical()} />
        </div>
      </div>*/}
      <div className="hidden lg:grid grid-cols-2  lg:grid-cols-4 gap-4 overflow-x-auto">
        {ServicesEnterprises.map((uniService) => GridFlipperCard(uniService))}
      </div>
      <div className="lg:hidden grid grid-cols-2 gap-6 px-3">
        {ServicesEnterprises.map((uniService) =>
          CardForMobile(uniService.sideA)
        )}
      </div>
    </section>
  );
};
