import { type VariantProps, cva } from "class-variance-authority";
import { Card, CardContent, CardFooter } from "../shadcn/ui/card";
import { cn } from "@/lib/utils";
import Image, { StaticImageData } from "next/image";
import replacementWaste from "@/public/images/diccionario/replacement.png";

const cardPerWasteVariants = cva(
  "bg-[#f3f3f3] flex justify-around flex-col min-h-52 w-36 border-2 border-[#48484840] rounded-3xl shadow-xl shadow-[#00000040]",
  {
    variants: {
      hoverBgColor: {
        green: "hover:bg-[#2CAD8F] hover:text-white",
        yellow: "hover:bg-[#EEB932] hover:text-white",
        red: "hover:bg-[#CE1126] hover:text-white",
        none: "",
      },
    },
    defaultVariants: {
      hoverBgColor: "none",
    },
  }
);

export interface CardPerWasteProps
  extends VariantProps<typeof cardPerWasteVariants> {
  className?: string;
  nameWaste?: string;
  imgWaste?: string | StaticImageData | null;
}

export const CardPerWaste = ({
  className,
  hoverBgColor,
  nameWaste = "Residuo",
  imgWaste = replacementWaste,
}: CardPerWasteProps) => {
  return (
    <Card className={cn(cardPerWasteVariants({ hoverBgColor, className }))}>
      <CardContent className="p-2 flex justify-center items-center align-middle">
        <Image
          className="align-middle max-h-32 aspect-square"
          src={imgWaste == null ? replacementWaste : imgWaste}
          alt=""
          width={90}
          height={90}
        />
      </CardContent>
      <CardFooter className="p-2 max-w-40 text-center">
        <p className="text-lg mx-auto line-clamp-3">{nameWaste}</p>
      </CardFooter>
    </Card>
  );
};
