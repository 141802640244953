import { FilterPerYear } from "@/components/molecules";
import { FormFilterGraph } from "@/hooks/useGraphicsData";
import React from "react";
import { DateRange } from "react-day-picker";
import { Control, Controller, FieldPath } from "react-hook-form";

export const FilterPerYearController = ({
  name,
  control,
}: {
  name: FieldPath<FormFilterGraph>;
  control: Control<FormFilterGraph>;
}) => {
  if (name.startsWith("graphsRanges") || name.startsWith("globalRange")) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FilterPerYear value={value as DateRange} rangeOnChange={onChange} />
        )}
      />
    );
  } else {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FilterPerYear value={value as string} singleOnChange={onChange} />
        )}
      />
    );
  }
};
