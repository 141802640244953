import { CDN_URL } from "@/constants";
//* Logo
export const logoEcolana = CDN_URL + "/assets_web/images/logos/logo.png";
//* Landing
import mockupAhorra from "@/public/images/landing/mockup-ahorra.webp";
import mockupEncuentra from "@/public/images/landing/mockup-encuentra.webp";
import mockupGana from "@/public/images/landing/mockup-niveles.webp";
import mockupNiveles from "@/public/images/landing/mockup-gana.webp";
import iconDictionary from "@/public/images/landing/icon_dictionary.webp";
import iconEnterprises from "@/public/images/landing/icon_enterprises.webp";
import iconMap from "@/public/images/landing/icon_map.webp";
export const landingInicioResiduos =
  CDN_URL + "/assets_web/images/landing/AppInicio_residuos.webp";
export const landingUneteReciclaGana =
  CDN_URL + "/assets_web/images/landing/UneteReciclaGana_lettering_blanco.webp";
import playStoreBadge from "@/public/images/landing/google-play-badge.png";
import AppStoreBadge from "@/public/images/landing/appStoreBadge";
import residuosFormImage from "@/public/images/landing/recycle.webp";
import FacebookIcon from "./icons/facebookIcon";
import TiktokIcon from "./icons/tiktokIcon";
import TwitterIcon from "./icons/twitterIcon";
import InstagramIcon from "./icons/instagramIcon";
//* Press
import elUniversalLogo from "@/public/images/landing/press/El_Universal_Logo.png";
import entrepreneurLogo from "@/public/images/landing/press/Entrepreneur_logo.png";
import forbesLogo from "@/public/images/landing/press/Forbes.png";
import foroTvLogo from "@/public/images/landing/press/Logo-ForoTV-2020.png";
import adn40Logo from "@/public/images/landing/press/adn40.png";
import heraldoMexicoLogo from "@/public/images/landing/press/heraldo_mexico.png";
import theHappeningLogo from "@/public/images/landing/press/thehappening-logo.png";
//* Map
import ArrowFloatingButton from "./icons/ArrowFloatingButton";
import FilterFloatingButton from "./icons/FilterFloatingButton";
import { GreenWavesFilterSheet } from "./icons/GreenWavesFilterSheet";
import ecolanitaMapDialogImg from "@/public/images/map/ecolanita.webp";
import googlemapsIcon from "@/public/images/map/googlemapsIcon.png";
import wazeIcon from "@/public/images/map/wazeIcon.png";
import miniBanner from "@/public/images/map/miniBanner.jpeg";
import miniBanner2 from "@/public/images/map/miniBanner2.jpeg";
import miniBanner3 from "@/public/images/map/miniBanner3.jpeg";
import miniBanner4 from "@/public/images/map/miniBanner4.jpeg";
import coinEcolanita from "@/public/images/map/coin.webp";
import centroAcopioLogo from "@/public/images/map/logoCentroAcopio.webp";
import banner_test_1 from "@/public/images/map/banner_test_1.jpg";
import banner_test_2 from "@/public/images/map/banner_test_2.jpg";

//* Empresas
import bottlesEnterprises from "@/public/images/empresas/bottom_enterprises.webp";
import topBottlesEnterprises from "@/public/images/empresas/top_enterprises.webp";
import campaignEnterprises from "@/public/images/empresas/campaign.webp";
import collectionCenterEnterprises from "@/public/images/empresas/collection_center.webp";
import communicationEnterprises from "@/public/images/empresas/comunication.webp";
import internalProgramsEnterprises from "@/public/images/empresas/internal_programs.webp";
import learningEnterprises from "@/public/images/empresas/learn.webp";
import programmingEnterprises from "@/public/images/empresas/programming.webp";
import recycleFriendsEnterprises from "@/public/images/empresas/recycle_friends.webp";
import analyticsEnterprises from "@/public/images/empresas/analytics.webp";
//* Lattitud R
import appStoreBadgeWhite from "@/public/images/latitudR/app_store_logo.png";
import playStoreBadgeWhite from "@/public/images/latitudR/play_store_logo.png";
import greenLogoEcolana from "@/public/images/latitudR/logo_verde.png";
import rightCollector from "@/public/images/latitudR/recolectora_derecha.png";
import logoFullLatitudR from "@/public/images/latitudR/logo_full.png";
import ecolanitaLatitud from "@/public/images/latitudR/ecolanita_latitud.png";
//* RE
import logoRE from "@/public/images/re/logore2.png";
import logoTrashLater from "@/public/images/re/LOGO-TrashLater.png";
import filterImgRe from "@/public/images/re/filtrar2.png";
//* Admins
import logo_ecolana_green from "@/public/images/admins/logo_admins_ecolana.webp";
import collection_center_icon from "@/public/images/admins/collectionCentersIcon.webp";
import validation_image from "@/public/images/admins/ValidationImage.png";
//* Papalote
import papalote_qr from "@/public/images/papalote/PapaloteQR.png";
//* Ruta del Envase
import logoTetraPak from "@/public/images/rutaDelEnvase/logoTetraPak.webp";
import mainRutaDelEnvase from "@/public/images/rutaDelEnvase/rutaDelEnvase_main.webp";
import pinTretapack from "@/public/images/rutaDelEnvase/pin_tretapack.webp";
import tetraPak_esquina_1 from "@/public/images/rutaDelEnvase/tetraPak_esquina_1.webp";
import tetraPak_esquina_2 from "@/public/images/rutaDelEnvase/tetraPak_esquina_2.webp";
import tetraPak_esquina_3 from "@/public/images/rutaDelEnvase/tetraPak_esquina_3.webp";
import tetraPak_esquina_4 from "@/public/images/rutaDelEnvase/tetraPak_esquina_4.webp";
import PASOS_1_DESARMA from "@/public/images/rutaDelEnvase/Pasos/PASOS_1_DESARMA.webp";
import PASOS_2_ESCURRE from "@/public/images/rutaDelEnvase/Pasos/PASOS_2_ESCURRE.webp";
import PASOS_3_COMPACTA from "@/public/images/rutaDelEnvase/Pasos/PASOS_3_COMPACTA.webp";
import PASOS_4_INSERTA from "@/public/images/rutaDelEnvase/Pasos/PASOS_4_INSERTA.webp";
import PASOS_5_ENTREGA from "@/public/images/rutaDelEnvase/Pasos/PASOS_5_ENTREGA.webp";
import iconoAcopioCasa from "@/public/images/rutaDelEnvase/Icons/icono_AcopioCasa.webp";
import iconoCentroCompactacion from "@/public/images/rutaDelEnvase/Icons/icono_centroCompactacion.webp";
import iconoCentroReciclaje from "@/public/images/rutaDelEnvase/Icons/icono_centroReciclaje.webp";
import pantalla_ecolana_app from "@/public/images/rutaDelEnvase/pantalla_ecolana_app.webp";
import pin_recicla_tetra from "@/public/images/rutaDelEnvase/pin_recicla_tetra.webp";
import cc_connect_ca from "@/public/images/rutaDelEnvase/cc_connect_ca.webp";
import Vector1 from "@/public/images/rutaDelEnvase/Vector1.png";
import Vector2 from "@/public/images/rutaDelEnvase/Vector2.png";
import Vector3 from "@/public/images/rutaDelEnvase/Vector3.png";
import Vector4 from "@/public/images/rutaDelEnvase/Vector4.png";
import Vector5 from "@/public/images/rutaDelEnvase/Vector5.png";
//* Ruta de la Pila
import IMUReciclaLogo from "@/public/images/rutaDeLaPila/IMURecicla_logo.webp";
import pila_binoculares from "@/public/images/rutaDeLaPila/pila_binoculares.webp";
import pila_card from "@/public/images/rutaDeLaPila/pila_card.webp";
import half_pila_card from "@/public/images/rutaDeLaPila/half_pila_card.svg";

import RutaDeLaPila_logo from "@/public/images/rutaDeLaPila/RutaDeLaPila_logo.webp";
import torre_imu_ciudad from "@/public/images/rutaDeLaPila/torre_imu_ciudad.webp";
import link_line_1 from "@/public/images/rutaDeLaPila/link_line_1.svg";
import link_line_2 from "@/public/images/rutaDeLaPila/link_line_2.svg";

export {
  mockupAhorra,
  mockupEncuentra,
  mockupGana,
  mockupNiveles,
  iconDictionary,
  iconEnterprises,
  iconMap,
  playStoreBadge,
  AppStoreBadge,
  residuosFormImage,
  elUniversalLogo,
  entrepreneurLogo,
  forbesLogo,
  foroTvLogo,
  adn40Logo,
  heraldoMexicoLogo,
  theHappeningLogo,
  FacebookIcon,
  TiktokIcon,
  TwitterIcon,
  InstagramIcon,
  ArrowFloatingButton,
  FilterFloatingButton,
  GreenWavesFilterSheet,
  ecolanitaMapDialogImg,
  googlemapsIcon,
  wazeIcon,
  miniBanner,
  miniBanner2,
  miniBanner3,
  miniBanner4,
  banner_test_1,
  banner_test_2,
  coinEcolanita,
  centroAcopioLogo,
  bottlesEnterprises,
  topBottlesEnterprises,
  campaignEnterprises,
  collectionCenterEnterprises,
  communicationEnterprises,
  internalProgramsEnterprises,
  learningEnterprises,
  programmingEnterprises,
  recycleFriendsEnterprises,
  analyticsEnterprises,
  playStoreBadgeWhite,
  appStoreBadgeWhite,
  greenLogoEcolana,
  rightCollector,
  logoFullLatitudR,
  ecolanitaLatitud,
  logoRE,
  logoTrashLater,
  filterImgRe,
  logo_ecolana_green,
  collection_center_icon,
  validation_image,
  papalote_qr,
  logoTetraPak,
  mainRutaDelEnvase,
  pinTretapack,
  tetraPak_esquina_1,
  tetraPak_esquina_2,
  tetraPak_esquina_3,
  tetraPak_esquina_4,
  PASOS_1_DESARMA,
  PASOS_2_ESCURRE,
  PASOS_3_COMPACTA,
  PASOS_4_INSERTA,
  PASOS_5_ENTREGA,
  iconoAcopioCasa,
  iconoCentroCompactacion,
  iconoCentroReciclaje,
  pantalla_ecolana_app,
  pin_recicla_tetra,
  cc_connect_ca,
  Vector1,
  Vector2,
  Vector3,
  Vector4,
  Vector5,
  IMUReciclaLogo,
  pila_binoculares,
  pila_card,
  half_pila_card,
  RutaDeLaPila_logo,
  torre_imu_ciudad,
  link_line_1,
  link_line_2,
};
