import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import React from "react";
import { TableCardWastes } from "../TableCardWastes/TableCardWastes";
import {
  BadgeInfoInterface,
  DataGraphicObjectType,
  dataGraphicsType,
} from "@/interfaces";
import { WasteValidationsContainer } from "@/components/molecules";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { Control, UseFormWatch, useWatch } from "react-hook-form";
import { useGraphicsData } from "@/hooks";

export interface DemographicsTabProps {
  control_badge: Control<BadgeInfoInterface, any>;
  watch_badge: UseFormWatch<BadgeInfoInterface>;
}

const dataGraphicsDemographicsApp: DataGraphicObjectType = {
  type_1: [
    {
      title: "Género",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Acumulación de KG por GÉNERO",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Edad",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Acumulación de KG por EDAD",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Ubicaciones de usuarios (TOP 10)",
      subtitle: "*Estado",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Ubicaciones de usuarios (TOP 10)",
      subtitle: "*Municipio",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos con más validaciones por EDAD (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos con más validaciones por GÉNERO (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_2: [
    {
      title: "Género",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Acumulación de KG por GÉNERO",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Edad",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Acumulación de KG por EDAD",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Ubicaciones de usuarios (TOP 10)",
      subtitle: "*Estado",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Ubicaciones de usuarios (TOP 10)",
      subtitle: "*Municipio",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos con más validaciones por EDAD (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos con más validaciones por GÉNERO (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
  ],
};
const dataGraphicsDemographicsBadgeApp: DataGraphicObjectType = {
  type_1: [
    {
      title: "Género",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "doughnut",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Edad",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Ubicaciones de usuarios (TOP 10)",
      subtitle: "*Estado",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Ubicaciones de usuarios (TOP 10)",
      subtitle: "*Municipio",
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_2: [],
};
export const DemographicsTab = ({
  control_badge,
  watch_badge,
}: DemographicsTabProps) => {
  const typeBadge = useWatch({ control: control_badge, name: "typeBadge" });
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_recycler",
      "enterprises",
      "brands",
      "waste_categories",
      "wastes",
      "waste_category_enterprises",
      "waste_enterprises",
    ],
    keysYear: [
      "year_history",
      "enterprise_waste_categories",
      "enterprise_wastes",
    ],
  });
  return (
    <TabsContent
      value="app_demographics"
      className="w-full grid grid-cols-2 gap-4 px-3"
    >
      {typeBadge !== "type_3" && dataGraphicsDemographicsApp[typeBadge]?.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
      {typeBadge == "type_1" && (
        <p className="text-admins-text-active font-bold text-xl col-span-2">
          Usuarios con búsquedas en INSIGNIA
        </p>
      )}
      {typeBadge !== "type_3" && dataGraphicsDemographicsBadgeApp[typeBadge]?.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          title={item.title}
          subtitle={item.subtitle}
          filter={
            //   <FilterPerYearController
            //     name={item.nameFilter}
            //     control={control}
            //   />
            <></>
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
    </TabsContent>
  );
};
