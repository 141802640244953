import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import {
  AppActivityTab,
  DemographicsTab,
  RewardBadgeTab,
  SearchersTab,
} from "../MarketingTabs";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { BadgeInfoInterface } from "@/interfaces";

interface MarketingOverviewTabsInterface {
  watch: UseFormWatch<BadgeInfoInterface>;
  control: Control<BadgeInfoInterface, any>;
  setValue_badge: UseFormSetValue<BadgeInfoInterface>;
}

export const MarketingOverviewTabs = ({
  watch,
  control,
  setValue_badge,
}: MarketingOverviewTabsInterface) => {
  const typeBadge = watch("typeBadge");
  return (
    <Tabs
      className="w-full flex flex-wrap items-center align-middle justify-center gap-5"
      onValueChange={(value: string) => {
        setValue_badge("selectedTab", value);
      }}
      value={watch("selectedTab")}
    >
      <div className="flex w-full justify-end pr-10">
        <TabsList className="w-fit h-14 rounded-2xl px-4 space-x-5">
          <TabsTrigger
            variant={"adminsTab"}
            className="w-1/2"
            value="app_activity"
          >
            Actividad APP
          </TabsTrigger>
          {typeBadge !== "type_3" && (
            <>
              <TabsTrigger
                variant={"adminsTab"}
                className="w-1/2"
                value="rewards"
              >
                Recompensas
              </TabsTrigger>
              <TabsTrigger
                variant={"adminsTab"}
                className="w-1/2"
                value="app_demographics"
              >
                Demográficos App
              </TabsTrigger>
            </>
          )}
          <TabsTrigger variant={"adminsTab"} className="w-1/2" value="searches">
            Búsquedas
          </TabsTrigger>
        </TabsList>
      </div>
      <AppActivityTab
        control_badge={control}
        watch_badge={watch}
        setValue_badge={setValue_badge}
      />
      <RewardBadgeTab control_badge={control} watch_badge={watch} />
      <DemographicsTab control_badge={control} watch_badge={watch} />
      <SearchersTab control_badge={control} watch_badge={watch} />
    </Tabs>
  );
};
