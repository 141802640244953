import { CardBGGradient } from "@/components/atoms";
import { cardBGGradientVariants } from "@/components/atoms/CardBGGradient/CardBGGradient";
import { cn } from "@/lib/utils";
import { VariantProps } from "class-variance-authority";
import { StaticImageData } from "next/image";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import Image from "next/image";
import { validation_image } from "@/assets";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { X } from "lucide-react";
interface ValidationsImagesProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof cardBGGradientVariants> {
  image?: string | StaticImageData;
  left_text?: string;
  right_text?: string;
  closeIcon?: boolean;
}

export const ValidationsImages = ({
  image = validation_image,
  left_text = "",
  right_text = "",
  variant,
  className,
  closeIcon = false,
  onClick,
}: ValidationsImagesProps) => {
  return (
    <CardBGGradient
      onClick={onClick}
      className={cn(
        className,
        "flex-wrap aspect-square items-center justify-center pt-3"
      )}
      variant={variant}
    >
      {(left_text || right_text) && (
        <div className="bg-white px-4 py-2 rounded-xl flex flex-col xl:flex-row w-full mx-4 my-2 justify-around text-md text-admins-text-inactive">
          <p className="text-xs">{left_text}</p>
          <p className="text-xs">{right_text}</p>
        </div>
      )}
      {closeIcon && (
        <div className="bg-white px-4 py-2 rounded-xl flex flex-wrap w-fit mx-4 my-2 justify-around text-md text-admins-text-inactive">
          <X className="text-admins-text-active mr-2 w-5 h-5" />
        </div>
      )}
      <Image
        src={image}
        width={300}
        height={300}
        alt="Evidence_image"
        className="w-full max-h-96 max-w-96 rounded-2xl aspect-square mb-4"
        style={{ objectFit: "contain" }}
      />
    </CardBGGradient>
  );
};
