import React from "react";
import { Dialog, DialogContent } from "../shadcn/ui/dialog";
import { Loader2 } from "lucide-react";
type ModalMessageProps = {
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
  isMarkers?: boolean;
  isLoading?: boolean;
};
export const ModalMessage = ({
  isOpen = false,
  onClose = () => {},
  isMarkers,
  isLoading,
}: ModalMessageProps) => {
  return (
    <Dialog open={isOpen}>
      <DialogContent className="max-h-svh max-w-4xl md:w-10/12 items-center overflow-auto ">
        <div className="flex flex-col w-full items-center justify-center">
          {isMarkers && (
            <p className="text-2xl text-center">
              Lo siento, no hemos encontrado un centro de acopio en el lugar que
              has seleccionado, por favor, realiza otra búsqueda.
            </p>
          )}
          {isLoading && (
            <>
              <Loader2 size={64} className="animate-spin" />
              <p className="text-2xl text-center">Cargando centros de acopio</p>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
