import { DialogAdmins } from "@/components/atoms";
import { Control, Controller } from "react-hook-form";
import { CC_Form } from "@/interfaces";
import { Button } from "@/components/atoms/shadcn/ui/button";
import Image from "next/image";
import { validation_image } from "@/assets";

type DialogResponseNewCCProps = {
  isOpen: boolean;
  onClose: () => void;
  control: Control<CC_Form, any>;
  isCC: boolean;
};

export const DialogResponseNewCC = ({
  isOpen,
  onClose,
  control,
  isCC,
}: DialogResponseNewCCProps) => {
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Formato horarios de operación"
      variant={"green"}
    >
      {isCC ? (
        <div className="flex flex-col">
          <h3 className="text-center text-2xl text-ecolana-black">
            Se ha creado centro de acopio con éxito
          </h3>
        </div>
      ) : (
        <div className="flex flex-col">
          <h3 className="text-center text-2xl text-ecolana-black">
            Se ha creado recolector con éxito
          </h3>
          <Controller
            control={control}
            name="qr_code"
            render={({ field: { value } }) => (
              <Image
                src={value ? value : validation_image}
                width={300}
                height={300}
                alt="qr_image"
              />
            )}
          />
          <Button variant={"adminGray"} onClick={onClose}>
            Descargar más tarde
          </Button>
        </div>
      )}
    </DialogAdmins>
  );
};
