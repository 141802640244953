import { Separator } from "@/components/atoms/shadcn/ui/separator";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { ReactNode } from "react";
import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { Tabs } from "@/components/atoms/shadcn/ui/tabs";
const WasteValidationsContainerVariant = cva(
  "flex flex-wrap p-2 shadow-lg rounded-2xl",
  {
    variants: {
      variant: {
        default: "bg-white",
        collection_centers: "bg-admins-collection_centers bg-opacity-15",
        green_points: "bg-admins-green_points bg-opacity-15",
        collectors: "bg-admins-collectors bg-opacity-15",
        activity_general_green:
          "bg-admins-activity_general_green bg-opacity-15",
        activity_scan_yellow: "bg-admins-activity_scan_yellow bg-opacity-15",
        activity_recycling_violet:
          "bg-admins-activity_recycling_violet bg-opacity-15",
        activity_evidence_blue:
          "bg-admins-activity_evidence_blue bg-opacity-15",
      },
      borderVariant: {
        default: "border-none",
        activity_general_green:
          "border-2 border-admins-activity_general_green ",
        activity_scan_yellow: "border-2 border-admins-activity_scan_yellow",
        activity_recycling_violet:
          "border-2 border-admins-activity_recycling_violet",
        activity_evidence_blue: "border-2 border-admins-activity_evidence_blue",
      },
    },
    defaultVariants: {
      variant: "default",
      borderVariant: "default",
    },
  }
);
interface WasteValidationsContainerProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof WasteValidationsContainerVariant> {
  children?: ReactNode;
  title: string;
  filter?: JSX.Element;
  subtitle?: string;
  defaultValueTabName?: string;
  infoButton?: JSX.Element;
  onValueTabChange?: ((value: string) => void) | undefined;
}
export const WasteValidationsContainer = ({
  className,
  title,
  children,
  filter,
  subtitle,
  variant,
  borderVariant,
  defaultValueTabName,
  infoButton,
  onValueTabChange,
  onClick,
}: WasteValidationsContainerProps) => {
  return (
    <Tabs
      defaultValue={defaultValueTabName}
      className={cn(
        WasteValidationsContainerVariant({ variant, borderVariant, className })
      )}
      onValueChange={onValueTabChange}
      orientation="vertical"
      onClick={onClick}
    >
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 justify-evenly py-3 items-center w-[100%]">
        <div className="col-span-2 md:col-span-1">
          <p className="text-admins-text-active text-xl font-bold">
            {title} {infoButton}
          </p>
          {subtitle && (
            <p className="text-admins-text-active text-xxs">{subtitle}</p>
          )}
        </div>
        {filter && filter}
      </div>
      <Separator className="bg-[#E6E8F0]" orientation="horizontal" />
      <div className="w-full flex flex-wrap py-2 items-center justify-center">
        {children}
      </div>
    </Tabs>
  );
};
