import { Checkbox } from "@/components/atoms/shadcn/ui/checkbox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/shadcn/ui/popover";
import {
  ControlledSelectWithDefault,
  SelectWithDefault,
} from "@/components/molecules";
import { FormFilterGraph } from "@/hooks/useGraphicsData";
import { Calendar } from "lucide-react";
import React from "react";
import { Control, Controller, FieldPath, useWatch } from "react-hook-form";
import FacebookIcon from "../../../assets/icons/facebookIcon";

// Array con los nombres de los meses
const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: currentYear - 2019 }, (_, i) => ({
  id: String(2020 + i),
  name: String(2020 + i),
}));

export const FilterPerWeekMonthController = ({
  name_checkbox,
  name_date,
  control,
}: {
  name_checkbox: FieldPath<FormFilterGraph>;
  name_date: FieldPath<FormFilterGraph>;
  control: Control<FormFilterGraph>;
}) => {
  const name_checkbox_watch = useWatch({
    control: control,
    name: name_checkbox,
    defaultValue: "week",
  });
  return (
    <div className="grid grid-cols-3">
      <Controller
        name={name_checkbox}
        control={control}
        defaultValue={"week"}
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-row gap-3 items-center align-middle">
            <Checkbox
              checked={value == "week"}
              onClick={() => onChange("week")}
              variant={"greenAdmin"}
            />
            <p>Semana</p>
          </div>
        )}
      />

      <Controller
        name={name_checkbox}
        control={control}
        defaultValue={"week"}
        render={({ field: { onChange, value } }) => (
          <div className="flex flex-row gap-3 items-center align-middle">
            <Checkbox
              checked={value == "month"}
              onClick={() => onChange("month")}
              variant={"greenAdmin"}
            />
            <p>Mes</p>
          </div>
        )}
      />

      {name_checkbox_watch == "week" ? (
        <Popover>
          <PopoverTrigger className="">
            <Calendar className="text-admins-text-active text-center cursor-pointer flex m-1" />
          </PopoverTrigger>
          <PopoverContent className="flex flex-col gap-2">
            <ControlledSelectWithDefault
              control={control}
              selectedItems={[
                { id: "2020", name: "2020" },
                { id: "2021", name: "2021" },
                { id: "2022", name: "2022" },
                { id: "2023", name: "2023" },
                { id: "2024", name: "2024" },
              ]}
              name={name_date}
              placeholder="Año"
            />
            <Controller
              name={name_date}
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  {/* Selector de Mes */}
                  <div className="grid grid-cols-3 gap-2">
                    {months.map((month, index) => (
                      <button
                        key={index}
                        onClick={() => {
                          const selectedYear =
                            typeof value == "string"
                              ? value.split("-")[0] || "2020"
                              : "2020";
                          const selectedMonth = String(index + 1).padStart(
                            2,
                            "0"
                          );
                          onChange(`${selectedYear}-${selectedMonth}`);
                        }}
                        className="p-2 text-center rounded-lg cursor-pointer hover:bg-admins-text-active hover:text-white"
                      >
                        {month}
                      </button>
                    ))}
                  </div>
                </>
              )}
            />
          </PopoverContent>
        </Popover>
      ) : (
        <ControlledSelectWithDefault
          control={control}
          selectedItems={[
            { id: "2020", name: "2020" },
            { id: "2021", name: "2021" },
            { id: "2022", name: "2022" },
            { id: "2023", name: "2023" },
            { id: "2024", name: "2024" },
          ]}
          name={name_date}
          placeholder="Año"
        />
      )}
    </div>
  );
};
