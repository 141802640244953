import { CardAdvertisement } from "@/components/atoms";
import { CardAdvertisementProps } from "@/components/atoms/CardAdvertisement/CardAdvertisement";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import { cn } from "@/lib/utils";
import Autoplay from "embla-carousel-autoplay";
import React, { HTMLAttributes, useRef } from "react";
interface CarouselAdvertisementProps extends HTMLAttributes<HTMLDivElement> {
  pluginFlag?: boolean;
  showBtns?: boolean;
  advertisement?: CardAdvertisementProps[];
  isBanner?: boolean;
}

export const CarouselAdvertisement = ({
  pluginFlag = false,
  showBtns = false,
  advertisement,
  isBanner,
  className,
}: CarouselAdvertisementProps) => {
  const plugin = useRef(Autoplay({ delay: 4000, stopOnInteraction: true }));

  return (
    <div className={cn("w-full h-fit flex items-center justify-center", className)}>
      <Carousel
        opts={{
          loop: true,
          align: "start",
        }}
        plugins={pluginFlag ? [plugin.current] : []}
        className="flex w-full h-full max-w-xs md:max-w-xl"
      >
        <CarouselContent className="-ml-0">
          {[1, 2, 3, 4]?.map((uniAdvertisement, idxAdvertisement) => (
            <CarouselItem
              key={`${idxAdvertisement}_imageCarousel`}
              className={`${
                isBanner ? "basis-full" : "basis-11/12"
              } pl-2 items-center align-middle`}
            >
              <CardAdvertisement />
            </CarouselItem>
          ))}
        </CarouselContent>
        {showBtns && (
          <>
            <CarouselNext />
            <CarouselPrevious />
          </>
        )}
      </Carousel>
    </div>
  );
};
