import Link from "next/link";
import { Button } from "../shadcn/ui/button";

interface StepCardProps {
  textCard: string;
  numStep: number;
  isBtn?: boolean;
  textBtn?: string;
  linkBtn?: string;
}

export const StepCard = ({
  textCard,
  numStep,
  isBtn,
  textBtn,
  linkBtn = "",
}: StepCardProps) => {
  return (
    <div className="flex flex-col w-5/12 lg:w-48 bg-slate-50 rounded-xl items-center text-center mx-2 lg:mx-20 min-h-48 my-4 lg:my-6 px-2 lg:px-4 py-3 justify-center relative">
      <div
        style={{
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        }}
        className="absolute -top-[25px] bg-[#19dc69] filter w-[50px] h-[50px] rounded-full text-white flex justify-center items-center"
      >
        {numStep}
      </div>
      <div className="font-bold">
        <p>{textCard}</p>
      </div>
      {isBtn && (
        <Button variant={"latitudRSolid"} className="mt-4 lg:text-sm text-sm">
          <Link href={linkBtn}>{textBtn}</Link>
        </Button>
      )}
    </div>
  );
};
