import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from "@/components/atoms/shadcn/ui/carousel";
import { TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import {
  BadgeCampaignCard,
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { FormFilterGraph, useGraphicsData } from "@/hooks";
import {
  Control,
  Controller,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useEffect, useState } from "react";
import { EmblaCarouselType } from "embla-carousel";
import { BadgeInfoInterface, DataTabType3ObjectType } from "@/interfaces";
import { Button } from "@/components/atoms/shadcn/ui/button";

const cardsPerTab: DataTabType3ObjectType[] = [
  {
    overview: [
      {
        title: "Kilos Acumulados",
        subtitle: " *Contempla validaciones aceptadas",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Escaneos Acumulados",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Usuarios activos reciclaje",
        subtitle: "*Al menos una actividad",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Usuarios activos escaneo",
        subtitle: "*Al menos una actividad",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Usuarios con TyC",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
    rewards: [
      {
        title: "Total de códigos otorgados",
        subtitle: "*No contempla ecolanitas",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Total de ecolanitas especiales",
        subtitle:
          "*Especiales: Las que se otorgan por insignia. Contempla ambas actividades",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Total de ecolanitas ordinarias",
        subtitle:
          "*Ordinarias: Las que se otorgan por actividad subsecuente. Contempla ambas actividades",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
    demographics: [
      {
        title: "Total de ecolanitas especiales",
        subtitle: "*Al menos una actividad.",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Promedio de edad usuarios participantes",
        subtitle: "*Contempla todas las actividades",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Promedio género usuarios participantes",
        subtitle: "*Contempla todas las actividades",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
  },
  {
    overview: [
      {
        title: "TOTAL DE ESCANEOS",
        subtitle: "*Contempla todos",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Promedio de SKU's únicos escaneados por usuario",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Sku's participantes",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Sku's escaneados",
        subtitle: "*No contempla más de uno del mismo tipo ",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Residuos escaneados",
        subtitle: "*No contempla más de uno del mismo tipo",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Categorías escaneadas",
        subtitle: "*No contempla más de uno del mismo tipo",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
    rewards: [
      {
        title: "Total de ecolanitas otorgadas por escaneos",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Total de usuarios con el máximo de recompensas",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
    demographics: [
      {
        title: "Total de usuarios únicos",
        subtitle: "*Al menos una actividad.",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Promedio de edad usuarios participantes",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Promedio género usuarios participantes",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
  },
  {
    overview: [
      {
        title: "Usuarios Activos ",
        subtitle: "*Contempla usuarios únicos",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Kilogramos acumulados",
        subtitle: "*Contempla Validaciones aceptadas",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Prom. de Validaciones por usuario",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Prom. de Kg validados por usuario",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Prom. de Kg por validación",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "No. de acopios participantes",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
    rewards: [
      {
        title: "Total de códigos otorgados",
        subtitle: "*No contempla ecolanitas",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Total de ecolanitas especiales",
        subtitle: "*Especiales: Las que se otorgan por insignia",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Total de ecolanitas ordinarias",
        subtitle: "*Ordinarias: Las que se otorgan por actividad subsecuente",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "No. usuarios con códigos otorgados",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Total de códigos restantes",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
    demographics: [
      {
        title: "Total de usuarios únicos",
        subtitle: "*Al menos una actividad.",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Promedio de edad usuarios participantes",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Promedio género usuarios participantes",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
  },
  {
    overview: [
      {
        title: "Usuarios Activos",
        subtitle: "*Contempla usuarios únicos",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Prom. de evidencias por usuario",
        subtitle: undefined,
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
      {
        title: "Usuarios concluyentes",
        subtitle: "*Completaron todas las actividades de evidencia",
        nameFilter: "graphsYear.year_history",
        name_graphic_data: "validation_center_types",
      },
    ],
    rewards: [],
    demographics: [],
  },
];

export const CarouselCampaignBadgesTabs = ({
  watch_badge,
  control_graphics,
  control_badge,
  setValue_badge,
}: {
  watch_badge: UseFormWatch<BadgeInfoInterface>;
  control_graphics: Control<FormFilterGraph, any>;
  control_badge: Control<BadgeInfoInterface, any>;
  setValue_badge: UseFormSetValue<BadgeInfoInterface>;
}) => {
  const [api, setApi] = useState<CarouselApi>();
  useEffect(() => {
    if (!api) {
      return;
    }
    api.on("select", () => {
      setValue_badge("carouselIndex", api.selectedScrollSnap());
      setValue_badge("selectedTabCarousel", "overview");
    });
  }, [api]);
  return (
    <>
      <div className="flex w-full justify-center px-14 items-center">
        <Carousel
          setApi={setApi}
          opts={{ loop: true, align: "start" }}
          className="flex w-full"
        >
          <CarouselContent className="-ml-2 md:-ml-4">
            <CampaignBadgesItems
              control={control_graphics}
              control_badge={control_badge}
              api_carousel={api}
              watch_badge={watch_badge}
            />
          </CarouselContent>
          <CarouselPrevious className="bg-white fill-white hidden lg:flex" />
          <CarouselNext className="bg-white fill-white hidden lg:flex" />
        </Carousel>
      </div>
    </>
  );
};

const CampaignBadgesItems = ({
  control,
  control_badge,
  api_carousel,
  watch_badge,
}: {
  control: Control<FormFilterGraph, any>;
  control_badge: Control<BadgeInfoInterface, any>;
  watch_badge: UseFormWatch<BadgeInfoInterface>;
  api_carousel?: EmblaCarouselType;
}) => {
  const tabSelected = watch_badge("selectedTabCarousel");
  const carouselIndex = watch_badge("carouselIndex");
  return [
    "Actividad General Insignia",
    "Actividad Escaneo",
    "Actividad Reciclaje",
    "Actividad Evidencia",
  ].map((uni_title_card, index) => (
    <CarouselItem className={`basis-11/12 my-5 mx-1 `} key={index}>
      <WasteValidationsContainer
        key={`uni_data_${index}_${uni_title_card}`}
        title={`${uni_title_card}`}
        variant={`${
          index == 0
            ? "activity_general_green"
            : index == 1
            ? "activity_scan_yellow"
            : index == 2
            ? "activity_recycling_violet"
            : "activity_evidence_blue"
        }`}
        filter={
          <div className=" bg-slate-100 col-span-2 md:col-span-1 justify-self-center flex items-center align-middle w-fit h-14 justify-between rounded-2xl px-4 ">
            <Controller
              name={"selectedTabCarousel"}
              control={control_badge}
              render={({ field: { name, onChange, value } }) => (
                <Button
                  onClick={() => {
                    onChange("overview");
                  }}
                  variant={value == "overview" ? "adminsTab" : "adminGhost"}
                  className="font-"
                >
                  Overview
                </Button>
              )}
            />
            <Controller
              name={"selectedTabCarousel"}
              control={control_badge}
              render={({ field: { name, onChange, value } }) => (
                <Button
                  onClick={() => {
                    onChange("rewards");
                  }}
                  variant={value == "rewards" ? "adminsTab" : "adminGhost"}
                >
                  Recompensas
                </Button>
              )}
            />
            <Controller
              name={"selectedTabCarousel"}
              control={control_badge}
              render={({ field: { name, onChange, value } }) => (
                <Button
                  onClick={() => {
                    onChange("demographics");
                  }}
                  variant={value == "demographics" ? "adminsTab" : "adminGhost"}
                >
                  Demográficos
                </Button>
              )}
            />
          </div>
        }
      >
        <div className="w-full grid lg:grid-cols-3 grid-cols-2 gap-6">
          {cardsPerTab[carouselIndex][
            carouselIndex == index ? tabSelected : "overview"
          ].map((uni_data_map, idx) => (
            <WasteValidationsContainer
              key={`uni_card_data_${idx}_tab_type_3_${uni_data_map.title}`}
              className={`w-full`}
              title={uni_data_map.title}
              subtitle={uni_data_map.subtitle}
              filter={
                <FilterPerYearController
                  name={"graphsRanges"}
                  control={control}
                />
              }
            >
              <CardNumOverview
                variant={"collection_centers"}
                className="w-9/12 aspect-square items-center"
              />
            </WasteValidationsContainer>
          ))}
        </div>
      </WasteValidationsContainer>
    </CarouselItem>
  ));
};
