import { CardBGGradient } from "@/components/atoms";
import { cardBGGradientVariants } from "@/components/atoms/CardBGGradient/CardBGGradient";
import { cn } from "@/lib/utils";
import { VariantProps } from "class-variance-authority";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";


interface CardNumOverviewProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof cardBGGradientVariants> {
  children?: ReactNode;
  number?: string;
}

export const CardNumOverview = ({ number = "1234", className, variant }: CardNumOverviewProps) => {
  return (
    <CardBGGradient
      key={`CardBG_default`}
      variant={variant}
      className={cn(className)}
    >
      <p className=" text-admins-text-active text-5xl items-center font-extrabold">
        {number}
      </p>
    </CardBGGradient>
  );
};
