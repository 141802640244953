import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";
import { Dialog, DialogContent } from "../shadcn/ui/dialog";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

interface DialogAdminsProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof dialogAdminVariants> {
  children?: ReactNode;
  title: string;
  subtitle?: string;
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
}

const dialogAdminVariants = cva("z-0 flex w-full absolute -top-10 h-32", {
  variants: {
    variant: {
      default: "bg-white",
      green: "bg-admins-select-selected_items_bg ",
      orange: "bg-[#F63F3440]",
      red: "bg-[#FF507140]",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export const DialogAdmins = ({
  children,
  title,
  isOpen,
  onClose,
  variant,
  className,
  subtitle,
}: DialogAdminsProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-h-svh max-w-4xl md:w-10/12 items-center overflow-auto ">
        <div className={cn(dialogAdminVariants({ variant }))}></div>
        <div className="relative flex flex-wrap w-full items-center justify-center mt-2 space-y-3">
          <div className="w-full flex flex-col -mt-3 mb-10">
            <h1 className="text-center text-admins-text-active text-2xl font-bold">
              {title}
            </h1>
            {subtitle && (
              <p className="text-center text-admins-text-active text-lg font-bold">
                {subtitle}
              </p>
            )}
          </div>
          {children}
        </div>
      </DialogContent>
    </Dialog>
  );
};
