import { bottlesEnterprises } from "../../../assets";
import { Button } from "@/components/atoms/shadcn/ui/button";
import Image from "next/image";
import Link from "next/link";
import React from "react";

export const AddCompanyBottles = () => {
  return (
    <section className="flex flex-wrap w-full justify-around p-5 my-5 text-[#212529]">
      <div className="flex flex-col w-full lg:w-1/2 lg:px-3 text-center items-center justify-around">
        <h2 className="mt-8 lg:mt-14 font-extrabold text-3xl lg:text-5xl">
          ¿Te gustaría sumar tu empresa a la cultura del reciclaje?
        </h2>
        <div className="text-xl w-full font-semibold">
          <p>
            Solicita tu asesoría <strong>gratis</strong>
          </p>
        </div>
        <div className="flex w-full m-5 align-middle justify-center">
          <Button variant={"ecolanaLetsStartv2"} className="w-1/2">
            <Link href={"#comment-form"}>Contactanos</Link>
          </Button>
        </div>
      </div>
      <div className="flex w-1/2 lg:w-1/4 lg:px-3 justify-center align-middle text-center">
        <Image
          src={bottlesEnterprises}
          alt="bottles_enterprises"
          className="w-full"
        />
      </div>
    </section>
  );
};
