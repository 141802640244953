import { Footer, FooterLatitudR, NavbarLatitudR } from "@/components/organisms";
import { ReactNode } from "react";
import { AvenirNextedium, GothamRounded } from "@/fonts";
import Head from "next/head";
import { RutaDeLaPila_logo } from "@/assets";
import { DataScripts } from "@/components/DataScripts";

export const ViajeDeLaPilaLayout = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <Head>
        <title>La Ruta de la Pila</title>
        <meta
          name="description"
          content="Encuentra las columnas de IMU Recicla en tu camino y deposita tus pilas alcalinas usadas"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <meta name="og:title" content="La Ruta de la Pila" />
        <meta
          name="og:description"
          content="Encuentra las columnas de IMU Recicla en tu camino y deposita tus pilas alcalinas usadas"
        />
        <meta name="og:image" content={RutaDeLaPila_logo.src} />
        <meta name="og:url" content={`https://ecolana.com.mx/latitud-r`} />
        <meta name="og:type" content="website" />
        <meta name="og:locale" content="es_MX" />
        <meta name="og:site_name" content="Ecolana" />
        <meta name="twitter:title" content="La Ruta de la Pila" />
        <meta
          name="twitter:description"
          content="Encuentra las columnas de IMU Recicla en tu camino y deposita tus pilas alcalinas usadas"
        />
        <meta name="twitter:image" content={RutaDeLaPila_logo.src} />
        <meta name="twitter:card" content="summary" />
      </Head>
      <main
        className={`flex flex-col min-h-svh w-full   ${AvenirNextedium.variable} ${GothamRounded.variable}
        `}
      >
        <NavbarLatitudR />
        <div className="flex-grow">{children}</div>
        <Footer />
      </main>
      <DataScripts />
    </>
  );
};
