import { DialogAdmins } from "@/components/atoms";
import { DataTable } from "@/components/molecules";
import { columnMKT_CCs } from "@/components/columns";
import { usePagination } from "@/hooks";

type DialogCCListMKTProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const DialogCCListMKT = ({
  isOpen,
  onClose,
}: DialogCCListMKTProps) => {
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    setTotal,
    setTotalPages,
    setCurrentPage,
    onChangePageSize,
    pageSize,
  } = usePagination(10);
  const pagination = {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    paginationLabel,
    last_page,
    onChangePageSize,
    pageSize,
  };
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Editar centro de acopio"
      variant={"green"}
    >
      <div className="w-full flex justify-center items-center px-5 py-8">
        <DataTable
          columns={columnMKT_CCs()}
          data={[]}
          pagination={pagination}
        />
      </div>
    </DialogAdmins>
  );
};
