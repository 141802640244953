import { DialogAdmins } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";

type DialogConfirmationProps = {
  isOpen: boolean;
  onClose?: (() => void) | undefined;
  handleSubmit: () => void;
};
export const DialogConfirmation = ({
  isOpen,
  onClose,
  handleSubmit,
}: DialogConfirmationProps) => {
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Eliminar"
      variant={"red"}
    >
      <div className="grid grid-cols-5 gap-6">
        <p className="col-span-5">¿Estás segur@ de eliminar este elemento?</p>
        <Button onClick={onClose} className="col-span-2" variant={"adminGray"}>
          Cancelar
        </Button>
        <Button
          onClick={handleSubmit}
          className="col-span-2 col-start-4"
          variant={"adminGray"}
        >
          Aceptar
        </Button>
      </div>
    </DialogAdmins>
  );
};
