import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { ButtonHTMLAttributes } from "react";
import Image, { StaticImageData } from "next/image";

const squareButtonImageVariants = cva(
  "p-1 rounded-2xl aspect-square flex justify-center items-center",
  {
    variants: {
      hoverColor: {
        none: "",
        green: "hover:bg-[#2CAD8F] hover:shadow-xl",
      },
    },
    defaultVariants: {
      hoverColor: "none",
    },
  }
);

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof squareButtonImageVariants> {
  asChild?: boolean;
  srcImg: StaticImageData;
}

const SquareButtonImage = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, hoverColor, srcImg, ...props }, ref) => {
    return (
      <button
        className={cn(squareButtonImageVariants({ hoverColor, className }))}
        ref={ref}
        {...props}
      >
        <Image
          className="w-2/3"
          src={srcImg}
          alt=""
        />
      </button>
    );
  }
);
SquareButtonImage.displayName = "SquareButtonImg";

export { SquareButtonImage };
