import { Button } from "@/components/atoms/shadcn/ui/button";
import { Label } from "@/components/atoms/shadcn/ui/label";
import {
  ControlledInput,
  RowInputDataCCValidation,
  SelectWithDefault,
  ValidationGroupButton,
  ValidationsImages,
} from "@/components/molecules";
import { CampaignsOptions } from "@/constants/campaignsData";
import {
  WasteCategoriesId,
  WasteId,
  collectionCenterValidations,
} from "@/interfaces";
import { CirclePlus } from "lucide-react";
import React from "react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const FormCCValidation = ({
  data,
  handleRejected,
  handleRemove,
  isEditable = true,
  fields,
  control,
  errors,
  handleSubmit,
  wastes,
  waste_categories,
  badges,
  handleAddNewWaste,
}: {
  data: collectionCenterValidations;
  handleRejected: () => void;
  handleRemove: () => void;
  isEditable: boolean;
  fields: FieldArrayWithId<
    collectionCenterValidations,
    "wastes_validations",
    "id"
  >[];
  control: Control<collectionCenterValidations, any>;
  errors: FieldErrors<collectionCenterValidations>;
  handleSubmit: () => void;
  wastes: WasteId[];
  badges: CampaignsOptions[];
  waste_categories: WasteCategoriesId[];
  handleAddNewWaste: () => void;
}) => {
  return (
    <div className=" grid grid-cols-10">
      <div className="col-span-3 flex flex-col gap-5">
        <Controller
          name={"evidence"}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <TransformWrapper>
              <TransformComponent>
                <ValidationsImages image={value} />
              </TransformComponent>
            </TransformWrapper>
          )}
        />
        <div className="grid grid-cols-3 gap-4 justify-center px-4">
          <Label
            htmlFor="date_register"
            className="text-admins-text-active flex items-center justify-center"
          >
            Fecha de Registro
          </Label>
          <ControlledInput
            label={"Fecha de registro"}
            name={`date_insert`}
            control={control}
            variant={"adminGray"}
            placeholder={"Fecha de Registro"}
            className="col-span-2"
            disabled
          />
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active flex items-center justify-center"
          >
            ID de Usuario
          </Label>
          <ControlledInput
            label={"ID de Usuario"}
            name={`id_user`}
            control={control}
            variant={"adminGray"}
            placeholder={"ID de Usuario"}
            className="col-span-2"
            disabled
          />
          <Controller
            name="user_name"
            control={control}
            render={({ field: { name, value, onChange } }) =>
              value ? (
                <>
                  <Label
                    htmlFor="purchase_place"
                    className="text-admins-text-active flex items-center justify-center"
                  >
                    Nombre de usuario
                  </Label>
                  <ControlledInput
                    label={"ID de Usuario"}
                    name={`user_name`}
                    control={control}
                    variant={"adminGray"}
                    placeholder={"ID de Usuario"}
                    className="col-span-2"
                    disabled
                  />
                </>
              ) : (
                <></>
              )
            }
          />
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active flex items-center justify-center"
          >
            Insignia
          </Label>
          <ControlledInput
            control={control}
            name={"campaign_name"}
            type="text"
            variant={"adminWhiteBorder"}
            className="col-span-2 w-full"
            disabled
          />
        </div>
        {isEditable && (
          <ValidationGroupButton
            handleSubmit={handleSubmit}
            handleRemove={handleRemove}
            handleRejected={handleRejected}
          />
        )}
      </div>
      <div className="col-span-7">
        <div className=" flex justify-center items-center">
          {isEditable && (
            <Button
              className="mx-10 my-7 justify-self-start bg-admins-button-green rounded-lg text-md w-fit"
              onClick={handleAddNewWaste}
            >
              <CirclePlus className="text-white mx-4 w-5 h-5" />
              Agregar Residuo
            </Button>
          )}
        </div>
        <div className="grid grid-cols-7 w-full gap-3 text-ecolana-black text-center px-3">
          <p className=" col-start-2 col-span-3 font-semibold">Cantidad</p>
          <p className=" col-span-3 font-semibold ">Residuo</p>
        </div>
        <div className="grid grid-cols-7 w-full gap-3 text-ecolana-black text-center px-3 mb-7">
          {fields.map((item, idx) => (
            <Controller
              key={`fields_${item.id}_${idx}`}
              name={`wastes_validations.${idx}._destroy`}
              control={control}
              render={({ field: { name, value, onChange } }) =>
                !value ? (
                  <RowInputDataCCValidation
                    key={`${item.id}_validation_row_${idx}`}
                    handleRemoveSkuFromList={onChange}
                    control={control}
                    errors={errors}
                    isActive={isEditable}
                    idx={idx}
                    waste_categories={waste_categories}
                    wastes={wastes}
                  />
                ) : (
                  <></>
                )
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};
