import { DialogAdmins } from "@/components/atoms";
import React, { RefObject } from "react";
import { FormCreateCC } from "../FormCreateCC/FormCreateCC";
import { FormCreateCollector } from "../FormCreateCollector/FormCreateCollector";
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import {
  CC_Form,
  Schedules,
  Settlement,
  States,
  Towns,
  WasteCategoriesId,
  WasteId,
  WastePerCategoryID,
  ZipCodes,
} from "@/interfaces";
import { MapRef } from "react-map-gl";
import { CampaignsOptions } from "@/constants/campaignsData";
type DialogDetailedInfoCCProps = {
  isOpen: boolean;
  onClose: () => void;
  control_cc: Control<CC_Form, any>;
  fields_cc: FieldArrayWithId<CC_Form, "wastes", "id">[];
  fields_cc_campaign: FieldArrayWithId<CC_Form, "campaign", "id">[];
  append_cc: UseFieldArrayAppend<CC_Form, "wastes">;
  setValue_cc: UseFormSetValue<CC_Form>;
  getValue_cc: UseFormGetValues<CC_Form>;
  remove_cc: UseFieldArrayRemove;
  watch_cc: UseFormWatch<CC_Form>;
  isCC: boolean;
  wastes?: WasteId[];
  wastes_categories?: WasteCategoriesId[];
  wastes_perCategory: WastePerCategoryID[];
  states: States[];
  towns: Towns[];
  zip_codes: ZipCodes[];
  settlement: Settlement[];
  nups?: ZipCodes[];
  mapRef: RefObject<MapRef>;
  schedules?: Schedules[];
  campaigns: CampaignsOptions[];
  handleDialogNewSchedule: () => void;
  handleCampaignChange: (id: string) => void;
  onSubmitValidCC: () => void;
  fields_cc_socials: FieldArrayWithId<CC_Form, "socials", "id">[];
  append_cc_socials: UseFieldArrayAppend<CC_Form, "socials">;
};
export const DialogDetailedInfoCC = ({
  isOpen,
  onClose,
  control_cc,
  fields_cc,
  append_cc,
  setValue_cc,
  getValue_cc,
  remove_cc,
  watch_cc,
  isCC,
  nups,
  states,
  towns,
  zip_codes,
  settlement,
  wastes,
  wastes_categories = [],
  wastes_perCategory = [],
  campaigns,
  handleDialogNewSchedule,
  mapRef,
  schedules,
  onSubmitValidCC,
  handleCampaignChange,
  fields_cc_socials,
}: DialogDetailedInfoCCProps) => {
  return (
    <DialogAdmins
      isOpen={isOpen}
      onClose={onClose}
      title="Editar centro de acopio"
      variant={"green"}
    >
      <div className="w-full flex justify-center items-center p-10">
        <FormCreateCC
          isOpen={!isCC}
          control={control_cc}
          fields={fields_cc}
          watch={watch_cc}
          append={append_cc}
          remove={remove_cc}
          setValue_CC={setValue_cc}
          getValue_cc={getValue_cc}
          fields_socials={fields_cc_socials}
          states={states}
          towns={towns}
          zip_codes={zip_codes}
          settlement={settlement}
          schedules={schedules}
          wastes={wastes}
          wastes_categories={wastes_categories}
          wastes_perCategory={wastes_perCategory}
          mapRef={mapRef}
          campaigns={campaigns}
          handleDialogNewSchedule={handleDialogNewSchedule}
          handleCampaignChange={handleCampaignChange}
          onSubmitValidCC={onSubmitValidCC}
        />
        <FormCreateCollector
          isOpen={isCC}
          control={control_cc}
          fields={fields_cc}
          append={append_cc}
          remove={remove_cc}
          watch={watch_cc}
          wastes={wastes}
          wastes_categories={wastes_categories}
          wastes_perCategory={wastes_perCategory}
          nups={nups}
          states={states}
          schedules={schedules}
          handleDialogNewSchedule={handleDialogNewSchedule}
          onSubmitValidCC={onSubmitValidCC}
        />
      </div>
    </DialogAdmins>
  );
};
