import { CampaignProps } from "@/constants/campaignsData";
import Image from "next/image";
import React, { useState } from "react";

export const AccordionHorizontal = ({
  campaign,
}: {
  campaign: CampaignProps;
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const numColsWidth = `w-${String(13 - campaign?.steps.length)}/12`;

  return (
    <div className="flex flex-row  w-full md:w-8/12 min-h-[50vh] justify-center">
      {campaign?.steps.map((x, index) => {
        return (
          <div
            className={`mx-1 lg:mx-2 h-[45vh] bg-[#F1F1F1] rounded-[30px] mt-[2.5vh] cursor-pointer transition-all ease-linear duration-500 ${
              index == selectedIndex ? numColsWidth : "w-1/12"
            }`}
            key={index + "_card"}
            onClick={() => setSelectedIndex(index)}
          >
            {index === selectedIndex ? (
              <div
                className="grid grid-cols-2 w-full h-full pb-7 px-7"
                key={index + "_step"}
              >
                <div className="col-span-2">
                  <p
                    className={
                      "text-xl 2xl:text-4xl mt-3 text-center font-extrabold text-[#002F2F]"
                    }
                  >
                    {x.text}
                  </p>
                </div>
                <div className="flex h-full align-bottom items-end">
                  <p
                    className={
                      "text-2xl 2xl:text-4xl text-left text-[#343434] font-extrabold"
                    }
                  >
                    Paso {index + 1}
                  </p>
                </div>
                <div className="flex h-full items-end align-bottom justify-end">
                  <Image
                    src={campaign.steps[index].img}
                    alt={`Paso_${index}`}
                    className="xl:w-3/4 2xl:w-2/3"
                  />
                </div>
              </div>
            ) : (
              <div
                className="relative w-full h-full pb-3"
                key={index + "_unselected"}
              >
                <div className=" absolute bottom-6 left-0 xl:left-5 w-full p-0">
                  <p
                    style={{
                      writingMode: "vertical-rl",
                    }}
                    className="text-base lg:text-2xl 2xl:text-4xl -rotate-180 text-[#343434] font-extrabold"
                  >
                    {"Paso " + (index + 1)}
                  </p>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
