import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from "react";

const cardBGGradientVariants = cva(
  "flex justify-center text-center rounded-2xl shadow-sm",
  {
    variants: {
      variant: {
        default: "border border-white backdrop-blur-sm",
        defaultValue:
          "bg-gradient-to-b from-[#c6e9b41a] via-[#0832211A] to-[#D2E2DD1A] backdrop-blur-sm",
        greenGradient:
          "bg-[#5BB79D] bg-opacity-20 border border-white backdrop-blur-sm",
        horizontalCard: "backdrop-blur-sm shadow-xl rounded-xl ",
        greenActive: "bg-[#5BB79DC2] border border-white backdrop-blur-sm",
        whiteDisabled: "bg-[#FFFFFFB2] border border-white backdrop-blur-sm",
        collection_centers: "bg-[#C6D2FD33] border border-white backdrop-blur-sm",
        collectors: "bg-[#FFDA1B33] border border-white backdrop-blur-sm",
        green_points: "bg-[#5BB79D33] border border-white backdrop-blur-sm",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);
interface CardBGGradientProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    VariantProps<typeof cardBGGradientVariants> {
  children?: ReactNode;
}
const CardBGGradient = ({
  className,
  variant,
  children,
  onClick,
}: CardBGGradientProps) => {
  return (
    <div
      onClick={onClick}
      className={cn(cardBGGradientVariants({ variant, className }))}
    >
      {children}
    </div>
  );
};

export { cardBGGradientVariants, CardBGGradient };
