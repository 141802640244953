import { type VariantProps, cva } from "class-variance-authority";
import { cn } from "@/lib/utils";
import Image, { StaticImageData } from "next/image";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { Button } from "../shadcn/ui/button";
import Link from "next/link";
import { CDN_URL, app_home_url } from "@/constants";
import { StaticImport } from "next/dist/shared/lib/get-img-props";
import { pila_card } from "@/assets";

export interface PilaCardProperties
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  number?: string;
  textFill?: string;
  imgWaste?: string | StaticImageData | null;
  isBtn?: boolean;
}

export const PilaCard = ({
  number,
  textFill,
  className,
  isBtn,
}: PilaCardProperties) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center text-center justify-center relative h-full lg:max-h-40 xl:min-h-48 bg-[url('/images/rutaDeLaPila/half_pila_card.svg')] lg:bg-[url('/images/rutaDeLaPila/pila_card.webp')] ",
        className
      )}
      style={{
        // backgroundImage:
        //   "url('/images/rutaDeLaPila/half_pila_card.svg')",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        objectFit:"contain"
        
      }}
    >
      {/* <Image  alt={textFill ?? "Images"} src={pila_card}  className="absolute "
      style={{
        transform: "rotate(90deg)",
        objectFit: "contain"
      }}
    /> */}
      <div
        style={{
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
        }}
        className="absolute -top-[25%] -left-5 lg:-top-[15px] lg:left-5 bg-[#0470AD] filter w-[50px] h-[50px] rounded-full text-white flex justify-center items-center border border-white"
      >
        {number}
      </div>
      <div className="font-bold pt-8 mx-7 lg:px-10 lg:py-8 xl:px-16 xl:py-10 flex flex-col items-center justify-center text-center">
        {isBtn && (
          <p
            className="text-[#4AD5F1] text-sm lg:text-xl cursor-pointer"
            onClick={() => window.open(app_home_url, "_blank")}
          >
            Descarga la app
          </p>
        )}
        <p className="text-white text-sm lg:text-xl">{textFill}</p>
      </div>
    </div>
  );
};
