import { RefAttributes, useState } from "react";
import { ChevronDown, ChevronUp, LucideIcon } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../shadcn/ui/collapsible";
import { Button } from "../shadcn/ui/button";
import * as CollapsiblePrimitive from "@radix-ui/react-collapsible";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";

interface CollapsibleMenuProps
  extends CollapsiblePrimitive.CollapsibleContentProps,
    RefAttributes<HTMLDivElement> {
  icon_to_show: LucideIcon | JSX.Element | undefined;
  main_option: string;
  sub_options: {
    label: string;
    path: string;
    isSuboptions?: boolean;
    sub_options?: { label: string; path: string }[];
  }[];
}

export const CollapsibleMenu = ({
  className,
  icon_to_show,
  main_option,
  sub_options,
}: CollapsibleMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const pathname = usePathname();
  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className={cn(className)}
    >
      <div className="flex items-center justify-around  px-2 rounded-2xl bg-[#F3F4F6]">
        <>{icon_to_show}</>
        <h4 className="text-sm font-semibold w-3/6">{main_option}</h4>
        <CollapsibleTrigger asChild>
          <Button variant="ghost" size="sm" className="w-1/6 p-0">
            {!isOpen && <ChevronDown className="text-[#4C6C60] h-4 w-4" />}
            {isOpen && <ChevronUp className="text-[#4C6C60] h-4 w-4" />}
            <span className="sr-only">Toggle</span>
          </Button>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent className="space-y-2">
        {sub_options.map((uni_option) => {
          if (uni_option.isSuboptions) {
            return (
              <div key={`${uni_option.label}_link`} className="flex flex-wrap">
                <a
                  key={`${uni_option.label}_link`}
                  className="w-full"
                  href={uni_option.path}
                >
                  <p
                    className={`w-full hover:bg-admins-select-selected_items_bg pl-14 px-4 py-3 text-sm ${
                      uni_option.sub_options?.find(
                        (element) => pathname == element.path
                      )
                        ? "bg-admins-select-selected_items_bg"
                        : ""
                    }`}
                  >
                    {uni_option.label}
                  </p>
                </a>
                <div className=" flex flex-col w-full">
                  {uni_option.sub_options?.map((uni_sub_option) => (
                    <a
                      key={`${uni_sub_option.label}_sub_link`}
                      href={uni_sub_option.path}
                    >
                      <div
                        className={`${
                          pathname === uni_sub_option.path
                            ? "text-admins-text-active"
                            : "text-admins-text-default"
                        } pl-20 px-4 py-3 text-sm`}
                      >
                        {uni_sub_option.label}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            );
          } else {
            return (
              <a
                key={`${uni_option.label}_link`}
                href={uni_option.path}
              >
                <div
                  className={`${
                    pathname === uni_option.path
                      ? "text-admins-text-active"
                      : "text-admins-text-default"
                  } pl-14 px-4 py-3 text-sm  hover:bg-admins-select-selected_items_bg`}
                >
                  {uni_option.label}
                </div>
              </a>
            );
          }
        })}
      </CollapsibleContent>
    </Collapsible>
  );
};
