import { Controller, useForm } from "react-hook-form";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { residuosFormImage } from "../../../assets";
import { ControlledInput } from "@/components/molecules";
import { useTranslations } from "next-intl";
import Image from "next/image";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import axios from "axios";
import { apiBaseURLV1 } from "@/constants";

const schema = z.object({
  name: z.string({ required_error: "NOMBRE: Es un campo es requerido, " }),
  phone: z
    .string({ required_error: "TELÉFONO: Es un campo es requerido" })
    .min(10, { message: "TELÉFONO: debe ser más largo" })
    .max(13, { message: "TELÉFONO: debe ser más corto" }),
  email: z
    .string({ required_error: "EMAIL: Es un campo es requerido," })
    .email("EMAIL: Por favor, ingrese un email válido"),
  comment: z
    .string({ required_error: "COMENTARIO: Es un campo es requerido" })
    .min(5, { message: "COMENTARIO: debe ser más largo" }),
});
interface ContactProps {
  name: string;
  phone: string;
  email: string;
  comment: string;
}
export const ContactForm = ({ origin }: { origin: string }) => {
  const t = useTranslations();
  const [showMessage, setShowMessage] = useState<string>("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<ContactProps>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      phone: "",
      email: "",
      comment: "",
    },
  });

  async function onSubmit(data: ContactProps) {
    const response = await axios.post(`${apiBaseURLV1}/leads`, {
      ...data,
      origin,
    });
    if (response.status) {
      setShowMessage(
        "Mensaje enviado con éxito, en breve nos pondremos en contacto contigo"
      );
      reset();
    } else {
      setShowMessage(
        "Hubo un error al enviar el mensaje, por favor intenta de nuevo"
      );
    }
  }

  return (
    <section
      className="flex flex-wrap justify-around w-full bg-[#002f2f]"
      id="comment-form"
    >
      <div className="flex lg:w-5/12">
        <Image
          className="w-full aspect-auto"
          src={residuosFormImage}
          alt="Residuos en formulario"
        />
      </div>
      <div className="flex flex-col w-full lg:w-1/3 justify-center align-middle justify-items-center p-4">
        <div className="text-3xl mb-4 font-extrabold w-full text-white text-center">
          <p>{t("contactFormTitle")}</p>
        </div>

        <div className="grid grid-cols-4 w-full space-y-6 gap-4 justify-center items-center">
          <ControlledInput
            type="name"
            placeholder={t("inputName")}
            className="col-span-4 w-full bg-transparent text-white font-bold"
            control={control}
            name={"name"}
            variant={"ghost"}
            error={errors.name?.message}
          />
          <ControlledInput
            className="col-span-4 lg:col-span-2 w-full"
            type="tel"
            placeholder={t("inputPhone")}
            control={control}
            name={"phone"}
            variant={"ghost"}
          />
          <ControlledInput
            className="col-span-4 lg:col-span-2 w-full"
            type="email"
            placeholder={t("inputEmail")}
            control={control}
            name={"email"}
            variant={"ghost"}
          />
          <Controller
            control={control}
            name={"comment"}
            render={({ field }) => (
              <>
                <Textarea
                  placeholder={t("inputComment")}
                  {...field}
                  className="w-full col-span-4 bg-transparent text-white font-bold"
                />
                <p>{errors.comment?.message}</p>
              </>
            )}
          />
          <Button
            className=" bg-ecolana-mediumBlue text-white font-extrabold text-xl rounded-3xl col-start-2  col-span-2"
            onClick={handleSubmit(onSubmit)}
          >
            {t("btnSend")}
          </Button>
          <p className={`font-semibold text-white text-center col-span-4`}>
            {errors.name?.message}
            {errors.phone?.message}
            {errors.email?.message}
            {errors.comment?.message}
            {showMessage}
          </p>
        </div>
      </div>
    </section>
  );
};
