import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { colors_admin } from "@/styles/fullConfig";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
    },
  },
};

const labels = [
  "Enero",
  "Feb",
  "Mar",
  "Abr",
  "May",
  "Jun",
  "Jul",
  "Ago",
  "Sep",
  "Oct",
  "Nov",
  "Dic",
];

export const dataPreview = {
  labels,
  datasets: [
    {
      label: "Validaciones totales",
      data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120],
      backgroundColor: colors_admin.graphics.total_validations,
    },

    {
      label: "Validaciones pendientes",
      data: [100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 5, 1],
      backgroundColor: colors_admin.graphics.pending_validations,
    },
    {
      label: "Validaciones aceptadas",
      data: [5, 25, 65, 45, 75, 80, 123, 50, 40, 30, 10, 15],
      backgroundColor: colors_admin.graphics.approved_validations,
    },
    {
      label: "Validaciones rechazadas",
      data: [5, 25, 65, 45, 75, 80, 123, 50, 40, 30, 10, 15],
      backgroundColor: colors_admin.graphics.rejected_validations,
    },
  ],
};

export const BarChartHistoricValidations = ({
  data = dataPreview,
}: {
  data?: ChartData<"bar", (number | [number, number] | null)[], unknown>;
}) => {
  return (
    <div className="w-full flex items-center justify-center">
      <Bar
        options={options}
        data={{
          ...data,
          labels,
        }}
      />
    </div>
  );
};
