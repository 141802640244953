import { logoFullLatitudR, rightCollector } from "@/assets";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/shadcn/ui/popover";
import { FormAskForCollector } from "@/components/molecules";
import { Plus } from "lucide-react";
import Image from "next/image";
import React from "react";

export const FormSectionLatituR = () => {
  return (
    <section className="flex flex-wrap justify-center">
      <div className="w-full flex flex-col md:w-1/3 justify-between">
        <h1 className="text-white text-3xl md:pl-5 font-medium ml-2 md:ml-6 mt-12">
          En Ecolana queremos
          <span className="text-[#19dc69] font-medium"> CONECTAR </span>
          ciudadanos con recolectores de base.
        </h1>
        <div className="w-full relative flex flex-row text-white md:pl-5">
          <div className="w-1/2 flex flex-col items-center justify-around">
            <p>¿Quiénes son ellos?</p>
            <Popover>
              <PopoverTrigger>
                <Plus className="animate-pulse bg-[#19dc69] text-white border-2 w-[30px] h-[30px] text-center cursor-pointer rounded-full flex p-0 m-0 " />
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex justify-between space-x-4">
                  <p
                    style={{
                      color: "#3F3F3F",
                      fontWeight: "300",
                    }}
                    className="text-center fs-6 mt-3 mt-lg-0"
                  >
                    Los{" "}
                    <label style={{ color: "#19DC69" }}>
                      recolectores de base
                    </label>{" "}
                    desempeñan un papel esencial al recolectar, separar y vender
                    materiales reciclables a centros de acopio, generando{" "}
                    <label style={{ color: "#19DC69" }}>ingresos</label> y
                    contribuyendo al{" "}
                    <label style={{ color: "#19DC69" }}>
                      manejo de residuos
                    </label>{" "}
                    en todo el país.
                    <br />
                    <br />
                    Estos dedicados hombres y mujeres son{" "}
                    <label style={{ color: "#19DC69" }}>
                      fundamentales
                    </label>{" "}
                    para fortalecer nuestras iniciativas de gestión de residuos.
                  </p>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className="w-1/2 flex flex-col items-center justify-end mt-10 space-y-6">
            <p>¿Como los identificas?</p>
            <Popover>
              <PopoverTrigger>
                <Plus className="animate-pulse bg-[#19dc69] text-white border-2 w-[30px] h-[30px] text-center cursor-pointer rounded-full flex p-0 m-0 " />
              </PopoverTrigger>
              <PopoverContent>
                <div className="flex justify-between space-x-4">
                  <p
                    style={{
                      color: "#616161",
                      fontWeight: "300",
                    }}
                    className="mt-3 text-center fs-6"
                  >
                    Los <label style={{ color: "#19DC69" }}>recolectores</label>{" "}
                    llevan{" "}
                    <label style={{ color: "#19DC69" }}>chaleco azul</label>{" "}
                    empadronado
                  </p>
                </div>
              </PopoverContent>
            </Popover>
          </div>
        </div>
        <Image src={rightCollector} className="w-3/4" alt="" />
      </div>
      <div className="w-full flex flex-col md:w-2/3 justify-center space-y-4 px-3 text-center items-center mt-10 md:mt-5 pb-10 md:mb-0">
        <p className="text-white text-lg">
          <span className="text-[#19dc69] font-bold">¡Regístrate</span> y recibe
          más información de cómo participar!
        </p>
        <FormAskForCollector />
        <Image
          src={logoFullLatitudR}
          className="w-11/12 md:w-2/3"
          alt="Latitud_R_partners"
        />
      </div>
    </section>
  );
};
