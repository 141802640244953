import {
  aluminum,
  aluminumSelected,
  capsule,
  capsuleSelected,
  cardboard,
  cardboardSelected,
  flexPlastic,
  flexPlasticSelected,
  glass,
  glassSelected,
  hardPlastic,
  hardPlasticSelected,
  paper,
  paperSelected,
  pet,
  petSelected,
  tin,
  tinSelected,
} from "@/public/images/re/wastes";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { cn } from "@/lib/utils";
import { useState } from "react";
import Image, { StaticImageData } from "next/image";
import { Label } from "@/components/atoms/shadcn/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/atoms/shadcn/ui/select";
import { States } from "@/interfaces/statesTownsInterfaces";
import { Control, Controller } from "react-hook-form";
import { FormFilterValues } from "@/hooks/useMapData";

export interface Wastes {
  id: string;
  name: string;
  selected: boolean;
}

const wastesImages: {
  id: string;
  imgDefault: StaticImageData;
  imgSelected: StaticImageData;
}[] = [
  { id: "15", imgDefault: aluminum, imgSelected: aluminumSelected },
  { id: "22", imgDefault: cardboard, imgSelected: cardboardSelected },
  { id: "4", imgDefault: paper, imgSelected: paperSelected },
  { id: "18", imgDefault: pet, imgSelected: petSelected },
  {
    id: "20",
    imgDefault: hardPlastic,
    imgSelected: hardPlasticSelected,
  },
  {
    id: "48",
    imgDefault: flexPlastic,
    imgSelected: flexPlasticSelected,
  },
  { id: "5", imgDefault: glass, imgSelected: glassSelected },
  { id: "17", imgDefault: tin, imgSelected: tinSelected },
  { id: "26", imgDefault: capsule, imgSelected: capsuleSelected },
];

interface FormReFiltersProps extends React.HTMLAttributes<HTMLDivElement> {
  states: States[];
  control: Control<FormFilterValues>;
  openAccordionPrev?: boolean;
  handleSearch: () => void;
}

export const FormReFilters = ({
  className,
  states,
  control,
  openAccordionPrev = false,
  handleSearch,
}: FormReFiltersProps) => {
  const [openAccordion, setOpenAccordion] = useState(openAccordionPrev);
  const [wastes, setWastes] = useState<Wastes[]>([
    { id: "15", name: "aluminio", selected: false },
    { id: "22", name: "cartonlaminado", selected: false },
    { id: "4", name: "papelycarton", selected: false },
    { id: "18", name: "pet", selected: false },
    { id: "20", name: "plasticoduro", selected: false },
    { id: "48", name: "plasticoflexible", selected: false },
    { id: "5", name: "vidrio", selected: false },
    { id: "17", name: "hojalata", selected: false },
    { id: "26", name: "capsula", selected: false },
    { id: "12", name: "carton", selected: false },
    { id: "82", name: "polipropileno", selected: false },
    { id: "83", name: "polietileno", selected: false },
    { id: "19", name: "hdpe", selected: false },
  ]);

  return (
    <div
      className={cn(
        className,
        "bg-[url('/images/re/banner_textura.jpg')]  rounded-3xl w-full md:w-[350px] border-2 animate-accordion-down duration-500 items-center justify-center p-3 space-y-2 lg:ml-6"
      )}
    >
      <h2 className="text-2xl">Filtar por:</h2>
      <Label className="text-xl" htmlFor="states">
        Estado
      </Label>
      <Controller
        name="state"
        control={control}
        render={({ field }) => (
          <select
            name={field.name}
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value as string);
            }}
            className="py-3 px-4 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
          >
            <option value="">Selecciona un estado</option>
            {states.map((uni_state) => (
              <option
                key={`${uni_state.name}_selected_option_re`}
                value={uni_state.id}
              >
                {uni_state.name}
              </option>
            ))}
          </select>
        )}
      />
      <Button
        className=" bg-[#ffffff80] w-full border-none cursor-pointer rounded-xl text-black hover:bg-[#34793b] hover:text-white"
        onClick={() => setOpenAccordion(!openAccordion)}
      >
        Elegir material +
      </Button>
      <div
        className={`rounded-2xl bg-[#ffffff80] flex flex-wrap gap-2 animate-accordion-down justify-center items-center ${
          openAccordion ? "" : "hidden"
        }`}
      >
        <Controller
          control={control}
          name="or_wastes"
          render={({ field }) => (
            <>
              {wastes.map(
                (uniWaste, idx) =>
                  idx < 9 && (
                    <div
                      key={`${idx}_uniWaste`}
                      className=" cursor-pointer w-3/12"
                      onClick={() => {
                        let aux =
                          uniWaste.id === "4" ? ["4", "12"] : [uniWaste.id];
                        const newSelected = field.value?.includes(aux[0])
                          ? field.value.filter(
                              (uni) =>
                                uni !== aux[0] &&
                                (aux[1] ? uni !== aux[1] : true)
                            )
                          : [
                              ...(field.value ?? []),
                              ...(aux[1] ? [aux[0], aux[1]] : [aux[0]]),
                            ];
                        field.onChange(newSelected);
                      }}
                    >
                      <Image
                        className="w-full max-h-32"
                        src={
                          field.value?.includes(uniWaste.id)
                            ? wastesImages[idx].imgSelected
                            : wastesImages[idx].imgDefault
                        }
                        alt=""
                      />
                    </div>
                  )
              )}
            </>
          )}
        />
      </div>
      <Button
        onClick={handleSearch}
        className=" bg-[#34793b] border-[#34793b] border-none cursor-pointer rounded-xl w-3/4 hover:bg-[#ffffff80] hover:text-black"
      >
        Buscar
      </Button>
    </div>
  );
};
