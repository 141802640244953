import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { colors_admin } from "@/styles/fullConfig";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Chart.js Bar Chart - Stacked",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["January", "February", "March"];

const dataChart = {
  labels,
  datasets: [
    {
      label: "Residuo 3",
      data: [40, 20, 30],
      backgroundColor: colors_admin.graphics.level1,
    },
    {
      label: "Residuo 2",
      data: [40, 20, 30],
      backgroundColor: colors_admin.graphics.level2,
    },
    {
      label: "Residuo 1",
      data: [40, 20, 30],
      backgroundColor: colors_admin.graphics.level3,
    },
    {
      label: "Residuo 4",
      data: [10, 20, 10],
      backgroundColor: colors_admin.graphics.level4,
    },

    {
      label: "Residuo 5",
      data: [10, 20, 10],
      backgroundColor: colors_admin.graphics.level5,
    },
    {
      label: "Residuo 6",
      data: [10, 20, 10],
      backgroundColor: colors_admin.graphics.level6,
    },
  ],
};

export const WastesCountChart = ({
  data = dataChart,
}: {
  data?: ChartData<"bar", (number | [number, number] | null)[], unknown>;
}) => {
  return (
    <div className="w-full flex items-center justify-center">
      <Bar options={options} data={data} />
    </div>
  );
};
