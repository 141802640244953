import { Input } from "@/components/atoms/shadcn/ui/input";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import {
  RowInputData,
  SelectWithDefault,
  ValidationGroupButton,
  ValidationsImages,
} from "@/components/molecules";
import { ShoppingPlacesId } from "@/interfaces";
import { Ticket } from "@/interfaces/ticketsInterface";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const FormValidations = ({
  data,
  handleRejected,
  handleRemove,
  isEditable = true,
  fields,
  control,
  errors,
  handleSubmit,
  shopping_places,
}: {
  data: Ticket;
  handleRejected: () => void;
  handleRemove: () => void;
  isEditable: boolean;
  fields: FieldArrayWithId<Ticket, "list_sku", "id">[];
  control: Control<Ticket, any>;
  errors: FieldErrors<Ticket>;
  handleSubmit: () => void;
  shopping_places: ShoppingPlacesId[];
}) => {
  return (
    <div
      key={`scan_${data.id_ticket}_${data.sku}`}
      className="w-full grid grid-cols-6 p-2"
    >
      <div className=" col-span-2 flex flex-wrap justify-center items-center gap-4">
        <Controller
          name={"evidence"}
          control={control}
          render={({ field: { value } }) => (
            <TransformWrapper>
              <TransformComponent>
                <ValidationsImages image={value == "" ? undefined : value} />
              </TransformComponent>
            </TransformWrapper>
          )}
        />
        <div className="grid grid-cols-3 gap-4 justify-center w-full px-4">
          <Label
            htmlFor="date_register"
            className="text-admins-text-active flex items-center justify-center"
          >
            Fecha de Registro
          </Label>
          <Controller
            name="date_insert"
            control={control}
            render={({ field: { name, value } }) => (
              <Input
                name={name}
                value={value}
                variant={"adminGray"}
                className="col-span-2"
                disabled
              />
            )}
          />
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active flex items-center justify-center"
          >
            Lugar de compra
          </Label>
          <Controller
            name={"place"}
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <SelectWithDefault
                  value={value}
                  name={name}
                  setValue={onChange}
                  selectedItems={shopping_places}
                  placeHolder="Seleccionar una opción..."
                  className="col-span-2"
                  variant={isEditable ? "adminGreen" : "adminDisabled"}
                  disabled={!isEditable}
                />
                <p className="text-ecolana-wasteRed col-span-3 font-semibold">
                  {errors.place?.message}
                </p>
              </>
            )}
          />
        </div>
        {isEditable && (
          <ValidationGroupButton
            handleRejected={handleRejected}
            handleRemove={handleRemove}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
      <div className="col-span-4 flex flex-wrap justify-center py-4">
        <div className="grid grid-col-10 w-full gap-3 text-ecolana-black text-center  px-3">
          <p className="col-span-10 text-center text-admins-button-green">
            SKUs Registrados {data.user_name}
          </p>
          <p className=" col-start-2 col-span-3 font-semibold">SKU</p>
          <p className=" col-span-3 font-semibold ">Marca</p>
          <p className=" col-span-3 font-semibold ">DESCRIPCIÓN</p>
          {fields.map((item, idx) => (
            <Controller
              key={`${item.id}_validation_row_${idx}`}
              name={`list_sku.${idx}._destroy`}
              control={control}
              render={({ field: { name, value, onChange } }) =>
                !value ? (
                  <RowInputData
                    key={`${item.id}_validation_row_${idx}`}
                    handleRemoveSkuFromList={onChange}
                    control={control}
                    isActive={isEditable}
                    idx={idx}
                    errors={errors}
                  />
                ) : (
                  <></>
                )
              }
            />
          ))}
        </div>
        {isEditable && (
          <div className="flex flex-col w-3/4 justify-center">
            <p className="text-center text-xl text-ecolana-black">
              ¿Agregar comentarios?
            </p>
            <div className="flex flex-col w-full space-y-3">
              <Label
                className="px-3 text-admins-text-active text-sm"
                htmlFor="textArea"
              >
                Descripción
              </Label>
              <Controller
                name={"description"}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <>
                    <Textarea
                      name={name}
                      onChange={onChange}
                      value={value}
                      placeholder="Descripción"
                      variant={"adminGray"}
                      className="rounded-lg p-2"
                      id="textArea"
                      disabled={!isEditable}
                    />
                    <p>{errors.description?.message}</p>
                  </>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
