import { DialogAdmins } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { columnCCCommentHistory } from "@/components/columns";
import { DataTable } from "@/components/molecules";
import { PaginationInterface, usePagination } from "@/hooks";
import { CCCommentHistory } from "@/interfaces";
import { DownloadCloudIcon } from "lucide-react";
import { useEffect } from "react";

type DialogCommentHistoryCCProps = {
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
  comment_data: {
    id?: string;
    description?: string;
    created_at?: string;
    score?: string;
  }[];
};

export const DialogCommentHistoryCC = ({
  isOpen = false,
  onClose = () => {},
  comment_data,
}: DialogCommentHistoryCCProps) => {
  const {
    currentPage,
    firstPage,
    lastPage,
    nextPage,
    prevPage,
    last_page,
    paginationLabel,
    onChangePageSize,
    setCurrentPage,
    pageSize,
  } = usePagination(100);

  useEffect(() => {
    onChangePageSize(100);
    setCurrentPage(1);
  }, [comment_data]);

  return (
    <DialogAdmins
      title="Detalles"
      subtitle="Historial de comentarios"
      variant={"green"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DataTable
        columns={columnCCCommentHistory()}
        data={comment_data}
        pagination={{
          currentPage,
          firstPage,
          lastPage,
          nextPage,
          prevPage,
          paginationLabel,
          last_page,
          onChangePageSize,
          pageSize,
        }}
      />
      <Button
        variant={"adminDownload"}
        className="w-fit mx-10 my-5 justify-self-start"
        onClick={() => {}}
      >
        <DownloadCloudIcon className="text-admins-text-active mx-2 w-5 h-5" />
        Descargar comentarios
      </Button>
    </DialogAdmins>
  );
};
