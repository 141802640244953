import { Button } from "@/components/atoms/shadcn/ui/button";
import { app_home_url, baseURL } from "@/constants";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faLocationDot,
  faMoneyBill1,
  faPhone,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import Link from "next/link";
import {
  faFacebook,
  faInstagram,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { centroAcopioLogo, googlemapsIcon, wazeIcon } from "@/assets";

import { PropsCenter } from "@/interfaces/collectionCenterInterface";
import { CustomTag } from "@/components/atoms";
import Image from "next/image";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
type DesktopInfoWastesProps = {
  collectionCenter: PropsCenter;
  isBlockOtherLinks?: boolean;
};
export const DesktopInfoWastes = ({
  collectionCenter,
  isBlockOtherLinks,
}: DesktopInfoWastesProps) => {
  return (
    <div className="lg:flex hidden w-full justify-around pt-1 pt-lg-5  items-center text-ecolana-black">
      <div className="flex flex-wrap w-4/12 space-y-2 justify-around h-full">
        <h2 className="w-full justify-center items-center flex min-h-12 text-pretty font-bold text-ecolana-darkGreen lg:text-xl ">
          {collectionCenter?.name}
        </h2>
        <div className="w-3/12">
          <Image className="w-full" src={centroAcopioLogo} alt="" />
        </div>
        <h3 className="text-ecolana-wasteGreen text-lg text-left">
          Dirección y Teléfono
        </h3>
        <div className="w-full flex flex-wrap space-y-2 items-center">
          <div className="w-2/12">
            <FontAwesomeIcon
              className="text-ecolana-darkGreen"
              icon={faLocationDot}
            />
          </div>
          <div className="w-10/12 text-pretty">
            <p>{collectionCenter?.address}</p>
          </div>
          {collectionCenter?.phone !== "" && (
            <>
              <div className="w-2/12">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div className="w-10/12 text-pretty">
                <p>{collectionCenter?.phone}</p>
              </div>
            </>
          )}
        </div>
        <h3 className="text-ecolana-wasteGreen mt-3 mb-1  text-lg">
          Horario de servicio
        </h3>
        <div className="w-full text-center">
          <h4>{collectionCenter?.schedule}</h4>
        </div>
        {!isBlockOtherLinks && (
          <>
            <h3 className="w-full text-start mt-4 text-lg text-ecolana-wasteGreen">
              ¿Cómo llegar?
            </h3>
            <div className="flex flex-wrap w-full pt-4 justify-start">
              <div className="w-2/12 text-end ">
                <Image
                  src={wazeIcon}
                  className="w-fit mt-1"
                  alt=""
                  onClick={() =>
                    window.open(`${collectionCenter?.wazeLink}`, "_blank")
                  }
                />
              </div>
              <div className="w-2/12 text-start">
                <Image
                  src={googlemapsIcon}
                  className="w-fit"
                  alt=""
                  onClick={() =>
                    window.open(`${collectionCenter?.mapsLink}`, "_blank")
                  }
                />
              </div>
            </div>
            <div className="w-full flex flex-wrap pt-4 items-center justify-around space-y-3">
              <h3 className="text-ecolana-wasteGreen w-full text-lg text-center font-bold">
                Pon tu granito de arena, ¡Comparte!
              </h3>
              <div className="w-full flex flex-wrap justify-center space-x-4 items-center">
                <FontAwesomeIcon
                  icon={faShareNodes}
                  className="text-ecolana-darkGreen"
                />
                <p className="text-ecolana-wasteGreen">Compartir</p>
              </div>
              <div className="w-2/12">
                <Link
                  href={`https://www.facebook.com/sharer/sharer.php?u=${baseURL}/mapa/${collectionCenter?.id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="text-[#2cad8f] text-3xl"
                  />
                </Link>
              </div>
              <div className="w-2/12">
                <Link
                  href={`https://twitter.com/intent/tweet?url=${baseURL}/mapa/${collectionCenter?.id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    className="text-ecolana-black text-3xl"
                  />
                </Link>
              </div>
              <div className="w-2/12">
                <Link
                  href={`https://twitter.com/intent/tweet?url=${baseURL}/mapa/${collectionCenter?.id}`}
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="text-ecolana-black text-3xl"
                  />
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
      <Separator
        className="h-[30rem]"
        decorative={true}
        orientation="vertical"
      />
      <div className="flex flex-col items-center justify-center w-7/12 text-lg text-left space-y-2 ">
        {/* <h3 className="text-[#2cad8f]  text-lg text-left w-full">
          ¿Cómo reciclar aquí?
        </h3>
        <p className="text-sm">IMPORTANTE: {collectionCenter.description}</p>
        */}
        <h3 className="text-[#2cad8f] w-full ">Residuos que reciben:</h3>
        <div className=" w-full flex flex-wrap gap-2 justify-start">
          {collectionCenter?.wastes.map((uniWaste, index) => (
            <CustomTag tag2Show={uniWaste} colorIcon={"green"} key={index} />
          ))}
        </div>
        <h3 className="text-[#2cad8f]  text-lg text-left w-full">Beneficios</h3>
        <p className="w-full text-center text-xs">
          {collectionCenter?.benefit == null && "No hay beneficios"}
        </p>
        <div className="flex items-start justify-around">
          {(collectionCenter.benefit == "Ambiental" ||
            collectionCenter.benefit == "Ambos") && (
            <div className="flex flex-wrap  w-5/12">
              <h3 className="  text-ecolana-wasteGreen w-full text-md text-center font-bold">
                <FontAwesomeIcon
                  className="text-ecolana-darkGreen"
                  size="lg"
                  icon={faLeaf}
                />{" "}
                Ambiental
              </h3>
              <p className="w-full text-center text-xs">
                Al traer tus residuos estás siendo parte importante de la
                transformación de residuos en nuevos productos o en su correcta
                disposición. ¡Sigue siendo un héroe para nuestro planeta!
              </p>
            </div>
          )}
          {collectionCenter.benefit == "Ambos" && (
            <Separator
              className="h-[150px] w-[2px] rounded-xl"
              orientation="vertical"
            />
          )}
          {(collectionCenter.benefit == "Efectivo" ||
            collectionCenter.benefit == "Ambos") && (
            <div className="flex flex-wrap  w-5/12">
              <h3 className="  text-ecolana-wasteGreen w-full text-md text-center font-bold">
                <FontAwesomeIcon
                  className="text-ecolana-darkGreen"
                  size="lg"
                  icon={faMoneyBill1}
                />{" "}
                Efectivo
              </h3>
              <p className="w-full text-center text-xs">
                Recibe dinero por los residuos que llevas; ten en cuenta que el
                pago es por kilo y van desde centavos según sea el material
              </p>
            </div>
          )}
        </div>
        {/*<div className="w-full text-center text-sm flex flex-wrap justify-center items-center">
          <div className="w-full flex flex-wrap gap-3 items-baseline">
            <Image src={coinEcolanita} className="w-7 " alt="" />
            <h2 className="w-9/12 text-left text-[#2cad8f] text-xl ">
              Ecolanitas
            </h2>
          </div>
          <p className="w-full">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis
            earum incidunt soluta consequuntur veritatis pariatur nisi suscipit
            mollitia nihil quasi.
          </p>
        </div>
        <h3 className="text-sm font-extrabold text-ecolana-wasteGreen text-center w-full">
          Gana más con nuestras campañas en Ecolana App
        </h3>
        <CarouselAdvertisement />
        */}
        {!isBlockOtherLinks && (
          <Button
            variant={"ecolanaLearnMore"}
            onClick={() => window.open(app_home_url, "_blank")}
            className="text-md w-1/2"
            style={{ marginTop: "2rem" }}
          >
            Descarga y gana
          </Button>
        )}
      </div>
    </div>
  );
};
