import { useState } from "react";
import { CirclePlus } from "lucide-react";
import { ExtFile, FileCard, FileInputButton } from "@files-ui/react";
type InputImagesProps = {
  value?: File;
  onChange: (data?: File) => void;
};
export const InputImages = ({ value, onChange }: InputImagesProps) => {
  const [files, setFiles] = useState([] as ExtFile[]);
  const updateFiles = (files: ExtFile[]) => setFiles(files);

  const removeFile = (fileId: string | number | undefined) =>
    setFiles(files.filter((file) => file.id !== fileId));

  return (
    <div className="flex flex-col justify-center gap-2 w-full">
      <FileInputButton
        onChange={(files) => {
          updateFiles(files);
          onChange(files[0]?.file);
        }}
        value={files}
        maxFiles={1}
        style={{
          backgroundColor: "#2CAD8E",
        }}
      >
        <CirclePlus className="text-white mr-2 w-5 h-5" /> Agregar o cambiar
        imagen
      </FileInputButton>
      {files.map((file: ExtFile) => {
        return (
          <FileCard
            key={file.id}
            {...file}
            onDelete={removeFile}
            info
            preview
            style={{
              width: "100%",
            }}
          />
        );
      })}
    </div>
  );
};
