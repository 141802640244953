import { ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "@/components/atoms/shadcn/ui/button";
import {  MKTValidationInterface, RowMKTValidation } from "@/interfaces";
import Image from "next/image";
import { ValidationsImages } from "../molecules";
import { validation_image } from "@/assets";

export const columnMKTValidation = (
  getRowInformation: (data: MKTValidationInterface) => void
): ColumnDef<MKTValidationInterface>[] => {
  return [
    {
      accessorKey: "evidence",
      header: "Evidencia",
      cell: ({ row }) => {
        return (
          <Image
            src={row.original.evidence ?? validation_image}
            width={100}
            height={100}
            alt={""}
          />
        );
      },
    },
    {
      accessorKey: "id_user",
      header: "Usuario",
      cell: ({ row }) => (
        <div className=" flex flex-col justify-center items-center">
          <p className="font-bold">{`# ${row.original.id_user}`}</p>
          <p>{`${row.original.user_name ? row.original.user_name : ""}`}</p>
        </div>
      ),
    },
    {
      accessorKey: "date_insert",
      header: "Fecha registro",
    },
    {
      accessorKey: "name_badge",
      header: "Insignia",
    }
    ,
    {
      accessorKey: "name_activity",
      header: "Nombre de la Actividad",
    },
    {
      accessorKey: "details",
      header: "Detalles",
      cell: ({ row }) => {
        return (
          <Button
            onClick={() => getRowInformation(row.original)}
            variant={"adminUnderlineGhost"}
          >
            Ver Detalles
          </Button>
        );
      },
    },
  ];
};

export const columnMKTValidationGrid = (
  getIdCell: (row: Row<RowMKTValidation>, idx: number) => void
): ColumnDef<RowMKTValidation>[] => {
  return Array(3)
    .fill(0)
    .map((_, idx) => {
      return {
        header: `scan_rows_${idx}`,
        columns: [
          {
            id: `expand_${idx}_${Math.random() * idx}`,
            cell: ({ row }) =>
              row.original[idx] && (
                <ValidationsImages
                  key={row.original[idx].id_validation + "_card"}
                  variant={"greenGradient"}
                  image={row?.original[idx]?.evidence}
                  left_text={row.original[idx].name_badge}
                  right_text={row.original[idx].user_name}
                  onClick={() => getIdCell(row, idx)}
                />
              ),
          },
        ],
      };
    });
};
