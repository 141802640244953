import {
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  Row,
  ExpandedState,
} from "@tanstack/react-table";
import React, { Dispatch, Fragment, SetStateAction, useEffect } from "react";
import { PaginationFooter } from "@/components/molecules";
import { PaginationInterface } from "@/hooks";

type TableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData>[];
  renderSubComponent: (props: { row: Row<TData> }) => React.ReactElement;
  getRowCanExpand: () => boolean;
  pagination: PaginationInterface;
  expanded: ExpandedState;
  setExpanded: Dispatch<SetStateAction<ExpandedState>>;
};

export function TableRowExpanded<T>({
  data,
  columns,
  renderSubComponent,
  getRowCanExpand,
  pagination,
  expanded,
  setExpanded,
}: TableProps<T>): JSX.Element {
  const table = useReactTable<T>({
    data,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  useEffect(() => {
    table.toggleAllRowsExpanded(false);
  }, [data.length > 0]);

  function setPageSizeTable(pageSize: number) {
    table.setPageSize(pageSize);
  }

  return (
    <div className="grid grid-cols-3 w-full p-10 gap-7 transition-all duration-500 ">
      {table.getRowModel().rows?.length ? (
        table.getRowModel().rows?.map((row, idx) => {
          return (
            <Fragment key={`complete_row_${Math.random() * 1000}}`}>
              {row.getVisibleCells().map((cell) => (
                <Fragment key={`cell_${Math.random() * 100}`}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Fragment>
              ))}
              {row.getIsExpanded() && (
                <div
                  key={`row_expanded_${Math.random() * 100}`}
                  className="col-span-3"
                >
                  {renderSubComponent({ row })}
                </div>
              )}
            </Fragment>
          );
        })
      ) : (
        <div className="col-span-3 w-full p-10 gap-7 transition-all duration-500 h-[500px] text-center text-5xl font-bold text-admins-text-active">
          No se encuentra información
        </div>
      )}
      <PaginationFooter
        setPageSizeTable={setPageSizeTable}
        className="col-span-3"
        pagination={pagination}
      />
    </div>
  );
}
