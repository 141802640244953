import { greenLogoEcolana } from "@/assets";
import Image from "next/image";
import Link from "next/link";
export const NavbarLatitudR = () => {
  return (
    <nav
      className={`bg-white border-transparent z-50 w-full rounded-b-[24px] shadow-xl pb-2 pt-2`}
    >
      <div
        key={"navBar-mainDiv"}
        className="flex flex-wrap items-center justify-between mx-auto py-4 px-12"
      >
        <Link className="w-2/5 sm:w-44" href={"/"}>
          <Image
            src={greenLogoEcolana.src}
            alt="Ecolana"
            className="w-fit"
            sizes="small"
            width={282}
            height={76}
          />
        </Link>
      </div>
    </nav>
  );
};
