import { CardBGGradient } from "@/components/atoms";
import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  WasteValidationsContainer,
  BarChartHistoricValidations,
  BarChartHorizontal,
} from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { fetcherOverviewDataShoppingRegistrations } from "@/pages/admins/compras/overview";
import { generateGraphic } from "@/shared/functions/generateGraphic";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";

type ComprasActivityProps = {
  session: Session | null;
};

export const ComprasActivity = ({ session }: ComprasActivityProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "places",
      "products_per_shopping",
      "enterprise",
      "brands",
      "sub_brands",
      "waste_categories",
      "wastes",
    ],
    keysYear: ["year_history"],
  });
  const { data: stats, refetch } = useQuery({
    queryKey: ["overviewShoppingRegistrations"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDataShoppingRegistrations({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });
  useCheckRefetch(refetch, global, ranges, years);
  return (
    <TabsContent value="Activity">
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController name={"globalRange"} control={control} />
        </div>
      </div>
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        <WasteValidationsContainer
          className="col-span-2"
          title="Historial de transacciones de compras registradas"
          filter={
            <FilterPerYearController
              name={"graphsYear.year_history"}
              control={control}
            />
          }
        >
          <BarChartHistoricValidations
            data={generateGraphic(stats as any, "history", true) as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Lugares de compra (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.places"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "places") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Promedio de productos ingresados por compra"
          filter={
            <FilterPerYearController
              name={"graphsRanges.products_per_shopping"}
              control={control}
            />
          }
        >
          <CardBGGradient
            key={`CardBG_default`}
            variant={"defaultValue"}
            className="w-1/2 aspect-square items-center"
          >
            <p className=" text-admins-text-active text-7xl items-center font-extrabold">
              {stats &&
              stats["products_per_shopping"] &&
              stats["products_per_shopping"][0]?.value
                ? Number(stats["products_per_shopping"][0]?.value).toFixed(0)
                : 0}
            </p>
          </CardBGGradient>
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Empresas más registradas en compras (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.enterprise"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "enterprise") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Marcas más registradas en compras (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.brands"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "brands") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Submarcas más registradas en compras (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.sub_brands"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "sub_brands") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Categorias más registradas en compras (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.waste_categories"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "waste_categories") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Residuos más registrados en compras (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.wastes"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "wastes") as any}
          />
        </WasteValidationsContainer>
      </div>
    </TabsContent>
  );
};
