import { ButtonHTMLAttributes, InputHTMLAttributes } from "react";
import { Input, inputVariants } from "../../atoms/shadcn/ui/input";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { VariantProps } from "class-variance-authority";
import {
  SelectWithDefault,
  selectWithDefaultVariants,
} from "../SelectWithDefault/SelectWithDefault";
import { CampaignsOptions } from "@/constants/campaignsData";
import { cn } from "@/lib/utils";
export type Props<T extends FieldValues> = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  "name"
> &
  VariantProps<typeof selectWithDefaultVariants> & {
    error?: string;
    label?: string;
    control: Control<T, Object>;
    name: Path<T>;
    isEnable?: boolean;
    placeholder: string;
    errors?: string;
    selectedItems: CampaignsOptions[];
  };

export const ControlledSelectWithDefault = <T extends FieldValues>({
  error,
  label,
  name,
  control,
  isEnable = true,
  variant,
  placeholder,
  selectedItems,
  errors,
  className,
  ...props
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, onChange, value } }) => (
        <div className={cn("flex flex-col w-full", className)}>
          <SelectWithDefault
            {...props}
            value={String(value)}
            name={name}
            variant={variant}
            setValue={onChange}
            selectedItems={selectedItems}
            placeHolder={placeholder}
            className={"w-full"}
          />
          <p className="text-ecolana-wasteRed font-semibold">{errors}</p>
        </div>
      )}
    />
  );
};
