import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { colors_admin } from "@/styles/fullConfig";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const optionsChart = {
  plugins: {
    title: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = [
  "Lugar 1",
  "Lugar 2",
  "Lugar 3",
  "Lugar 4",
  "Lugar 5",
  "Lugar 6",
  "Lugar 7",
  "Lugar 8",
  "Lugar 9",
  "Lugar 10",
];

export const dataChart = {
  labels,
  datasets: [
    {
      label: "18 - 24",
      data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
      backgroundColor: colors_admin.graphics.level1,
    },
    {
      label: "25 - 34",
      data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
      backgroundColor: colors_admin.graphics.level2,
    },
    {
      label: "35 - 44",
      data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
      backgroundColor: colors_admin.graphics.level3,
    },

    {
      label: "45 - 55",
      data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
      backgroundColor: colors_admin.graphics.level4,
    },

    {
      label: "56 +",
      data: labels.map(() => faker.number.int({ min: 0, max: 1000 })),
      backgroundColor: colors_admin.graphics.level5,
    },
  ],
};

export const BarChartVerticalStacked = ({
  data = dataChart,
  options = optionsChart,
}: {
  data?: any;
  options?: any;
}) => {
  return <Bar options={options} data={data} />;
};
