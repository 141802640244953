import Image, { StaticImageData } from "next/image";
import markerWasteCenter from "@/public/images/diccionario/replacement.png"
type MarkerCardCarouselProps = {
  nameCollectionCenter: string;
  imgMarker?: string | StaticImageData;
};
export const MarkerCardCarousel = ({
  nameCollectionCenter = "Ejemplo de nombre",
  imgMarker = markerWasteCenter,
}: MarkerCardCarouselProps) => {
  return (
    <div className="flex flex-col w-full min-h-[300px] rounded-3xl border-2 border-[#2CAD8F] bg-white p-2 items-center max-w-48">
      <Image
        className="aspect-square w-2/3"
        src={imgMarker}
        alt=""
      />
      <p className="text-lg font-bold text-center">
        {nameCollectionCenter} Lorem ipsum, dolor sit amet consectetur
        adipisicing elit. Iusto, soluta.{" "}
      </p>
    </div>
  );
};
