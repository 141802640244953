import {
  Checkbox,
  checkBoxVariants,
} from "@/components/atoms/shadcn/ui/checkbox";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { VariantProps } from "class-variance-authority";
import React from "react";
interface CheckBoxWLabelProps extends VariantProps<typeof checkBoxVariants> {
  onChange: (data: string) => void;
  value: boolean;
  text_label: string;
  name: string;
}
export const CheckBoxWLabel = ({
  onChange,
  value,
  text_label = "Test Check box",
  variant,
  name,
}: CheckBoxWLabelProps) => {
  return (
    <div className="flex flex-row w-fit space-x-2">
      <Checkbox
        className=""
        variant={variant}
        id={name}
        checked={value}
        onClick={() => onChange(name)}
      />
      <Label
        htmlFor={name}
        className={`text-lg ${
          value ? "text-[#2CAD8F]" : "text-[#9291A5]"
        } font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70`}
      >
        {text_label}
      </Label>
    </div>
  );
};
