import { SVGProps } from "react";

function ArrowFloatingButton(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21886 2.0625C7.49237 2.0625 7.75467 2.17115 7.94807 2.36454C8.14146 2.55794 8.25011 2.82024 8.25011 3.09375L8.25011 29.9062C8.25011 30.1798 8.14146 30.4421 7.94806 30.6355C7.75467 30.8288 7.49236 30.9375 7.21886 30.9375C6.94536 30.9375 6.68305 30.8288 6.48965 30.6355C6.29626 30.4421 6.18761 30.1798 6.18761 29.9062L6.18761 3.09375C6.18761 2.82024 6.29626 2.55794 6.48966 2.36454C6.68305 2.17115 6.94536 2.0625 7.21886 2.0625ZM12.3751 16.5C12.3751 16.2265 12.4838 15.9642 12.6772 15.7708C12.8706 15.5774 13.1329 15.4687 13.4064 15.4687L25.3544 15.4687L20.9262 11.0426C20.8304 10.9467 20.7543 10.8329 20.7024 10.7076C20.6505 10.5824 20.6238 10.4481 20.6238 10.3125C20.6238 10.1769 20.6505 10.0426 20.7024 9.91736C20.7543 9.79208 20.8304 9.67826 20.9262 9.58237C21.0221 9.48649 21.1359 9.41044 21.2612 9.35854C21.3865 9.30665 21.5208 9.27995 21.6564 9.27995C21.792 9.27995 21.9262 9.30665 22.0515 9.35854C22.1768 9.41044 22.2906 9.48649 22.3865 9.58237L28.574 15.7699C28.67 15.8657 28.7462 15.9795 28.7982 16.1048C28.8502 16.23 28.877 16.3644 28.877 16.5C28.877 16.6356 28.8502 16.77 28.7982 16.8952C28.7462 17.0205 28.67 17.1343 28.574 17.2301L22.3865 23.4176C22.1928 23.6113 21.9302 23.7201 21.6564 23.7201C21.3825 23.7201 21.1199 23.6113 20.9262 23.4176C20.7326 23.224 20.6238 22.9613 20.6238 22.6875C20.6238 22.4136 20.7326 22.151 20.9262 21.9574L25.3544 17.5312L13.4064 17.5312C13.1329 17.5312 12.8706 17.4226 12.6772 17.2292C12.4838 17.0358 12.3751 16.7735 12.3751 16.5Z"
        fill="black"
      />
    </svg>
  );
}
export default ArrowFloatingButton;
