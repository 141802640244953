import { validation_image } from "@/assets";
import { CardBGGradient } from "@/components/atoms";
import Image from "next/image";

export const BadgeCampaignCard = ({
  badgeInfo,
  isSelected,
}: {
  badgeInfo: any;
  isSelected: boolean;
}) => {
  return (
    <CardBGGradient
      variant={"whiteDisabled"}
      className={`flex w-full px-3 gap-2 shadow-lg  ${
        isSelected ? "border-admins-text-active border-2" : "border-none"
      }`}
    >
      {badgeInfo.isBadge && (
        <>
          <Image
            src={validation_image}
            alt="campana_insignia"
            className="w-20 h-20 "
          />
          <div className="flex flex-col justify-around">
            <p className="text-admins-text-active text-md">
              Nombre de la insginia
            </p>
            <p className="text-ecolana-lightGray text-xs">Inicio: dd/mm/aaaa</p>
            <p className="text-ecolana-lightGray text-xs">Final: dd/mm/aaaa</p>
          </div>
        </>
      )}
      <Image
        src={validation_image}
        alt="campana_insignia"
        className="w-20 h-20 "
      />
      <div className="flex flex-col justify-around">
        <p className="text-admins-text-active text-md">Nombre de la Campaña</p>
        <p className="text-ecolana-lightGray text-xs">Inicio: dd/mm/aaaa</p>
        <p className="text-ecolana-lightGray text-xs">Final: dd/mm/aaaa</p>
      </div>
    </CardBGGradient>
  );
};
