import aluminum from "./aluminio.png";
import aluminumSelected from "./aluminios.png";
import capsule from "./capsula.png";
import capsuleSelected from "./capsulas.png";
import cardboard from "./cartonlaminado.png";
import cardboardSelected from "./cartonlaminados.png";
import tin from "./hojalata.png";
import tinSelected from "./hojalatas.png";
import paper from "./papelycarton.png";
import paperSelected from "./papelycartons.png";
import pet from "./pet.png";
import petSelected from "./pets.png";
import hardPlastic from "./plasticoduro.png";
import hardPlasticSelected from "./plasticoduros.png";
import flexPlastic from "./plasticoflexible.png";
import flexPlasticSelected from "./plasticoflexibles.png";
import glass from "./vidrio.png";
import glassSelected from "./vidrios.png";

export {
  aluminum,
  aluminumSelected,
  capsule,
  capsuleSelected,
  cardboard,
  cardboardSelected,
  tin,
  tinSelected,
  paper,
  paperSelected,
  pet,
  petSelected,
  hardPlastic,
  hardPlasticSelected,
  flexPlastic,
  flexPlasticSelected,
  glass,
  glassSelected,
};
