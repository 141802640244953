import React from 'react'

export const GreenWavesFilterSheet = () => {
  return (
    <>
    <svg
            width="123"
            height="207"
            viewBox="0 0 123 207"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: "absolute", bottom: 0, right: 0, zIndex: -1 }}
            pointerEvents="none"
          >
            <path
              d="M123 0V216H0C18.9862 216 59.1535 196.509 67.9338 118.543C76.7142 40.5771 108.303 7.02857 123 0Z"
              fill="#2CAD8F"
              fillOpacity="0.5"
            />
          </svg>
          <svg
            width="193"
            height="132"
            viewBox="0 0 193 132"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ position: "absolute", bottom: 0, right: 0, zIndex: -1 }}
            pointerEvents="none"
          >
            <path
              d="M193 0V141H0C29.7913 141 92.8182 128.276 106.595 77.3821C120.373 26.4879 169.939 4.5881 193 0Z"
              fill="#2CAD8F"
              fillOpacity="0.8"
            />
          </svg>
    </>
  )
}
