import { CardBGGradient } from "@/components/atoms";
import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  WasteValidationsContainer,
  BarChartHistoricValidations,
  BarChartHorizontal,
} from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { fetcherOverviewDataProductRegistrations } from "@/pages/admins/products/overview";
import { generateGraphic } from "@/shared/functions/generateGraphic";
import { useQuery } from "@tanstack/react-query";
import { Session } from "next-auth";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";

type ProductActivityProps = {
  session: Session | null;
};

export const ProductActivity = ({ session }: ProductActivityProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "average_per_user",
      "brands",
      "sub_brands",
      "waste_categories",
      "wastes",
      "users_top",
    ],
    keysYear: ["year_history"],
  });

  const { data: stats, refetch } = useQuery({
    queryKey: ["overviewProductRegistrations"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewDataProductRegistrations({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });
  useCheckRefetch(refetch, global, ranges, years);
  return (
    <TabsContent value="Activity">
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <FilterPerYearController name={"globalRange"} control={control} />
        </div>
      </div>
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        <WasteValidationsContainer
          className="col-span-2"
          title="Historial catálogos SKU's"
          filter={
            <FilterPerYearController
              name={"graphsYear.year_history"}
              control={control}
            />
          }
        >
          <BarChartHistoricValidations
            data={generateGraphic(stats as any, "history", true) as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="SKU's  (productos) registrados por usuarios"
          subtitle="*únicamente se contempla aprobados"
          filter={
            <FilterPerYearController
              name={"graphsRanges.average_per_user"}
              control={control}
            />
          }
        >
          <CardBGGradient
            key={`CardBG_default`}
            variant={"defaultValue"}
            className="w-1/2 aspect-square items-center"
          >
            <p className=" text-admins-text-active text-7xl items-center font-extrabold">
              {stats &&
              stats["average_per_user"] &&
              stats["average_per_user"][0]?.value
                ? Number(stats["average_per_user"][0]?.value).toFixed(0)
                : 0}
            </p>
          </CardBGGradient>
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Marcas registradas (TOP 10)"
          subtitle="*contempla únicamente aprobadas"
          filter={
            <FilterPerYearController
              name={"graphsRanges.brands"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "brands") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Submarcas registradas (TOP 10)"
          subtitle="*contempla únicamente aprobadas"
          filter={
            <FilterPerYearController
              name={"graphsRanges.sub_brands"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "sub_brands") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Categorias registradas (TOP 10)"
          subtitle="*contempla únicamente aprobadas"
          filter={
            <FilterPerYearController
              name={"graphsRanges.waste_categories"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "waste_categories") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Residuos más escaneados (TOP 10)"
          subtitle="*contempla únicamente aprobadas"
          filter={
            <FilterPerYearController
              name={"graphsRanges.wastes"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "wastes") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Usuarios con más registros (TOP 10)"
          subtitle="*contempla únicamente aprobadas"
          filter={
            <FilterPerYearController
              name={"graphsRanges.users_top"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "users_top") as any}
          />
        </WasteValidationsContainer>
      </div>
    </TabsContent>
  );
};
