import { Card, CardContent } from "@/components/atoms/shadcn/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import React, { useRef } from "react";

export const CarouselItemsWithCards = (campaigns: Array<Number>) => {
  let sizeCampaigns: number = campaigns.length;
  let denominator =
    sizeCampaigns % 2 > 0
      ? Math.floor(sizeCampaigns / 2 + 1)
      : sizeCampaigns / 2;
  return Array.from({ length: denominator }).map((_, index) => (
    <CarouselItem className="basis-1/4" key={index}>
      <div className="p-0">
        <Card className="p-0 m-0">
          <CardContent className="flex aspect-square items-center justify-center p-6">
            <span className="text-3xl font-semibold">
              {" "}
              {`${campaigns[index * 2]}`}{" "}
            </span>
          </CardContent>
        </Card>
        <Card
          className={`p-0 m-0 ${
            campaigns[index * 2 + 1] == undefined ? "hidden" : ""
          }`}
        >
          <CardContent className="flex aspect-square items-center justify-center p-6">
            <span className="text-3xl font-semibold">
              {" "}
              {`${campaigns[index * 2 + 1]}`}{" "}
            </span>
          </CardContent>
        </Card>
      </div>
    </CarouselItem>
  ));
};

export const CarouselWithCards = ({
  campaigns = Array.from(Array(11).keys()),
  pluginFlag = false,
}: {
  campaigns?: Array<Number>;
  pluginFlag?: Boolean;
}) => {
  const plugin = useRef(Autoplay({ delay: 2000, stopOnInteraction: true }));
  return (
    <div className="w-full justify-center p-3 items-center">
      <Carousel
        opts={{
          loop: true,
          align: "start",
        }}
        plugins={pluginFlag ? [plugin.current] : []}
        className="w-full"
      >
        <CarouselContent>{CarouselItemsWithCards(campaigns)}</CarouselContent>
        <CarouselPrevious className="bg-white fill-white" />
        <CarouselNext className="bg-white fill-white" />
      </Carousel>
    </div>
  );
};
