import { CardBGGradient } from "@/components/atoms";
import React from "react";
import Image from 'next/image';
import { logoTrashLater } from "@/assets";

export const HorizontalCard = () => {
  return (
    <CardBGGradient variant={"horizontalCard"}>
      <div className="flex flex-wrap items-center justify-around">
        <div className="w-1/6 flex items-center rounded-full bg-white min-h-14">
            <Image src={logoTrashLater} alt="logo_campaña" />
        </div>
        <div className="flex flex-col items-center justify-around w-4/6">
          <p className="text-admins-text-active">Nombre de campaña</p>
          <p className="text-admins-input-placeholder">Fecha de finalización</p>
        </div>
      </div>
    </CardBGGradient>
  );
};
