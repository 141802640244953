import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type ListCheckItemsProps = {
  items: { text: string; checked: boolean }[];
};

export const ListCheckItems = ({ items }: ListCheckItemsProps) => {
  return (
    <div key={`ListItem${items.length}_key_item`} className="w-full flex flex-wrap min-h-14">
      {items.map((uni_item,idx) => (
        <>
          <div
            key={`${uni_item}_key_item`}
            className=" px-2 flex w-full items-center justify-stretch "
          >
            <p className=" w-11/12 text-admins-input-placeholder">
              {uni_item.text}
            </p>
            {uni_item.checked && (
              <FontAwesomeIcon
                className="text-admins-text-active w-5 h-5"
                icon={faSquareCheck}
              />
            )}
          </div>
          <Separator
            key={`${uni_item.text}_key_separator_idx`}
            orientation="horizontal"
            className="bg-[#9291A5]"
          />
        </>
      ))}
    </div>
  );
};
