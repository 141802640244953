import { Button } from "@/components/atoms/shadcn/ui/button";
import { Input } from "@/components/atoms/shadcn/ui/input";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import {
  GridViewProductImages,
  SelectWithDefault,
  ValidationGroupButton,
  ValidationsImages,
} from "@/components/molecules";
import { TypeMeasure, WasteCategoriesId, WasteId } from "@/interfaces";
import { Product } from "@/interfaces/productInterfaces";
import { CirclePlus } from "lucide-react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
} from "react-hook-form";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const FormProductsValidation = ({
  control,
  fields,
  errors,
  data,
  handleRemove,
  handleRejected,
  isEditable,
  wastes,
  waste_categories,
  type_measure,
  handleAddNewProductWaste,
  handleSubmit,
}: {
  data: Product;
  handleRemove: () => void;
  handleRejected: () => void;
  isEditable: boolean;
  wastes: WasteId[];
  waste_categories: WasteCategoriesId[];
  type_measure: TypeMeasure[];
  control: Control<Product, any>;
  errors: FieldErrors<Product>;
  fields: FieldArrayWithId<Product, "product_wastes", "id">[];
  handleAddNewProductWaste: () => void;
  handleSubmit: () => void;
}) => {
  return (
    <div
      key={`scan_${data.sku}_${Math.random()}`}
      className="w-full grid grid-cols-6 p-2 gap-4"
    >
      <div className=" col-span-2 flex flex-wrap justify-center items-center gap-4">
        <Controller
          name="image"
          control={control}
          render={({ field: { name, value } }) => (
            <TransformWrapper>
              <TransformComponent>
                <ValidationsImages
                  image={value !== "" || value !== null ? value : undefined}
                  left_text={data.company_name}
                  right_text={data.sku}
                  variant={"greenGradient"}
                  className="w-full"
                />
              </TransformComponent>
            </TransformWrapper>
          )}
        />
        <div className="grid grid-cols-3 gap-4 justify-center w-full px-4">
          <Label className="text-admins-text-active flex items-center justify-center">
            SKU
          </Label>
          <Controller
            name="sku"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                name={name}
                value={value}
                variant={"adminGray"}
                className="col-span-2"
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.sku?.message}
          </p>
        </div>
        <div className="grid grid-cols-3 gap-4 justify-center w-full px-4">
          <Label className="text-admins-text-active flex items-center justify-center">
            ID Usuario
          </Label>
          <Controller
            name="id_user"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                name={name}
                value={value}
                variant={"adminGray"}
                className="col-span-2"
                disabled
                onChange={onChange}
              />
            )}
          />
        </div>
        <Controller
          name="user_name"
          control={control}
          render={({ field: { name, value, onChange } }) =>
            value ? (
              <div className="grid grid-cols-3 gap-4 justify-center w-full px-4">
                <Label className="text-admins-text-active flex items-center justify-center">
                  Nombre de usuario
                </Label>
                <Input
                  name={name}
                  value={value}
                  variant={"adminGray"}
                  className="col-span-2"
                  disabled
                  onChange={onChange}
                />
                <p className="text-ecolana-wasteRed font-semibold">
                  {errors.user_name?.message}
                </p>
              </div>
            ) : (
              <></>
            )
          }
        />
      </div>
      <div className="col-span-4 grid grid-cols-7 w-full gap-4 text-ecolana-black text-center px-6">
        <div className="col-span-2 col-start-2 flex flex-col items-center justify-around">
          <Label htmlFor="date_register">Fecha de petición de registro</Label>
          <Controller
            name="date_register"
            control={control}
            render={({ field: { name, value } }) => (
              <Input name={name} value={value} variant={"adminGray"} disabled />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.date_register?.message}
          </p>
        </div>
        <div className="col-span-2 col-start-5 flex flex-col items-center justify-around">
          <Label htmlFor="brand">Marca del producto</Label>
          <Controller
            name="brand_name"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.brand_name?.message}
          </p>
        </div>
        <div className="col-span-2 col-start-2 flex flex-col items-center justify-around">
          <Label htmlFor="company">Empresa del producto</Label>
          <Controller
            name="company_name"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.company_name?.message}
          </p>
        </div>
        <div className="col-span-2 col-start-5 flex flex-col items-center justify-around">
          <Label htmlFor="sub_brand">Submarca</Label>
          <Controller
            name="sub_brand_name"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.sub_brand_name?.message}
          </p>
        </div>
        <div className="col-span-2 col-start-2 flex flex-col items-center justify-around">
          <Label htmlFor="type_measure">Tipo de Medida</Label>
          <Controller
            name="type_measure"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <SelectWithDefault
                  value={value}
                  name={name}
                  setValue={onChange}
                  selectedItems={type_measure}
                  placeHolder="Seleccionar"
                  className="w-full"
                  variant={isEditable ? "adminGreen" : "adminDisabled"}
                  disabled={!isEditable}
                />
                <p className="text-ecolana-wasteRed font-semibold">
                  {errors.type_measure?.message}
                </p>
              </>
            )}
          />
        </div>
        <div className="col-span-2 col-start-5 flex flex-col items-center justify-around">
          <Label htmlFor="measure">Medida</Label>
          <Controller
            name="measure"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.measure?.message}
          </p>
        </div>
        <div className="col-span-5 col-start-2 flex flex-col w-full justify-center space-y-3">
          <Label
            className="px-3 text-admins-text-active text-sm"
            htmlFor="description"
          >
            Descripción
          </Label>
          <Controller
            name="description"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Textarea
                  placeholder="Descripción"
                  variant={"adminGray"}
                  className="rounded-lg p-2"
                  name={name}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditable}
                />
                <p>{errors.description?.message}</p>
              </>
            )}
          />
        </div>
      </div>
      <div className="col-span-6 flex flex-wrap items-center justify-center">
        {isEditable && (
          <Button
            className="mx-10 my-7 bg-admins-button-green rounded-lg text-lg w-fit"
            onClick={handleAddNewProductWaste}
          >
            <CirclePlus className="text-white mx-4 w-5 h-5" />
            Agregar Categoría/Residuo
          </Button>
        )}
        <GridViewProductImages
          wastes={wastes}
          waste_categories={waste_categories}
          control={control}
          fields={fields}
          errors={errors}
          isEditable={isEditable}
        />
      </div>
      <div className="col-span-6 w-full flex flex-wrap justify-center items-center">
        {isEditable && (
          <ValidationGroupButton
            handleRejected={handleRejected}
            handleRemove={handleRemove}
            handleSubmit={handleSubmit}
            className="w-1/2"
          />
        )}
      </div>
    </div>
  );
};
