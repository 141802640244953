import nutrioliStepsPaso01 from "@/public/images/campana/nutrioli/pasos/paso01.png";
import nutrioliStepsPaso02 from "@/public/images/campana/nutrioli/pasos/paso02.png";
import nutrioliStepsPaso03 from "@/public/images/campana/nutrioli/pasos/paso03.png";
import nutrioliStepsPaso04 from "@/public/images/campana/nutrioli/pasos/paso04.png";
import nutrioliCarouselProduct01 from "@/public/images/campana/nutrioli/products/product01.png";
import nutrioliCarouselProduct02 from "@/public/images/campana/nutrioli/products/product02.png";
import nutrioliCarouselBannersBanner01 from "@/public/images/campana/nutrioli/banners/banner01.jpg";
import nutrioliCarouselBannersBanner02 from "@/public/images/campana/nutrioli/banners/banner02.jpg";
import grupoModeloStepsPaso01 from "@/public/images/campana/modelo/pasos/paso01.png";
import grupoModeloStepsPaso02 from "@/public/images/campana/modelo/pasos/paso02.png";
import grupoModeloStepsPaso03 from "@/public/images/campana/modelo/pasos/paso03.png";
import grupoModeloStepsPaso04 from "@/public/images/campana/modelo/pasos/paso04.png";
import grupoModeloCarouselProduct01 from "@/public/images/campana/modelo/products/product01.png";
import grupoModeloCarouselProduct02 from "@/public/images/campana/modelo/products/product02.png";
import grupoModeloCarouselProduct03 from "@/public/images/campana/modelo/products/product03.png";
import grupoModeloCarouselProduct04 from "@/public/images/campana/modelo/products/product04.png";
import grupoModeloCarouselProduct05 from "@/public/images/campana/modelo/products/product05.png";
import grupoModeloCarouselProduct06 from "@/public/images/campana/modelo/products/product06.png";
import grupoModeloCarouselBannersBanner01 from "@/public/images/campana/modelo/banners/banner01.png";
import maquinasDeAlenStepsPaso01 from "@/public/images/campana/alen/pasos/paso01.png";
import maquinasDeAlenStepsPaso02 from "@/public/images/campana/alen/pasos/paso02.png";
import maquinasDeAlenStepsPaso03 from "@/public/images/campana/alen/pasos/paso03.png";
import maquinasDeAlenStepsPaso04 from "@/public/images/campana/alen/pasos/paso04.png";
import maquinasDeAlenCarouselProduct01 from "@/public/images/campana/alen/products/product01.png";
import maquinasDeAlenCarouselProduct02 from "@/public/images/campana/alen/products/product02.png";
import maquinasDeAlenCarouselProduct03 from "@/public/images/campana/alen/products/product03.png";
import maquinasDeAlenCarouselBannersBanner01 from "@/public/images/campana/alen/banners/banner01.png";
import maquinasDeAlenCarouselBannersBanner02 from "@/public/images/campana/alen/banners/banner02.png";
import nescafeDolceGustoStepsPaso1 from "@/public/images/campana/dolce/pasos/paso1.png";

import nescafeDolceGustoStepsPaso2 from "@/public/images/campana/dolce/pasos/paso2.png";
import nescafeDolceGustoStepsPaso3 from "@/public/images/campana/dolce/pasos/paso3.png";
import nescafeDolceGustoStepsPaso4 from "@/public/images/campana/dolce/pasos/paso4.png";
import nescafeDolceGustoCarouselProduct1 from "@/public/images/campana/dolce/products/product1.png";
import nescafeDolceGustoCarouselProduct2 from "@/public/images/campana/dolce/products/product2.png";
import nescafeDolceGustoCarouselProduct3 from "@/public/images/campana/dolce/products/product3.png";
import nescafeDolceGustoCarouselBannersBanner1 from "@/public/images/campana/dolce/banners/banner1.png";
import nescafeDolceGustoCarouselBannersBanner2 from "@/public/images/campana/dolce/banners/banner2.png";
import nescafeDolceGustoCarouselBannersBanner3 from "@/public/images/campana/dolce/banners/banner3.png";
import nescafeDolceGustoCarouselBannersBanner4 from "@/public/images/campana/dolce/banners/banner4.png";
import nescafeDolceGustoCarouselBannersBanner5 from "@/public/images/campana/dolce/banners/banner5.png";
import nescafeDolceGustoCarouselBannersBanner6 from "@/public/images/campana/dolce/banners/banner6.png";
import airesDeCampoStepsPaso01 from "@/public/images/campana/aires/pasos/paso01.png";
import airesDeCampoStepsPaso02 from "@/public/images/campana/aires/pasos/paso02.png";
import airesDeCampoStepsPaso03 from "@/public/images/campana/aires/pasos/paso03.png";
import airesDeCampoStepsPaso04 from "@/public/images/campana/aires/pasos/paso04.png";
import airesDeCampoCarouselProduct01 from "@/public/images/campana/aires/products/product01.png";
import airesDeCampoCarouselBannersBanner01 from "@/public/images/campana/aires/banners/banner01.png";
import airesDeCampoCarouselBannersBanner02 from "@/public/images/campana/aires/banners/banner02.png";
import empaquesAlimentosDeMascotasPurinaStepsPaso02 from "@/public/images/campana/purina/pasos/paso02.png";
import empaquesAlimentosDeMascotasPurinaStepsPaso03 from "@/public/images/campana/purina/pasos/paso03.png";
import empaquesAlimentosDeMascotasPurinaStepsPaso05 from "@/public/images/campana/purina/pasos/paso05.png";
import empaquesAlimentosDeMascotasPurinaStepsPaso01 from "@/public/images/campana/purina/pasos/paso01.png";
import empaquesAlimentosDeMascotasPurinaCarouselProduct01 from "@/public/images/campana/purina/products/product01.png";
import empaquesAlimentosDeMascotasPurinaCarouselBannersBanner01 from "@/public/images/campana/purina/banners/banner01.png";
import empaquesAlimentosDeMascotasPurinaCarouselBannersBanner02 from "@/public/images/campana/purina/banners/banner02.png";
import empaquesAlimentosDeMascotasPurinaCarouselBannersBanner03 from "@/public/images/campana/purina/banners/banner03.png";
import tetraPakStepsPaso01 from "@/public/images/campana/tetra/pasos/paso01.png";
import tetraPakStepsPaso02 from "@/public/images/campana/tetra/pasos/paso02.png";
import tetraPakStepsPaso03 from "@/public/images/campana/tetra/pasos/paso03.png";
import tetraPakStepsPaso04 from "@/public/images/campana/tetra/pasos/paso04.png";
import tetraPakCarouselProduct01 from "@/public/images/campana/tetra/products/product01.png";
import tetraPakCarouselProduct02 from "@/public/images/campana/tetra/products/product02.png";
import tetraPakCarouselBannersBanner01 from "@/public/images/campana/tetra/banners/banner01.png";
import tetraPakCarouselBannersBanner02 from "@/public/images/campana/tetra/banners/banner02.png";
import bimboLogo from "@/public/images/campana/bimbo/logo.webp";
import bimboStepsPaso01 from "@/public/images/campana/bimbo/pasos/paso01.png";
import bimboStepsPaso02 from "@/public/images/campana/bimbo/pasos/paso02.png";
import bimboStepsPaso03 from "@/public/images/campana/bimbo/pasos/paso03.png";
import bimboStepsPaso04 from "@/public/images/campana/bimbo/pasos/paso04.png";
import bimboCarouselProduct01 from "@/public/images/campana/bimbo/products/product01.png";
import bimboCarouselProduct02 from "@/public/images/campana/bimbo/products/product02.png";
import bimboCarouselProduct03 from "@/public/images/campana/bimbo/products/product03.png";
import bimboCarouselProduct04 from "@/public/images/campana/bimbo/products/product04.png";
import bimboCarouselProduct05 from "@/public/images/campana/bimbo/products/product05.png";
import bimboCarouselProduct06 from "@/public/images/campana/bimbo/products/product06.png";
import bimboCarouselProduct07 from "@/public/images/campana/bimbo/products/product07.png";
import bimboCarouselBannersBanner01 from "@/public/images/campana/bimbo/banners/banner01.png";
import silkLogo from "@/public/images/campana/silk/logo.png";
import silkStepsPaso01 from "@/public/images/campana/silk/pasos/paso01.png";
import silkStepsPaso02 from "@/public/images/campana/silk/pasos/paso02.png";
import silkStepsPaso03 from "@/public/images/campana/silk/pasos/paso03.png";
import silkStepsPaso04 from "@/public/images/campana/silk/pasos/paso04.png";
import silkCarouselProduct01 from "@/public/images/campana/silk/products/product01.png";
import silkCarouselBannersBanner01 from "@/public/images/campana/silk/banners/banner01.png";
import inixLogo from "@/public/images/campana/inix/logo.png";
import inixStepsPaso01 from "@/public/images/campana/inix/pasos/paso01.png";
import inixStepsPaso02 from "@/public/images/campana/inix/pasos/paso02.png";
import inixStepsPaso03 from "@/public/images/campana/inix/pasos/paso03.png";
import inixCarouselProduct01 from "@/public/images/campana/inix/products/product01.jpg";
import inixCarouselProduct02 from "@/public/images/campana/inix/products/product02.jpg";
import inixCarouselProduct03 from "@/public/images/campana/inix/products/product03.jpg";
import inixCarouselProduct04 from "@/public/images/campana/inix/products/product04.jpg";
import inixCarouselProduct05 from "@/public/images/campana/inix/products/product05.jpg";
import inixCarouselBannersBanner01 from "@/public/images/campana/inix/banners/banner01.png";
import jpmmLogo from "@/public/images/campana/jpmm/logo.png";
import jpmmStepsPaso01 from "@/public/images/campana/jpmm/pasos/paso01.png";
import jpmmStepsPaso02 from "@/public/images/campana/jpmm/pasos/paso02.png";
import jpmmStepsPaso03 from "@/public/images/campana/jpmm/pasos/paso03.png";
import jpmmStepsPaso04 from "@/public/images/campana/jpmm/pasos/paso04.png";
import jpmmCarouselProduct01 from "@/public/images/campana/jpmm/products/product01.png";
import jpmmCarouselProduct02 from "@/public/images/campana/jpmm/products/product02.png";
import jpmmCarouselBannersBanner01 from "@/public/images/campana/jpmm/banners/banner01.png";
import jpmmCarouselBannersBanner02 from "@/public/images/campana/jpmm/banners/banner02.png";

import amazonLogo from "@/public/images/campana/amazon/logo.webp";
import amazonStepsPaso01 from "@/public/images/campana/amazon/pasos/paso01.png";
import amazonStepsPaso02 from "@/public/images/campana/amazon/pasos/paso02.png";
import amazonStepsPaso03 from "@/public/images/campana/amazon/pasos/paso03.png";
import amazonStepsPaso04 from "@/public/images/campana/amazon/pasos/paso04.png";
import amazonCarouselProductsProduct01 from "@/public/images/campana/amazon/products/product01.png";
import amazonCarouselProductsProduct02 from "@/public/images/campana/amazon/products/product02.png";
import amazonCarouselBannersBanner02 from "@/public/images/campana/amazon/banners/banner02.png";
import latitudRLogo from "@/public/images/campana/latitud_r/logo.webp";
import latitudRStepsPaso01 from "@/public/images/campana/latitud_r/pasos/paso01.png";
import latitudRStepsPaso02 from "@/public/images/campana/latitud_r/pasos/paso02.png";
import latitudRStepsPaso03 from "@/public/images/campana/latitud_r/pasos/paso03.png";
import latitudRStepsPaso04 from "@/public/images/campana/latitud_r/pasos/paso04.png";
import latitudRCarouselBannersBanner01 from "@/public/images/campana/latitud_r/banners/banner01.png";
import latitudRCarouselBannersBanner02 from "@/public/images/campana/latitud_r/banners/banner02.png";
import dolceLogo from "@/public/images/campana/dolce/logo.webp";
import nutrioliLogo from "@/public/images/campana/nutrioli/logo.webp";
import alenLogo from "@/public/images/campana/alen/logo.png";
import airesDeCampoLogo from "@/public/images/campana/aires/logo.webp";
import purinaLogo from "@/public/images/campana/purina/logo.webp";
import tetrapackLogo from "@/public/images/campana/tetra/logo.webp";
import modeloLogo from "@/public/images/campana/modelo/logo.webp";
import novamilLogo from "@/public/images/campana/reciclando-con-novamil/logo.webp";
import novamilSteps01 from "@/public/images/campana/reciclando-con-novamil/pasos/paso01.webp";
import novamilSteps02 from "@/public/images/campana/reciclando-con-novamil/pasos/paso02.webp";
import novamilSteps03 from "@/public/images/campana/reciclando-con-novamil/pasos/paso03.webp";
import novamilSteps04 from "@/public/images/campana/reciclando-con-novamil/pasos/paso04.webp";
import novamilCarouselProduct01 from "@/public/images/campana/reciclando-con-novamil/products/product01.webp";
import novamilCarouselProduct02 from "@/public/images/campana/reciclando-con-novamil/products/product02.webp";
import novamilCarouselBanner01 from "@/public/images/campana/reciclando-con-novamil/banners/banner01.webp";
import novamilCarouselBanner02 from "@/public/images/campana/reciclando-con-novamil/banners/banner02.webp";
import inspiraLogo from "@/public/images/campana/inspira-el-cambio/logo.webp";
import inspiraSteps01 from "@/public/images/campana/inspira-el-cambio/pasos/paso01.webp";
import inspiraSteps02 from "@/public/images/campana/inspira-el-cambio/pasos/paso02.webp";
import inspiraSteps03 from "@/public/images/campana/inspira-el-cambio/pasos/paso03.webp";
import inspiraSteps04 from "@/public/images/campana/inspira-el-cambio/pasos/paso04.webp";
import inspiraCarouselProduct01 from "@/public/images/campana/inspira-el-cambio/products/product01.webp";
import inspiraCarouselProduct02 from "@/public/images/campana/inspira-el-cambio/products/product02.webp";
import inspiraCarouselBanner01 from "@/public/images/campana/inspira-el-cambio/banners/banner01.webp";
import inspiraCarouselBanner02 from "@/public/images/campana/inspira-el-cambio/banners/banner02.webp";
import { StaticImageData } from "next/image";
import { IMUReciclaLogo } from "@/assets";
export interface CampaignProps {
  id: string;
  name: string;
  slug: string;
  logo: StaticImageData;
  width: string;
  recycleText: string;
  steps: {
    text: string;
    img: StaticImageData;
  }[];
  carousel: {
    img: StaticImageData;
  }[];
  description: string;
  static_image?: StaticImageData;
  staticRedirectLink?: string;
  carouselBanners: {
    img: StaticImageData;
  }[];
  active: boolean;
  hide_description?: boolean;
  isBanner: boolean;
}
export interface CampaignsOptions {
  id: string;
  name: string;
  active?: boolean;
}

export const Campaigns: CampaignProps[] = [
  {
    id: "3",
    name: "NESCAFÉ® Dolce Gusto®",
    width: "w-100",
    slug: "nescafe-dolce-gusto",
    logo: dolceLogo,
    isBanner: true,
    recycleText: "Cápsulas NESCAFÉ® Dolce Gusto® y Starbucks® de Dolce Gusto",
    steps: [
      {
        text: "Corta la membrana superior y deja escurrir. Puedes dejar el café",
        img: nescafeDolceGustoStepsPaso1,
      },
      {
        text: "Separa en cualquier tipo de bolsa",
        img: nescafeDolceGustoStepsPaso2,
      },
      {
        text: "Localiza tu punto de recolección y entrega",
        img: nescafeDolceGustoStepsPaso3,
      },
      {
        text: "No olvides: Puedes solicitar la guía de recolección sin costo",
        img: nescafeDolceGustoStepsPaso4,
      },
    ],
    carousel: [
      { img: nescafeDolceGustoCarouselProduct1 },
      { img: nescafeDolceGustoCarouselProduct2 },
      { img: nescafeDolceGustoCarouselProduct3 },
    ],
    description:
      "¡Únete a la campaña de reciclaje y desecha de manera correcta tus Cápsulas NESCAFÉ® Dolce Gusto® y Starbucks®! Conoce el punto de acopio más cercano a ti y registra tu visita en ecolana app. #ConGustoReciclamos",
    carouselBanners: [
      { img: nescafeDolceGustoCarouselBannersBanner1 },
      { img: nescafeDolceGustoCarouselBannersBanner2 },
      { img: nescafeDolceGustoCarouselBannersBanner3 },
      { img: nescafeDolceGustoCarouselBannersBanner4 },
      { img: nescafeDolceGustoCarouselBannersBanner5 },
      { img: nescafeDolceGustoCarouselBannersBanner6 },
    ],
    active: true,
  },
  {
    id: "16",
    name: "NUTRIOLI",
    width: "w-75",
    slug: "nutrioli",
    logo: nutrioliLogo,
    isBanner: true,
    recycleText: "Todos los envases de Nutrioli y Aceite Vegetal Usado",
    steps: [
      {
        text: "Escurre al máximo el envase Nutrioli",
        img: nutrioliStepsPaso01,
      },
      {
        text: "Junta la mayor cantidad de envases que puedas",
        img: nutrioliStepsPaso04,
      },
      {
        text: "Colecta el aceite usado en un envase de plástico",
        img: nutrioliStepsPaso02,
      },
      {
        text: "Entrega los residuos separados en el acopio",
        img: nutrioliStepsPaso03,
      },
    ],
    carousel: [
      { img: nutrioliCarouselProduct01 },
      { img: nutrioliCarouselProduct02 },
    ],
    description:
      "Nutrioli te invita a reciclar tu aceite vegetal usado, así como todos sus envases de PET, aluminio y vidrio a través de los puntos de acopio del mapa de ecolana.",
    carouselBanners: [
      { img: nutrioliCarouselBannersBanner01 },
      { img: nutrioliCarouselBannersBanner02 },
    ],
    active: true,
  },
  {
    id: "5",
    name: "GRUPO ALEN",
    width: "w-75",
    slug: "maquinas-de-alen",
    logo: alenLogo,
    isBanner: true,
    recycleText: "PET y PEAD #noimportalamarca",
    steps: [
      {
        text: "Quitar todo el residuo restante",
        img: maquinasDeAlenStepsPaso01,
      },
      {
        text: "Retirar la tapa y guardar por separado",
        img: maquinasDeAlenStepsPaso02,
      },
      {
        text: "De preferencia, no aplastar las botellas.",
        img: maquinasDeAlenStepsPaso03,
      },
      {
        text: "Depositarlas en la máquina",
        img: maquinasDeAlenStepsPaso04,
      },
    ],
    carousel: [
      { img: maquinasDeAlenCarouselProduct01 },
      { img: maquinasDeAlenCarouselProduct02 },
      { img: maquinasDeAlenCarouselProduct03 },
    ],
    description:
      "Las máquinas de reciclaje de AlEn son automáticas, introduces tus residuos y en intercambio ganas dinero en un monedero electrónico. O si lo prefieres, puedes donar ese dinero a causas sociales de manera automática.",
    carouselBanners: [
      { img: maquinasDeAlenCarouselBannersBanner01 },
      { img: maquinasDeAlenCarouselBannersBanner02 },
    ],
    active: false,
  },
  {
    id: "8",
    name: "AIRES DE CAMPO",
    width: "w-75",
    slug: "aires-de-campo",
    logo: airesDeCampoLogo,
    isBanner: true,
    recycleText: "Vidrio de grado alimenticio #noimportalamarca",
    steps: [
      {
        text: "Consumir todo el producto",
        img: airesDeCampoStepsPaso01,
      },
      {
        text: "Escurre todo el producto restante",
        img: airesDeCampoStepsPaso02,
      },
      {
        text: "Junta y busca el punto de acopio más cercano",
        img: airesDeCampoStepsPaso03,
      },
      {
        text: "Entrega limpio y seco",
        img: airesDeCampoStepsPaso04,
      },
    ],
    carousel: [{ img: airesDeCampoCarouselProduct01 }],
    description:
      "Con esta campaña se busca ofrecer una alternativa para la disposición correcta de los envases de Aires de Campo, así como una nueva vida a los envases que serán suprarreciclados por Cerrando el Ciclo.",
    carouselBanners: [
      { img: airesDeCampoCarouselBannersBanner01 },
      { img: airesDeCampoCarouselBannersBanner02 },
    ],
    active: false,
  },
  {
    id: "6",
    name: "RECICLAJE PERRÓN BY RECICLANDO CON PURINA®",
    width: "w-75",
    slug: "empaques-alimentos-de-mascotas-purina",
    logo: purinaLogo,
    isBanner: true,
    recycleText: "Bolsas, sobres y latas de alimento para mascotas Purina®.",
    steps: [
      {
        text: "Vacía todo el producto",
        img: empaquesAlimentosDeMascotasPurinaStepsPaso02,
      },
      {
        text: "Limpia los restos con un trapo",
        img: empaquesAlimentosDeMascotasPurinaStepsPaso03,
      },
      {
        text: "Dobla las bolsas y sobres",
        img: empaquesAlimentosDeMascotasPurinaStepsPaso05,
      },
      {
        text: "Junta y lleva a los contenedores o Centros de Acopio disponibles al interior de la República",
        img: empaquesAlimentosDeMascotasPurinaStepsPaso01,
      },
    ],
    carousel: [],
    description:
      "Con esta campaña se busca ofrecer una alternativa para la disposición correcta de los empaques, sobres y latas de Purina®. Así como alimentas de manera correcta a tus mascotas, también pensemos en sus residuos. #ReciclandoConPurina",
    hide_description: true,
    static_image: empaquesAlimentosDeMascotasPurinaCarouselProduct01,
    staticRedirectLink:
      "https://purina.com.mx/purina/purina-por-el-planeta/reciclando-con-purina",
    carouselBanners: [
      { img: empaquesAlimentosDeMascotasPurinaCarouselBannersBanner01 },
      { img: empaquesAlimentosDeMascotasPurinaCarouselBannersBanner03 },
    ],
    active: true,
  },
  {
    id: "4",
    name: "TETRA PAK®",
    width: "w-75",
    slug: "tetra-pak",
    logo: tetrapackLogo,
    isBanner: true,
    recycleText: "Todos tus envases de Tetra Pak®#noimportalamarca",
    steps: [
      {
        text: "Desarma y escurre",
        img: tetraPakStepsPaso01,
      },
      {
        text: "Junta y compacta",
        img: tetraPakStepsPaso02,
      },
      {
        text: "Deposita limpios y secos",
        img: tetraPakStepsPaso03,
      },
      {
        text: "No olvides: Conservar la tapa y/o el popote del envase",
        img: tetraPakStepsPaso04,
      },
    ],
    carousel: [
      { img: tetraPakCarouselProduct01 },
      { img: tetraPakCarouselProduct02 },
    ],
    description:
      "Tetra Pak® tiene contenedores hechos de material reciclado por todo el país, algunos de ellos están ubicados en ciudades como Campeche, CDMX, Ensenada, Hermosillo, Guadalajara, León, Mazatlán, Mérida, Morelia, Texcoco, Toluca, Tulum, Progreso, Puebla, Veracruz Centro, Zapopan. Sigue reciclando y ayudando para incrementar la tasa de reciclaje.",
    carouselBanners: [
      { img: tetraPakCarouselBannersBanner01 },
      { img: tetraPakCarouselBannersBanner02 },
    ],
    active: true,
  },
  {
    id: "23",
    name: "Grupo Modelo",
    width: "w-75",
    slug: "grupo-modelo",
    logo: modeloLogo,
    isBanner: true,
    recycleText: "Botellas de vidrio de Grupo Modelo",
    steps: [
      {
        text: "Escurre todo el contenido",
        img: grupoModeloStepsPaso01,
      },
      {
        text: "Evita que se dañe la botella",
        img: grupoModeloStepsPaso02,
      },
      {
        text: "Junta la mayor cantidad de botellas de vidrio",
        img: grupoModeloStepsPaso03,
      },
      {
        text: "Entrega limpias y secas",
        img: grupoModeloStepsPaso04,
      },
    ],
    carousel: [
      { img: grupoModeloCarouselProduct01 },
      { img: grupoModeloCarouselProduct02 },
      { img: grupoModeloCarouselProduct03 },
      { img: grupoModeloCarouselProduct04 },
      { img: grupoModeloCarouselProduct05 },
      { img: grupoModeloCarouselProduct06 },
    ],
    description:
      "Grupo Modelo te invita a retornar todas tus botellas de vidrio, recuerda entregarlas en las mejores condiciones para los acopiadores. Juntos le damos más de una vida a nuestros residuos y apoyamos la cadena de reciclaje.",
    carouselBanners: [{ img: grupoModeloCarouselBannersBanner01 }],
    active: false,
  },
  {
    id: "34",
    name: "Reciclando con Bimbo y Barcel",
    width: "w-75",
    slug: "bimboybarcel",
    logo: bimboLogo,
    isBanner: true,
    recycleText: "Bolsas de BOPP y LDPE",
    steps: [
      {
        text: "Vacía todo el producto",
        img: bimboStepsPaso01,
      },
      {
        text: "Retira todo el residuo restante (salsa, aderezo)",
        img: bimboStepsPaso02,
      },
      {
        text: "Junta el mayor número de bolsas de BOPP y LDPE",
        img: bimboStepsPaso03,
      },
      {
        text: "Entrega las bolsas limpias y secas",
        img: bimboStepsPaso04,
      },
    ],
    carousel: [
      { img: bimboCarouselProduct01 },
      { img: bimboCarouselProduct02 },
      { img: bimboCarouselProduct03 },
      { img: bimboCarouselProduct04 },
      { img: bimboCarouselProduct05 },
      { img: bimboCarouselProduct06 },
      { img: bimboCarouselProduct07 },
    ],
    description:
      "Barcel y Bimbo crean una nueva campaña para darle una segunda vida a tus residuos. Participa en la insignia “Reciclando con Bimbo y Barcel” en ecolana app y llévate una recompensa por reciclar tus empaques de BOPP y LDPE.",
    carouselBanners: [{ img: bimboCarouselBannersBanner01 }],
    active: true,
  },
  {
    id: "20",
    name: "SILK",
    width: "w-75",
    slug: "silk",
    logo: silkLogo,
    isBanner: true,
    recycleText: "Todos los envases de Tetra Pak®",
    steps: [
      {
        text: "Desarma y escurre",
        img: silkStepsPaso01,
      },
      {
        text: "Junta y compacta",
        img: silkStepsPaso02,
      },
      {
        text: "Deposita limpios y secos",
        img: silkStepsPaso03,
      },
      {
        text: "No olvides: Conservar la tapa y/o el popote del envase",
        img: silkStepsPaso04,
      },
    ],
    carousel: [{ img: silkCarouselProduct01 }],
    description:
      "SILK te invita a que siembres mejores hábitos para ti y para el planeta. Recicla todos tus envases de Tetra Pak® a través de los puntos especiales de SILK y centros de acopio.",
    carouselBanners: [{ img: silkCarouselBannersBanner01 }],
    active: false,
  },
  {
    id: "21",
    name: "INIX",
    width: "w-75",
    slug: "inix",
    logo: inixLogo,
    isBanner: true,
    recycleText:
      "Charolas, vasos, domos, bisagras de plástico (PET, PP, PE y PS)",
    steps: [
      {
        text: "Quita todo el residuo restante",
        img: inixStepsPaso01,
      },
      {
        text: "Retira lo más que se pueda de etiquetas (opcional)",
        img: inixStepsPaso02,
      },
      {
        text: "Junta el mayor número de charolas, vasos, domos, bisagras de plástico (PET, PP, PE y PS).",
        img: inixStepsPaso03,
      },
      {
        text: "Entrega limpios y secos",
        img: inixStepsPaso03,
      },
    ],
    carousel: [
      { img: inixCarouselProduct01 },
      { img: inixCarouselProduct02 },
      { img: inixCarouselProduct03 },
      { img: inixCarouselProduct04 },
      { img: inixCarouselProduct05 },
    ],
    description:
      "Con esta campaña de INIX, se busca dar a conocer que las charolas, los domos, las bisagras y los vasos de plástico (PET, PP, PE y PS) son reciclables. Lleva al centro de acopio tus residuos y sigamos promoviendo la cultura del reciclaje. #INIXRecicla",
    carouselBanners: [{ img: inixCarouselBannersBanner01 }],
    active: false,
  },
  {
    id: "13",
    name: "Juntos por un mejor mañana",
    width: "w-75",
    slug: "jpmm",
    logo: jpmmLogo,
    isBanner: true,
    recycleText: "Todos los residuos participantes #noimportalamarca",
    steps: [
      {
        text: "Consume todo el producto. ¡No dejes ni una gota!",
        img: jpmmStepsPaso01,
      },
      {
        text: "Identifica qué residuos puedes separar",
        img: jpmmStepsPaso02,
      },
      {
        text: "Encuentra en el mapa el centro de acopio más cercano",
        img: jpmmStepsPaso03,
      },
      {
        text: "Valida tus residuos entregando limpios y secos",
        img: jpmmStepsPaso04,
      },
    ],
    carousel: [{ img: jpmmCarouselProduct01 }, { img: jpmmCarouselProduct02 }],
    description:
      "¡Vuelve Juntos por un Mejor Mañana a ecolana app®! P&G®, Nestle®, Purina® y Walmart de México y Centroamérica® quieren seguir premiando a los usuarios. Conoce y participa para ganar tu lanita.",
    carouselBanners: [
      { img: jpmmCarouselBannersBanner01 },
      { img: jpmmCarouselBannersBanner02 },
    ],
    active: false,
  },
  {
    id: "22",
    name: "Amazon",
    width: "w-75",
    slug: "amazon",
    logo: amazonLogo,
    isBanner: true,
    recycleText: "Cajas de cartón",
    steps: [
      {
        text: "Saca todo el contenido",
        img: amazonStepsPaso01,
      },
      {
        text: "Deja la cinta, se reciclan juntos",
        img: amazonStepsPaso02,
      },
      {
        text: "Desarma y compacta",
        img: amazonStepsPaso03,
      },
      {
        text: "Encuentra el centro de acopio más cercano",
        img: amazonStepsPaso04,
      },
    ],
    carousel: [
      {
        img: amazonCarouselProductsProduct01,
      },
      {
        img: amazonCarouselProductsProduct02,
      },
    ],
    description: `Amazon te invita a que recicles todas las cajas de cartón y obtén un beneficio en la tienda de ecolana app. ¡Encuentra tu centro de acopio más cercano, lleva tus residuos y gana! Para obtener tu beneficio descarga ecolana app
        <a href="https://bit.ly/EcolanaApp" target="_blank">
          https://bit.ly/EcolanaApp
        </a>
      `,
    carouselBanners: [
      //{ img: require("@/public/images/campana/amazon/banners/banner01.png") },
      { img: amazonCarouselBannersBanner02 },
    ],
    active: true,
  },
  {
    id: "28",
    name: "Latitud R",
    width: "w-75",
    slug: "latitud-r",
    logo: latitudRLogo,
    isBanner: true,
    recycleText:
      "Reciclaje inclusivo y participación justa para los recolectores de base en Puebla",
    steps: [
      {
        text: "Identifica al recolector de base con chaleco azul y escanea su código QR",
        img: latitudRStepsPaso01,
      },
      {
        text: "Entrega al recolector tus residuos separados e indica lo que estás entregando",
        img: latitudRStepsPaso02,
      },
      {
        text: "Ingresa el pesaje que el recolector te indique en ecolana app",
        img: latitudRStepsPaso03,
      },
      {
        text: "¡Recibe ecolanitas!",
        img: latitudRStepsPaso04,
      },
    ],
    carousel: [],
    description:
      "Fomentemos el reciclaje inclusivo en México. Valida tus residuos con tu recolector empadronado (chaleco azul) y ecolana app <a href='https://bit.ly/EcolanaApp' target='_blank'>https://bit.ly/EcolanaApp</a>",
    carouselBanners: [
      {
        img: latitudRCarouselBannersBanner01,
      },
      {
        img: latitudRCarouselBannersBanner02,
      },
    ],
    active: true,
  },
  {
    id: "33",
    name: "Inspira el cambio",
    width: "w-75",
    slug: "inspira-el-cambio",
    logo: inspiraLogo,
    isBanner: true,
    recycleText: "Todos los residuos participantes #noimportalamarca",
    steps: [
      {
        text: "Consume todo el producto. ¡No dejes ni una gota!",
        img: inspiraSteps01,
      },
      {
        text: "Identifica qué residuos puedes separar",
        img: inspiraSteps02,
      },
      {
        text: "Encuentra en el mapa el centro de acopio más cercano",
        img: inspiraSteps03,
      },
      {
        text: "Valida tus residuos entregando limpios y secos",
        img: inspiraSteps04,
      },
    ],
    carousel: [
      {
        img: inspiraCarouselProduct01,
      },
      {
        img: inspiraCarouselProduct02,
      },
    ],
    description:
      "¡Reciclando puedes GANAR hasta $1,000 en bonificación Cashi®! P&G®, Nestlé® y Walmart® quieren que recicles y que te inspires para que tus residuos tengan una segunda vida participando en el reto. Descarga ecolana app.",
    carouselBanners: [
      {
        img: inspiraCarouselBanner01,
      },
      {
        img: inspiraCarouselBanner02,
      },
    ],
    active: false,
  },{
    id: "36",
    name: "TRANSFOMAR",
    width: "w-75",
    slug: "a-reciclar",
    logo: inixLogo,
    isBanner: true,
    recycleText:
      "Transfomar, vasos, domos, bisagras de plástico (PET, PP, PE y PS)",
    steps: [
      {
        text: "Quita todo el residuo restante",
        img: inixStepsPaso01,
      },
      {
        text: "Retira lo más que se pueda de etiquetas (opcional)",
        img: inixStepsPaso02,
      },
      {
        text: "Junta el mayor número de charolas, vasos, domos, bisagras de plástico (PET, PP, PE y PS).",
        img: inixStepsPaso03,
      },
      {
        text: "Entrega limpios y secos",
        img: inixStepsPaso03,
      },
    ],
    carousel: [
      { img: inixCarouselProduct01 },
      { img: inixCarouselProduct02 },
      { img: inixCarouselProduct03 },
      { img: inixCarouselProduct04 },
      { img: inixCarouselProduct05 },
    ],
    description:
      "Con esta campaña de INIX, se busca dar a conocer que las charolas, los domos, las bisagras y los vasos de plástico (PET, PP, PE y PS) son reciclables. Lleva al centro de acopio tus residuos y sigamos promoviendo la cultura del reciclaje. #INIXRecicla",
    carouselBanners: [{ img: inixCarouselBannersBanner01 }],
    active: false,
  },
  {
    id: "40",
    name: "Reciclando con Novamil®",
    width: "w-75",
    slug: "reciclando-con-novamil",
    logo: novamilLogo,
    isBanner: false,
    recycleText: "Hojalatas de fórmula infantil",
    steps: [
      {
        text: "Retira lo más que puedas de residuos.",
        img: novamilSteps01,
      },
      {
        text: "Acopia por separado las partes.",
        img: novamilSteps02,
      },
      {
        text: "Con un plumón raya la lata.",
        img: novamilSteps03,
      },
      {
        text: "Encuentra el centro de acopio y entrega tus residuos.",
        img: novamilSteps04,
      },
    ],
    carousel: [
      {
        img: novamilCarouselProduct01,
      },
      {
        img: novamilCarouselProduct02,
      },
    ],
    description:
      "Novamil® te invita a que recicles todas las hojalatas de fórmula láctea y obtendrás un beneficio en la tienda de ecolana app. ¡Sigue los pasos y encuentra tu centro de acopio más cercano para llevarlas a reciclar!",
    carouselBanners: [
      {
        img: novamilCarouselBanner01,
      },
      {
        img: novamilCarouselBanner02,
      },
    ],
    active: true,
  },
  {
    id: "43",
    name: "La Ruta de la Pila",
    width: "w-75",
    slug: "la-ruta-de-la-pila",
    logo: IMUReciclaLogo,
    isBanner: false,
    recycleText: "Recicla en tu camino y deposita tus pilas alcalinas usadas",
    steps: [
      {
        text: "Recicla en tu camino y deposita tus pilas alcalinas usadas",
        img: IMUReciclaLogo,
      },
      {
        text: "Recicla en tu camino y deposita tus pilas alcalinas usadas",
        img: IMUReciclaLogo,
      },
      {
        text: "Recicla en tu camino y deposita tus pilas alcalinas usadas",
        img: IMUReciclaLogo,
      },
      {
        text: "Recicla en tu camino y deposita tus pilas alcalinas usadas",
        img: IMUReciclaLogo,
      },
    ],
    carousel: [
      {
        img: IMUReciclaLogo,
      },
      {
        img: IMUReciclaLogo,
      },
    ],
    description:
      "Encuentra las columnas de IMU Recicla en tu camino y deposita tus pilas alcalinas usadas",
    carouselBanners: [
      {
        img: IMUReciclaLogo,
      },
      {
        img: IMUReciclaLogo,
      },
    ],
    active: true,
  },
];
