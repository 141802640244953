import { ValidationsImages } from "../ValidationsImages/ValidationsImages";
import { ValidationGroupButton } from "../ValidationGroupButton/ValidationGroupButton";
import { DialogAdmins } from "@/components/atoms";
import { Product } from "@/interfaces/productInterfaces";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormWatch,
} from "react-hook-form";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Input } from "@/components/atoms/shadcn/ui/input";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { CirclePlus } from "lucide-react";
import { GridViewProductImages } from "../GridViewProductImages/GridViewProductImages";
import { TypeMeasure, WasteCategoriesId, WasteId } from "@/interfaces";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

type ViewDetailsDialogProps = {
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
  handleRemove: () => void;
  handleRejected: () => void;
  isEditable: boolean;
  control: Control<Product, any>;
  watch: UseFormWatch<Product>;
  errors: FieldErrors<Product>;
  fields: FieldArrayWithId<Product, "product_wastes", "id">[];
  wastes: WasteId[];
  waste_categories: WasteCategoriesId[];
  type_measure: TypeMeasure[];
  handleAddNewProductWaste: () => void;
  handleSubmit: () => void;
};

export const ViewDetailsProduct = ({
  isOpen = false,
  onClose = () => {},
  handleRemove,
  handleRejected,
  isEditable,
  control,
  watch,
  errors,
  fields,
  waste_categories,
  wastes,
  type_measure,
  handleAddNewProductWaste,
  handleSubmit,
}: ViewDetailsDialogProps) => {
  return (
    <DialogAdmins
      title="Detalles"
      variant={"green"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="flex flex-wrap w-1/3 justify-center items-center gap-4">
        <Controller
          name={`image`}
          control={control}
          render={({ field: { value } }) => (
            <TransformWrapper>
              <TransformComponent>
                <ValidationsImages
                  image={value}
                  variant={"greenGradient"}
                  className="w-full"
                  left_text={watch("id_user")}
                  right_text={watch("user_name")}
                />
              </TransformComponent>
            </TransformWrapper>
          )}
        />
      </div>
      <div className="grid grid-cols-5 w-2/3 gap-4 text-ecolana-black text-center mb-4 pl-5">
        <div className="col-span-3 col-start-2  grid grid-cols-3 gap-4 justify-center w-full px-4">
          <Label
            htmlFor="sku"
            className="text-admins-text-active flex items-center justify-center"
          >
            SKU
          </Label>
          <Controller
            name="sku"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                id="sku"
                name={name}
                value={value}
                variant={"adminGray"}
                className="col-span-2"
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className=" col-span-3 text-ecolana-wasteRed font-semibold">
            {errors.sku?.message}
          </p>
        </div>
        <div className="col-span-2 flex flex-col items-center justify-around">
          <Label htmlFor="date_register">Fecha de petición de registro</Label>
          <Controller
            name="date_register"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                id="date_register"
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="col-span-2 col-start-4 flex flex-col items-center justify-around">
          <Label htmlFor="date_register">Marca del producto</Label>
          <Controller
            name="brand_name"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                id="brand"
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed w-full font-semibold">
            {errors.brand_name?.message}
          </p>
        </div>
        <div className="col-span-2 flex flex-col items-center justify-around">
          <Label htmlFor="company">Empresa del producto</Label>
          <Controller
            name="company_name"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                id="company"
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.company_name?.message}
          </p>
        </div>
        <div className="col-span-2 col-start-4 flex flex-col items-center justify-around">
          <Label htmlFor="sub_brand">Submarca</Label>
          <Controller
            name="sub_brand_name"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                id="sub_brand"
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.sub_brand_name?.message}
          </p>
        </div>
        <div className="col-span-2 flex flex-col items-center justify-around">
          <Label htmlFor="type_measure">Tipo de Medida</Label>
          <Controller
            name="type_measure"
            control={control}
            render={({ field: { name, onChange, value } }) => (
                <SelectWithDefault
                  id="type_measure"
                  value={value}
                  name={name}
                  setValue={onChange}
                  selectedItems={type_measure}
                  placeHolder="Seleccionar"
                  className="w-full"
                  variant={isEditable ? "adminGreen" : "adminDisabled"}
                  disabled={!isEditable}
                />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.type_measure?.message}
          </p>
        </div>
        <div className="col-span-2 col-start-4 flex flex-col items-center justify-around">
          <Label htmlFor="measure">Medida</Label>
          <Controller
            name="measure"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <Input
                id="measure"
                name={name}
                value={value}
                variant={"adminGray"}
                disabled={!isEditable}
                onChange={onChange}
              />
            )}
          />
          <p className="text-ecolana-wasteRed font-semibold">
            {errors.measure?.message}
          </p>
        </div>
      </div>
      {isEditable && (
        <Button
          className="mx-10 my-7 justify-self-start bg-admins-button-green rounded-lg text-lg w-fit"
          onClick={handleAddNewProductWaste}
        >
          <CirclePlus className="text-white mx-4 w-5 h-5" />
          Agregar Categoría/Residuo
        </Button>
      )}
      {fields.length > 0 && (
        <GridViewProductImages
          wastes={wastes}
          waste_categories={waste_categories}
          control={control}
          fields={fields}
          errors={errors}
          isEditable={isEditable}
        />
      )}
      <div className="w-full flex flex-wrap justify-center items-center">
        {isEditable && (
          <div className={`flex w-1/3 items-center justify-center`}>
            <ValidationGroupButton
              handleSubmit={handleSubmit}
              handleRemove={() => {
                onClose();
                handleRemove();
              }}
              handleRejected={handleRejected}
            />
          </div>
        )}
        <div className="flex flex-col w-2/3 justify-center space-y-3 mb-4 pl-5 order-1">
          <Label
            className="px-3 text-admins-text-active text-sm"
            htmlFor="description"
          >
            Descripción
          </Label>
          <Controller
            name="description"
            control={control}
            render={({ field: { name, onChange, value } }) => (
              <>
                <Textarea
                  id="description"
                  placeholder="Descripción"
                  variant={"adminGray"}
                  className="rounded-lg p-2"
                  name={name}
                  value={value}
                  onChange={onChange}
                  disabled={!isEditable}
                />
                <p>{errors.description?.message}</p>
              </>
            )}
          />
        </div>
      </div>
    </DialogAdmins>
  );
};
