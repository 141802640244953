import {
  WasteCategoriesId,
  WasteId,
  collectionCenterValidations,
} from "@/interfaces";
import { Trash } from "lucide-react";
import { Control, FieldErrors } from "react-hook-form";
import { ControlledInput } from "../ControlledInput/ControlledInput";
import { ControlledSelectWithDefault } from "../ControlledSelectWithDefault/ControlledSelectWithDefault";

type RowInputDataProps = {
  data_validations?: { sku: string; branch: string; description: string };
  isActive?: boolean;
  control: Control<collectionCenterValidations, any>;
  errors?: FieldErrors<collectionCenterValidations>;
  handleRemoveSkuFromList: (value: boolean) => void;
  idx: number;
  wastes: WasteId[];
  waste_categories: WasteCategoriesId[];
};

export const RowInputDataCCValidation = ({
  control,
  errors,
  handleRemoveSkuFromList,
  isActive,
  idx,
  waste_categories,
  wastes,
}: RowInputDataProps) => {
  return (
    <>
      {isActive && (
        <Trash
          onClick={() => handleRemoveSkuFromList(true)}
          className="text-admins-button-red justify-self-center"
        />
      )}
      <ControlledInput
        label={"Cantidad"}
        name={`wastes_validations.${idx}.quantity`}
        control={control}
        variant={"adminGray"}
        placeholder={"Cantidad"}
        className={`${isActive ? "" : "col-start-2"} col-span-3`}
        disabled={!isActive}
        errors={
          errors?.wastes_validations
            ? errors?.wastes_validations[idx]?.quantity?.message
            : undefined
        }
      />
      <ControlledSelectWithDefault
        control={control}
        name={`wastes_validations.${idx}.waste_id`}
        placeholder={"Residuo"}
        selectedItems={wastes}
        className={`col-span-3`}
        disabled={!isActive}
        errors={
          errors?.wastes_validations
            ? errors?.wastes_validations[idx]?.waste_id?.message
            : undefined
        }
      />
    </>
  );
};
