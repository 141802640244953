import { useForm } from "react-hook-form";
import { FormCollectionCentersFilters } from "@/interfaces";

type useTransactionStatesProps = {
  default_type_collection_center?:
    | "collection_centers"
    | "global"
    | "green_points"
    | "collectors";
};

export const useTypeCollectionCenters = ({
  default_type_collection_center = "global",
}: useTransactionStatesProps) => {
  const {
    control: control_centers,
    watch: watch_centers,
    setValue: setValue_centers,
    getValues: getValues_centers,
  } = useForm<FormCollectionCentersFilters>({
    defaultValues: { type_collection_center: default_type_collection_center },
  });
  const type_collection_center = watch_centers("type_collection_center");
  return { control_centers, type_collection_center };
};
