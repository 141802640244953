import { ecolanitaLatitud } from "../../../assets";
import { Card, CardContent } from "@/components/atoms/shadcn/ui/card";
import Image from "next/image";
export type CardAdvertisementProps = {
  title?: string;
  img?: string;
  description?: string;
  footer?: string;
};

export const CardAdvertisement = ({
  title = "Reciclando por acapulco",
  img = ecolanitaLatitud.src,
  description = "Recicla y valida para participar en el ranking",
  footer = "1er Lugar: Tarjeta de amazón con un valor de 1000 pesos MXN",
}: CardAdvertisementProps) => {
  return (
    <Card className="shadow-lg">
      <CardContent className="text-sm flex flex-wrap gap-3 p-3">
        <div className="w-2/12 flex justify-center items-center">
          <Image
            src={img}
            className="rounded-full aspect-square w-full"
            alt=""
            width={50}
            height={50}
          />
        </div>
        <div className="w-9/12 flex flex-col">
          <h3 className="text-[#2CAD8F] text-sm">{title}</h3>
          <p className=" text-[0.7rem]">{description}</p>
          <p className=" text-[0.7rem] font-bold ">{footer}</p>
        </div>
      </CardContent>
    </Card>
  );
};
