import { colors_admin } from "@/styles/fullConfig";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from "chart.js";
import { Bar } from "react-chartjs-2";
const optionsChart = {
  indexAxis: "y" as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: "Horizontal Chart",
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const labels = [
  "Nivel 1",
  "Nivel 2",
  "Nivel 3",
  "Nivel 4",
  "Nivel 5",
  "Nivel 6",
  "Nivel 7",
  "Nivel 8",
  "Nivel 9",
  "Nivel 10",
];

export const dataChart = {
  labels,
  datasets: [
    {
      label: "Horizontal Bar Chart",
      data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      backgroundColor: [
        colors_admin.graphics["level1"],
        colors_admin.graphics["level2"],
        colors_admin.graphics["level3"],
        colors_admin.graphics["level4"],
        colors_admin.graphics["level5"],
        colors_admin.graphics["level6"],
        colors_admin.graphics["level7"],
        colors_admin.graphics["level8"],
        colors_admin.graphics["level9"],
        colors_admin.graphics["level10"],
      ],
    },
  ],
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChartHorizontal = ({
  data = dataChart,
  options = optionsChart,
}: {
  data?: ChartData<"bar">;
  options?: ChartOptions<"bar">;
}) => {
  return (
    <div className="w-full flex items-center justify-center">
      <Bar
        className="h-full"
        options={options}
        data={data}
      />
    </div>
  );
};
