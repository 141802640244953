import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/atoms/shadcn/ui/carousel";
import { BadgeCampaignCard } from "@/components/molecules";
import { BadgeInfoInterface, BadgesDataInterface } from "@/interfaces";
import { Control, Controller, UseFormSetValue } from "react-hook-form";

export const CarouselBadges = ({
  badges = [
    { slug: "badge_1", isBadge: false, badgeType: "type_1" },
    { slug: "badge_2", isBadge: true, badgeType: "type_2" },
    { slug: "badge_3", isBadge: false, badgeType: "type_1" },
    { slug: "badge_4", isBadge: false, badgeType: "type_1" },
    { slug: "badge_5", isBadge: true, badgeType: "type_3" },
  ],
  control,
  setValue,
}: {
  badges?: BadgesDataInterface[];
  control: Control<BadgeInfoInterface, any>;
  setValue: UseFormSetValue<BadgeInfoInterface>;
}) => {
  return (
    <div className="flex w-full justify-center px-3 lg:px-8 items-center">
      <Carousel opts={{ loop: true, align: "start" }} className="flex w-full ">
        <CarouselContent className="-ml-2 md:-ml-4">
          <BadgesItems badges={badges} control={control} setValue={setValue} />
        </CarouselContent>
        <CarouselPrevious className="bg-white fill-white hidden lg:flex" />
        <CarouselNext className="bg-white fill-white hidden lg:flex" />
      </Carousel>
    </div>
  );
};

const BadgesItems = ({
  badges = [],
  control,
  setValue,
}: {
  badges?: BadgesDataInterface[];
  control: Control<BadgeInfoInterface, any>;
  setValue: UseFormSetValue<BadgeInfoInterface>;
}) => {
  return badges.map((uni_badge, index) => (
    <Controller
      key={`uni_badge_${index * Math.random() * 100}`}
      control={control}
      name="selectedBadge"
      render={({ field: { name, onChange, value } }) => (
        <CarouselItem
          className={`${
            uni_badge.isBadge
              ? "basis-10/12 lg:basis-7/12"
              : "basis-10/12 lg:basis-7/12"
          } mb-5`}
          key={`CarouselItem_${index * Math.random() * 100}`}
          onClick={() => {
            onChange(uni_badge.slug);
            setValue("typeBadge", uni_badge.badgeType);
            if (uni_badge.badgeType == "type_3") {
              setValue("carouselIndex", 0);
            } else {
              setValue("carouselIndex", 4);
            }
            setValue("selectedTab", "app_activity");
          }}
        >
          <BadgeCampaignCard
            key={`badge_card_${index}`}
            badgeInfo={uni_badge}
            isSelected={value == uni_badge.slug}
          />
        </CarouselItem>
      )}
    />
  ));
};
