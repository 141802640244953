import { Control, Controller, FieldErrors, useWatch } from "react-hook-form";
import { ValidationsImages } from "../ValidationsImages/ValidationsImages";
import { InputImages } from "../InputImages/InputImages";
import { Sku } from "@/interfaces";
import { validation_image } from "@/assets";

type ControlledInputImageSKUProps = {
  control: Control<Sku, any>;
  errors_sku: FieldErrors<Sku>;
  baseImage: boolean;
  idx: number;
};
export const ControlledInputImageSKU = ({
  control,
  errors_sku,
  baseImage,
  idx,
}: ControlledInputImageSKUProps) => {
  const output_product = useWatch({ name: "product_wastes", control });
  return (
    <>
      {baseImage && (
        <>
          <Controller
            name={`image`}
            control={control}
            render={({ field: { value } }) => {
              return <ValidationsImages image={value} />;
            }}
          />
          <Controller
            name={`file`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return <InputImages value={value} onChange={onChange} />;
            }}
          />
        </>
      )}
      {!baseImage && (
        <>
          <Controller
            name={`product_wastes.${idx}.file`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return <InputImages value={value} onChange={onChange} />;
            }}
          />
          <Controller
            name={`product_wastes.${idx}.image`}
            control={control}
            render={({ field: { value } }) => {
              return <ValidationsImages image={value ?? validation_image} />;
            }}
          />
        </>
      )}
      <p>
        {errors_sku.image?.message} {errors_sku.file?.message}
      </p>
    </>
  );
};
