import { X } from "lucide-react";

export const CustomLabelTags = ({
  text = "Label",
  show_tick = false,
}: {
  text?: string;
  show_tick?: boolean;
}) => {
  return (
    <div className="flex space-x-2 px-3 items-center justify-around bg-transparent rounded-xl border border-admins-labels-border text-admins-labels-text">
      <p>{text}</p>
      {show_tick && <X />}
    </div>
  );
};
