import { ColumnDef, Row } from "@tanstack/react-table";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { ValidationsImages } from "../molecules";
import { Product, RowProduct } from "@/interfaces/productInterfaces";
import Image from "next/image";
import { validation_image } from "@/assets";

export const columnWastesAccepted = (
  getRowInformation: (data: Product) => void
): ColumnDef<Product>[] => {
  return [
    {
      accessorKey: "wastes",
      header: "Residuos",
    },
    {
      accessorKey: "num_cc",
      header: "No. Acopio",
    },
    {
      accessorKey: "details_cc",
      header: "Detalle",
      cell: ({ row }) => <Button variant={"adminGhost"}>Ver detalle</Button>,
    },
    {
      accessorKey: "num_gp",
      header: "No. Puntos Verdes",
    },
    {
      accessorKey: "details_gp",
      header: "Detalle",
      cell: ({ row }) => <Button variant={"adminGhost"}>Ver detalle</Button>,
    },
  ];
};
