import { cx } from "class-variance-authority";
import Image, { StaticImageData } from "next/image";
type MostSearchedWasteCardProps = {
  img: string | StaticImageData;
  label: string;
  onClick?: () => void;
  selected?: boolean;
};
export const MostSearchedWasteCard = ({
  img,
  label,
  selected,
  onClick,
}: MostSearchedWasteCardProps) => {
  const style = cx(
    "w-[2rem] h-[2rem] rounded-[4rem] flex justify-center items-center hover:bg-gray-400",
    `${selected ? "bg-gray-400" : ""}`
  );
  return (
    <div className="w-2/12 flex flex-col cursor-pointer text-center items-center" onClick={onClick}>
      <div className={style}>
        <Image src={img} alt="" width={80} height={80} />
      </div>
      <p className="text-[#1B2C32] text-xxs">{label}</p>
    </div>
  );
};
