import {
  CardNumOverview,
  WasteValidationsContainer,
} from "@/components/molecules";
import { cn } from "@/lib/utils";
import React, { DetailedHTMLProps, HTMLAttributes } from "react";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { FormFilterGraph, useGraphicsData } from "@/hooks";
import { CarouselCampaignBadgesTabs } from "../CarouselCampaignBadgesTabs/CarouselCampaignBadgesTabs";
import {
  BadgeInfoInterface,
  DataGraphicObjectType,
  TypeBadgeType,
  dataCardsType,
} from "@/interfaces";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";

interface DataPerCardMKTProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onlyCards?: boolean;
  typeCard: TypeBadgeType;
  watch_badge: UseFormWatch<BadgeInfoInterface>;
  control_graphics: Control<FormFilterGraph, any>;
  control_badge: Control<BadgeInfoInterface, any>;
  setValue_badge: UseFormSetValue<BadgeInfoInterface>;
}
const dataArray: DataGraphicObjectType = {
  type_1: [
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Kg acumulados",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios Activos",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios con TyC",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Validaciones por usuario",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Kg validados por usuario",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Kg por validación",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "No. de acopios participantes",
    },
  ],
  type_2: [
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios Activos",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Usuarios con TyC",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "No. de acopios y/o puntos participantes",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "No. Total Validaciones",
    },
    {
      name_graphic_data: "data_1",
      nameFilter: "globalRange",
      title: "Prom. de Validaciones por usuario",
    },
  ],
};

export const DataPerCardMKT = ({
  className,
  onlyCards,
  typeCard = "type_1",
  watch_badge,
  control_graphics,
  control_badge,
  setValue_badge,
}: DataPerCardMKTProps) => {
  return (
    <div className={cn(className)}>
      {onlyCards ? (
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5">
          {typeCard !== "type_3" &&
            dataArray[typeCard]?.map((uni_data, index) => (
              <WasteValidationsContainer
                className={`w-full`}
                key={`uni_bagse_num_${index}`}
                title={uni_data.title}
                filter={
                  <FilterPerYearController
                    name={"graphsRanges"}
                    control={control_graphics}
                  />
                }
              >
                <CardNumOverview
                  variant={"collection_centers"}
                  className="w-1/2 aspect-square items-center"
                />
              </WasteValidationsContainer>
            ))}
        </div>
      ) : (
        <CarouselCampaignBadgesTabs
          watch_badge={watch_badge}
          control_graphics={control_graphics}
          control_badge={control_badge}
          setValue_badge={setValue_badge}
        />
      )}
    </div>
  );
};
