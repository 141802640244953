import {
  CollectionCenter,
  PropsCenter,
} from "@/interfaces/collectionCenterInterface";

// Mapeo de los días de la semana en inglés a español
const dayTranslations: { [key: string]: string } = {
  monday: "Lunes",
  tuesday: "Martes",
  wednesday: "Miércoles",
  thursday: "Jueves",
  friday: "Viernes",
  saturday: "Sábado",
  sunday: "Domingo",
};

const formatTimeUTC = (isoString: string): string => {
  const date = new Date(isoString);
  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export function getCollectionCenterData(
  collection_center: CollectionCenter
): PropsCenter {
  let id = collection_center.id;
  let phone =
    collection_center.contacts.data.length > 0
      ? `${collection_center.contacts.data[0].phone} ${
          collection_center.contacts.data[0].phone_ext == "" ||
          collection_center.contacts.data[0].phone_ext == undefined ||
          collection_center.contacts.data[0].phone_ext == null
            ? ""
            : "Ext: " + collection_center.contacts.data[0].phone_ext
        }`
      : null;
  const mapsLink = `https://www.google.com/maps/dir/?api=1&destination=${collection_center.address.data.lat},${collection_center.address.data.lng}`;
  const wazeLink = `https://waze.com/ul?ll=${collection_center.address.data.lat},${collection_center.address.data.lng}`;
  let name = collection_center.name;
  let schedule = collection_center.schedule_model.data
    ? collection_center.schedule_model.data.schedule_days.data
        .map(
          (day) =>
            `${dayTranslations[day.day]}: ${formatTimeUTC(
              day.start_time
            )} - ${formatTimeUTC(day.end_time)}`
        )
        .join(", ")
    : collection_center.schedule;
  let benefit = collection_center.collection_center_benefit.data.name;
  let wastes =
    collection_center.wastes.data.length > 0
      ? collection_center.wastes.data.map((uniWaste) => uniWaste.name)
      : [];
  let address = collection_center.address.data.text_address;
  let description = collection_center.description;
  return {
    address,
    wastes,
    benefit,
    schedule,
    name,
    mapsLink,
    wazeLink,
    phone,
    description,
    id,
  };
}
