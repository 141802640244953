import { ValidationsImages } from "../ValidationsImages/ValidationsImages";
import { RowInputData } from "../RowInputData/RowInputData";
import { DialogAdmins } from "@/components/atoms";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Input } from "@/components/atoms/shadcn/ui/input";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { MKTValidationInterface, ShoppingPlacesId } from "@/interfaces";
import { ValidationGroupButton } from "../ValidationGroupButton/ValidationGroupButton";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import { ControlledInput } from "../ControlledInput/ControlledInput";

type DialogValidationsMKTProps = {
  isOpen?: boolean;
  onClose?: (() => void) | undefined;
  handleRemove: () => void;
  handleRejected: () => void;
  isEditable?: boolean;
  control: Control<MKTValidationInterface, any>;
  errors: FieldErrors<MKTValidationInterface>;
  handleSubmit: () => void;
};

export const DialogValidationsMKT = ({
  isOpen = false,
  onClose = () => {},
  handleRemove,
  handleRejected,
  isEditable,
  control,
  errors,
  handleSubmit,
}: DialogValidationsMKTProps) => {
  return (
    <DialogAdmins
      title="Detalles"
      variant={"green"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className=" col-span-2 flex flex-wrap justify-center items-center gap-4">
        <Controller
          name={"evidence"}
          control={control}
          render={({ field: { value } }) => (
            <TransformWrapper>
              <TransformComponent>
                <ValidationsImages image={value == "" ? undefined : value} />
              </TransformComponent>
            </TransformWrapper>
          )}
        />
      </div>
      <div className="col-span-4 flex flex-wrap justify-center py-4">
        <div className="grid grid-cols-3 gap-4 justify-center w-full px-4">
          <Label
            htmlFor="date_register"
            className="text-admins-text-active flex items-center justify-center"
          >
            Nombre de la Actividad:
          </Label>
          <ControlledInput
            name="name_activity"
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active flex items-center justify-center"
          >
            Descripción de la actividad
          </Label>
          <ControlledInput
            name="description"
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
        </div>
        <div className="grid grid-cols-8 w-full gap-3 text-ecolana-black text-center  px-3 py-6">
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active col-span-2 flex items-center justify-center"
          >
            Fecha de Registro
          </Label>
          <ControlledInput
            name={"date_insert"}
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active col-span-2 flex items-center justify-center"
          >
            ID de Usuario
          </Label>

          <ControlledInput
            name={"id_user"}
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />

          <Label
            htmlFor="purchase_place"
            className="text-admins-text-active col-span-2 flex items-center justify-center"
          >
            Nombre de Usuario
          </Label>
          <ControlledInput
            name={"user_name"}
            control={control}
            className="col-span-2"
            variant={"adminWhiteBorder"}
            disabled
          />
        </div>

        {isEditable && (
          <div className="flex flex-col w-3/4 justify-center">
            <p className="text-center text-xl text-ecolana-black">
              ¿Agregar comentarios?
            </p>
            <div className="flex flex-col w-full space-y-3">
              <Label
                className="px-3 text-admins-text-active text-sm"
                htmlFor="textArea"
              >
                Comentarios
              </Label>
              <Controller
                name={"comments"}
                control={control}
                render={({ field: { name, onChange, value } }) => (
                  <>
                    <Textarea
                      name={name}
                      onChange={onChange}
                      value={value}
                      placeholder="Comentarios"
                      variant={"adminGray"}
                      className="rounded-lg p-2"
                      id="textArea"
                      disabled={!isEditable}
                    />
                    <p className="text-ecolana-red py-3">
                      {errors.comments?.message}
                    </p>
                  </>
                )}
              />
            </div>
          </div>
        )}

        {isEditable && (
          <ValidationGroupButton
            handleRejected={handleRejected}
            handleRemove={handleRemove}
            handleSubmit={handleSubmit}
          />
        )}
      </div>
    </DialogAdmins>
  );
};
