import { Navbar } from "../../organisms/Navbar/Navbar";
import { Footer } from "../../organisms/Footers/Footer";
import { useRouter } from "next/router";
import { ModalMessage } from "@/components/atoms";
import Head from "next/head";
import landingInicioResiduos from "@/public/images/landing/AppInicio_residuos.webp";
import { AvenirNextedium, GothamRounded } from "@/fonts";
import { DataScripts } from "@/components/DataScripts";

export const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  let changeLayOut =
    router.pathname === "/diccionario" ||
    router.pathname === "/diccionario/[id]" ||
    router.pathname === "/campana/[id]";
  return (
    <>
      <Head>
        <title>Ecolana</title>
        <meta
          name="description"
          content="♻️ Expertes en reciclaje, te guiamos en cómo y dónde reciclar ♻️"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <meta name="og:title" content="Ecolana" />
        <meta
          name="og:description"
          content="♻️ Expertes en reciclaje, te guiamos en cómo y dónde reciclar ♻️"
        />
        <meta name="og:image" content={landingInicioResiduos.src} />
        <meta name="og:url" content={`https://ecolana.com.mx`} />
        <meta name="og:type" content="website" />
        <meta name="og:locale" content="es_MX" />
        <meta name="og:site_name" content="Ecolana" />
        <meta name="twitter:title" content="Ecolana" />
        <meta
          name="twitter:description"
          content="♻️ Expertes en reciclaje, te guiamos en cómo y dónde reciclar ♻️"
        />
        <meta name="twitter:image" content={landingInicioResiduos.src} />
        <meta name="twitter:card" content="summary" />
      </Head>
      <main
        className={`flex flex-col min-h-svh w-full ${
          AvenirNextedium.variable
        } ${GothamRounded.variable} ${
          changeLayOut ? "bg-white" : "bg-[#e7fff9]"
        }`}
      >
        <Navbar />
        <div className="flex-grow">{children}</div>
        <Footer />
      </main>
      <ModalMessage isLoading={true} />
      <DataScripts />
    </>
  );
};
