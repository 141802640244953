import React from "react";
import { AdminLayout } from "@/components";
import { CardGradientData } from "@/components/molecules";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/shadcn/ui/tabs";
import { ComprasActivity, ComprasDemographics } from "@/components/organisms";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FetcherOverviewData } from "@/interfaces";
import {
  formatData,
  formatDataComplexNew,
  formatNumber,
  formatParamsForFilters,
} from "@/shared/functions/formData";
import { useSession } from "next-auth/react";
import { dataServices } from "@/hooks/dataServices";
import { useQuery } from "@tanstack/react-query";

export async function fetcherOverviewDataShoppingRegistrations({
  token,
  ranges,
  global,
  years,
}: FetcherOverviewData) {
  const { localRanges, localYears } = formatParamsForFilters(
    ranges,
    global,
    years
  );
  const response = await dataServices({
    url: "/shopping_registrations/overview",
    token,
    params: {
      filters: {
        ...localRanges,
        ...localYears,
      },
    },
  });
  return {
    ...formatData(response.data),
    ...formatDataComplexNew(response.data, "history"),
  };
}

export async function fetcherOverviewDemographicsShoppingRegistrations({
  token,
  ranges,
  global,
  years,
}: FetcherOverviewData) {
  const { localRanges } = formatParamsForFilters(ranges, global, years);
  const response = await dataServices({
    url: "/shopping_registrations/demographics_stats",
    token,
    params: {
      filters: {
        ...localRanges,
      },
    },
  });
  return {
    ...formatData(response.data),
  };
}

function ComprasOverviewPage() {
  const { data: session } = useSession();
  const { data } = useQuery({
    queryKey: ["totals_overview_shopping"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await dataServices({
            url: "/shopping_registrations/overview_totals",
            token: session?.user?.token!,
          })
        : { data: {} };
    },
  });
  return (
    <Tabs defaultValue="Activity">
     <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 items-end z-30 py-10 px-10 w-full">
        <CardGradientData
          variant={"greenActive"}
          icon_to_show={
            <FontAwesomeIcon
              className="text-admins-text-active fa-xl"
              icon={faTicket}
            />
          }
          className="shadow-md aspect-square"
          scans_number={formatNumber(data?.total)}
          text_card="Total Transacciones de compras (Tiempo real)"
        />
        <div className=" col-start-4 flex h-full items-end w-fit">
          <TabsList className="w-full h-14 justify-between rounded-2xl px-4 space-x-5">
            <TabsTrigger
              variant={"adminsTab"}
              className="w-1/2"
              value="Activity"
            >
              Actividad
            </TabsTrigger>
            <TabsTrigger
              variant={"adminsTab"}
              className="w-1/2"
              value="Demographics"
            >
              Demográficos
            </TabsTrigger>
          </TabsList>
        </div>
      </div>
      <ComprasActivity session={session} />
      <ComprasDemographics session={session} />
    </Tabs>
  );
}

export default ComprasOverviewPage;

ComprasOverviewPage.getLayout = function getLayout(page: React.ReactNode) {
  return <AdminLayout>{page}</AdminLayout>;
};
