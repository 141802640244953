import { Button } from "@/components/atoms/shadcn/ui/button";
import { Separator } from "@/components/atoms/shadcn/ui/separator";
import { WasteValidationsContainer } from "@/components/molecules";
import { Fragment } from "react";
const dataWastes: any = [
  { numCC: 2, numGP: 6, nameWaste: "BOPP" },
  { numCC: 13, numGP: 5, nameWaste: "LDPE" },
];

export const TableCardWastes = ({
  handleIsOpen,
}: {
  handleIsOpen: () => void;
}) => {
  return (
    <WasteValidationsContainer
      className={`w-full col-span-2 `}
      key={`uni_bagse_num_`}
      title={"Residuos Participantes"}
    >
      <div className="w-full grid grid-cols-5 gap-5 justify-center">
        <p className="text-center font-bold">Residuos</p>
        <p className="text-center font-bold">No. Acopios</p>
        <p className="text-center font-bold">Detalle</p>
        <p className="text-center font-bold">No. Puntos Verdes</p>
        <p className="text-center font-bold">Detalle</p>
        <Separator orientation="horizontal" className="col-span-5 bg-black" />
        {dataWastes.map((uni_waste: any, idx: number) => (
          <Fragment key={`${idx * Math.random() * 100}_${uni_waste.nameWaste}`}>
            <p className="text-center font-bold">{uni_waste.nameWaste}</p>
            <p className="text-center font-bold">{uni_waste.numCC}</p>
            <Button variant={"adminUnderlineGhost"} onClick={handleIsOpen}>
              Ver detalle
            </Button>
            <p className="text-center font-bold">{uni_waste.numGP}</p>
            <Button variant={"adminUnderlineGhost"} onClick={handleIsOpen}>
              Ver detalle
            </Button>
            <Separator
              orientation="horizontal"
              className="col-span-5 bg-black"
            />
          </Fragment>
        ))}
      </div>
    </WasteValidationsContainer>
  );
};
