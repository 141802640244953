import { TableCell, TableRow } from "@/components/atoms/shadcn/ui/table";
import { ControlledInput } from "../ControlledInput/ControlledInput";
import { Copy, ImagePlus, SaveIcon, Trash2, X } from "lucide-react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormWatch,
  useWatch,
} from "react-hook-form";
import { SelectWithDefault } from "../SelectWithDefault/SelectWithDefault";
import { Sku } from "@/interfaces/skuInterface";
import { Company, TypeMeasure, WasteCategoriesId, WasteId } from "@/interfaces";

type EditableRowProps = {
  control: Control<Sku, any>;
  errors_sku: FieldErrors<Sku>;
  fields: FieldArrayWithId<Sku, "product_wastes", "id">[];
  handleSaveRow: () => void;
  handleCancelEdit: () => void;
  handleViewImages: () => void;
  companys: Company[];
  type_measures: TypeMeasure[];
  waste_categories: WasteCategoriesId[];
  wastes: WasteId[];
  handleAddProductWaste: () => void;
  watch: UseFormWatch<Sku>;
};

export const EditableRow = ({
  control,
  fields,
  errors_sku,
  handleSaveRow,
  handleCancelEdit,
  handleViewImages,
  handleAddProductWaste,
  companys,
  type_measures,
  waste_categories,
  wastes,
  watch,
}: EditableRowProps) => {
  return (
    <TableRow data-state={"selected"}>
      <TableCell>
        <Controller
          name={"company"}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <>
              <SelectWithDefault
                value={value}
                name={name}
                setValue={onChange}
                selectedItems={companys}
                placeHolder="Empresas"
                className="w-full"
              />
              <p className="text-ecolana-wasteRed col-span-3 font-semibold">
                {errors_sku.company?.message}
              </p>
            </>
          )}
        />
      </TableCell>
      <TableCell>
        <>
          <ControlledInput
            type="text"
            variant={"adminWhiteBorder"}
            control={control}
            name={"brand"}
          />
          <p className="text-ecolana-wasteRed col-span-3 font-semibold">
            {errors_sku.brand?.message}
          </p>
        </>
      </TableCell>
      <TableCell>
        <>
          <ControlledInput
            type="text"
            variant={"adminWhiteBorder"}
            control={control}
            name={"sub_brand"}
          />
          <p className="text-ecolana-wasteRed col-span-3 font-semibold">
            {errors_sku.sub_brand?.message}
          </p>
        </>
      </TableCell>
      <TableCell>
        <>
          <ControlledInput
            type="string"
            variant={"adminWhiteBorder"}
            control={control}
            name={"sku"}
          />
          <p className="text-ecolana-wasteRed col-span-3 font-semibold">
            {errors_sku.sku?.message}
          </p>
        </>
      </TableCell>
      <TableCell>
        <>
          <ControlledInput
            type="string"
            variant={"adminWhiteBorder"}
            control={control}
            name={"measure"}
          />
          <p className="text-ecolana-wasteRed col-span-3 font-semibold">
            {errors_sku.measure?.message}
          </p>
        </>
      </TableCell>
      <TableCell>
        <Controller
          name={"type_measure"}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <>
              <SelectWithDefault
                value={value}
                name={name}
                setValue={onChange}
                selectedItems={type_measures}
                placeHolder="Tipo de Medida"
                className="w-full"
              />
              <p className="text-ecolana-wasteRed col-span-3 font-semibold">
                {errors_sku.type_measure?.message}
              </p>
            </>
          )}
        />
      </TableCell>
      <TableCell>
        <div className="flex flex-col gap-2 items-center justify-center w-full">
          {fields.map((item, index) => {
            return (
              !watch(`product_wastes.${index}._destroy`) && (
                <Controller
                  key={`${item.id}_${index * Math.random()}_category`}
                  name={`product_wastes.${index}.waste_category_id`}
                  control={control}
                  render={({ field: { name, onChange, value } }) => (
                    <>
                      <SelectWithDefault
                        key={`waste_category_${index}_${item.id}`}
                        value={String(value)}
                        name={name}
                        setValue={onChange}
                        selectedItems={waste_categories}
                        placeHolder="Categoria Residuo"
                        className="w-full"
                      />
                      <p className="text-ecolana-wasteRed font-semibold">
                        {errors_sku.product_wastes
                          ? errors_sku.product_wastes[index]?.waste_category_id
                              ?.message
                          : null}
                      </p>
                    </>
                  )}
                />
              )
            );
          })}
        </div>
      </TableCell>
      <TableCell>
        <div className="grid grid-cols-6 gap-2 items-center justify-center w-full">
          {fields.map((item, index) => {
            return (
              !watch(`product_wastes.${index}._destroy`) && (
                <>
                  <Controller
                    key={`${index * Math.random() * 100}_new_waste`}
                    name={`product_wastes.${index}.waste_id`}
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <div className="col-span-5">
                        <SelectWithDefault
                          key={`new_waste_id_select_${index * Math.random()}_${
                            item.id
                          }`}
                          value={String(value)}
                          name={name}
                          setValue={onChange}
                          selectedItems={wastes}
                          placeHolder="Residuo"
                          className="col-span-5"
                        />
                        <p className="text-ecolana-wasteRed font-semibold">
                          {errors_sku.product_wastes
                            ? errors_sku.product_wastes[index]?.waste_id
                                ?.message
                            : null}
                        </p>
                      </div>
                    )}
                  />
                  <Controller
                    key={`${item.id}_${index}_waste_destroy`}
                    name={`product_wastes.${index}._destroy`}
                    control={control}
                    render={({ field: { name, onChange, value } }) => (
                      <Trash2
                        key={`new_waste_id_${index * Math.random()}_${
                          item.id
                        }}_wastesTrash`}
                        onClick={() => onChange(true)}
                        className="hover:text-admins-button-red cursor-pointer"
                      />
                    )}
                  />
                </>
              )
            );
          })}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex flex-wrap justify-center">
          <ImagePlus onClick={handleViewImages} />
        </div>
      </TableCell>
      <TableCell className="grid grid-cols-3 gap-4 w-full">
        <SaveIcon
          className="text-admins-button-green"
          onClick={handleSaveRow}
        />
        <X onClick={handleCancelEdit} className="text-admins-button-red" />
      </TableCell>
    </TableRow>
  );
};
