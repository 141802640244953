import { RadioGroup } from "@/components/atoms/shadcn/ui/radio-group";
import { RadioGroupProps } from "@radix-ui/react-radio-group";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

// Definimos las props del componente
export type RadioGroupFieldProps<T extends FieldValues> = Omit<
  RadioGroupProps,
  "onChange"
> & {
  name: Path<T>;
  control: Control<T, object>;
  //register: UseFormRegister<T>;
};

// Creamos el componente RadioGroupField
export function ControlledRadioGroup<T extends FieldValues>({
  name,
  control,
  children,
  ...rest
}: RadioGroupFieldProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <RadioGroup
          {...rest}
          onValueChange={field.onChange}
          defaultValue={field.value}
        >
          {children}
        </RadioGroup>
      )}
    />
  );
}
