import { CustomTag, SelectWithSearch } from "@/components/atoms";
import { Button } from "@/components/atoms/shadcn/ui/button";
import { Card } from "@/components/atoms/shadcn/ui/card";
import { Label } from "@/components/atoms/shadcn/ui/label";
import { Textarea } from "@/components/atoms/shadcn/ui/textarea";
import {
  ControlledInput,
  ControlledInputImage,
  ControlledSelectWithDefault,
  SelectWithDefault,
} from "@/components/molecules";
import {
  CC_Form,
  CollectionCenter,
  Schedules,
  States,
  WasteCategoriesId,
  WasteId,
  WastePerCategoryID,
  ZipCodes,
} from "@/interfaces";
import { CirclePlus } from "lucide-react";
import {
  Control,
  Controller,
  FieldArrayWithId,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
  UseFormWatch,
} from "react-hook-form";
import { CheckBoxGroup } from "../CheckBoxGroup/CheckBoxGroup";

type FormCreateCollectorProps = {
  isOpen?: boolean;
  control: Control<CC_Form, any>;
  fields: FieldArrayWithId<CC_Form, "wastes", "id">[];
  watch: UseFormWatch<CC_Form>;
  append: UseFieldArrayAppend<CC_Form, "wastes">;
  remove: UseFieldArrayRemove;
  wastes?: WasteId[];
  wastes_categories?: WasteCategoriesId[];
  wastes_perCategory: WastePerCategoryID[];
  schedules?: Schedules[];
  nups?: ZipCodes[];
  states: States[];
  handleDialogNewSchedule: () => void;
  onSubmitValidCC: () => void;
};

export const FormCreateCollector = ({
  isOpen,
  control,
  wastes = [],
  wastes_categories = [],
  wastes_perCategory = [],
  fields,
  append,
  watch,
  schedules = [],
  nups = [],
  states = [],
  handleDialogNewSchedule,
  onSubmitValidCC,
}: FormCreateCollectorProps) => {
  return (
    <section
      className={`${isOpen ? "grid" : "hidden"} gap-6 grid-cols-4 w-full`}
    >
      <Card className="col-span-4 xl:col-span-3 grid grid-cols-2 gap-4 p-5">
        <h3 className="col-span-2 text-admins-button-green">
          Información General
        </h3>
        <div className="col-span-2">
          <Label className="text-admins-text-active">
            Nombre de recolector
          </Label>
          <ControlledInput
            variant={"adminGray"}
            control={control}
            name="name"
            placeholder="Nombre"
          />
        </div>
        <div>
          <Label className="text-admins-text-active">Teléfono</Label>
          <ControlledInput
            variant={"adminGray"}
            control={control}
            name="contact.phone"
            placeholder="Teléfono"
          />
        </div>
        <div>
          <Label className="text-admins-text-active">Correo electrónico</Label>
          <ControlledInput
            variant={"adminGray"}
            control={control}
            name="contact.email"
            type="email"
            placeholder="Correo electrónico"
          />
        </div>
        {/* <div>
          <Label className="text-admins-text-active">Género</Label>
          <ControlledSelectWithDefault
            variant={"adminDisabled"}
            control={control}
            name="active"
            placeholder="Género"
            selectedItems={[]}
          />
        </div> */}
        {/* <div>
          <Label className="text-admins-text-active">Fecha de nacimiento</Label>
          <ControlledInput
            variant={"adminGray"}
            control={control}
            type="date"
            name=""
            placeholder="dd/mm/yyyy"
          />
        </div> */}
        <div className="col-span-2">
          <Label className="text-admins-text-active">Notas</Label>
          <Controller
            control={control}
            name={"notes"}
            render={({ field: { value, name, onChange } }) => (
              <Textarea
                placeholder={"Notas"}
                onChange={onChange}
                value={value}
                className="w-full "
                variant={"adminGray"}
              />
            )}
          />
        </div>
      </Card>
      <Card className="col-span-4 xl:col-span-1 row-span-1 xl:row-span-2 flex flex-col items-center justify-between p-4">
        <h3 className="col-span-2 text-admins-button-green">Imagen</h3>
        <ControlledInputImage<any>
          control={control}
          name_image={"image"}
          name_file={"file"}
        />
      </Card>
      <Card className="col-span-4 xl:col-span-3 flex flex-wrap gap-4 justify-center p-5">
        <h3 className="w-full text-admins-button-green">
          Días y horarios de operación
        </h3>
        <Controller
          name={"schedule_id"}
          control={control}
          render={({ field: { name, onChange, value } }) => (
            <CheckBoxGroup
              options={schedules}
              value={value || ""}
              setValue={onChange}
              className=" flex flex-wrap justify-center gap-4 w-full px-2"
            />
          )}
        />
        <Button
          className="col-start-1 col-span-2"
          variant={"adminDownload"}
          onClick={handleDialogNewSchedule}
        >
          <CirclePlus className="text-admins-text-active mx-2 w-5 h-5" />
          Crear Formato de horario
        </Button>
      </Card>
      <Card className="col-span-4 grid grid-cols-2 gap-4 p-5">
        <h3 className="col-span-2 text-admins-button-green">Zona</h3>
        <div>
          <Label className="text-admins-text-active">NUP</Label>
          <ControlledSelectWithDefault
            variant={"adminDisabled"}
            control={control}
            name="nup.nup_id"
            placeholder="NUP"
            selectedItems={nups}
          />
          {/* <Controller
            control={control}
            name="nup_id"
            render={({ field: { name, onChange, value } }) => (
              <div className={"flex flex-col w-full"}>
                <SelectWithDefault
                  value={String(value)}
                  name={name}
                  variant={"adminDisabled"}
                  setValue={handleChangeNup}
                  selectedItems={nups}
                  className={"w-full"}
                  placeHolder="NUP"
                />
              </div>
            )}
          /> */}
        </div>
        <div>
          <Label className="text-admins-text-active">Estado</Label>
          <ControlledSelectWithDefault
            variant={"adminDisabled"}
            control={control}
            name="address.state_id"
            placeholder="Estado"
            selectedItems={states}
          />
        </div>
      </Card>
      <Card className="col-span-4 flex flex-col gap-3 p-5">
        <h3 className="col-span-2 text-admins-button-green">
          Selecciona los residuos que recibirán
        </h3>
        <div className="grid grid-cols-3 gap-10">
          {wastes_categories.map((uni_category) => (
            <div
              className="flex flex-col gap-2"
              key={`${uni_category.name}_${uni_category.id}`}
            >
              <Label className="text-admins-text-active">
                {uni_category.name}
              </Label>
              <SelectWithDefault
                setValue={(select: string) => {
                  if (
                    !fields.some((uni_waste) => uni_waste.waste_id == select)
                  ) {
                    append({ waste_id: select, _destroy: false });
                  }
                }}
                placeHolder={`${uni_category.name}`}
                selectedItems={
                  wastes_perCategory.find(
                    (categoryData) => categoryData.id == uni_category.id
                  )?.wastes ?? []
                }
              />
            </div>
          ))}
        </div>
        <div className="flex flex-wrap gap-2 w-full">
          <Label className="text-admins-text-active">Filtrar por Residuo</Label>
          <SelectWithSearch
            placeHolder={"Residuos"}
            value=""
            setValue={(select: any) => {
              append({ waste_id: select, _destroy: false });
            }}
            selectedItems={wastes}
          />
          <div className="w-full flex flex-wrap gap-2">
            {fields &&
              fields.map(
                (item, index) =>
                  !watch(`wastes.${index}._destroy`) && (
                    <Controller
                      key={`fields_${item.id}_${index}`}
                      name={`wastes.${index}._destroy`}
                      control={control}
                      render={({ field: { name, value, onChange } }) => (
                        <CustomTag
                          bg_color={"white"}
                          colorIcon={"black"}
                          onClick={() => onChange(true)}
                          className="cursor-pointer"
                          tag2Show={
                            wastes.find(
                              (uni_waste) => uni_waste.id == item.waste_id
                            )?.name
                          }
                          key={`waste_${item.id}_customTag_${index}`}
                        />
                      )}
                    />
                  )
              )}
          </div>
        </div>
      </Card>
      <Button
        variant={"adminGray"}
        onClick={onSubmitValidCC}
        className="col-start-2 col-span-2"
      >
        Guardar
      </Button>
    </section>
  );
};
