import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import React, { useEffect, useState } from "react";
import { DataPerCardMKT } from "../DataPerCardMKT/DataPerCardMKT";
import { TableCardWastes } from "../TableCardWastes/TableCardWastes";
import {
  BadgeInfoInterface,
  DataGraphicObjectType,
  KeysFiltersObjectType,
  dataGraphicsType,
} from "@/interfaces";
import { WasteValidationsContainer } from "@/components/molecules";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { OverviewItem } from "../OverviewItem/OverviewItem";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/shadcn/ui/popover";
import { Info } from "lucide-react";
import { useGraphicsData, useMKTActivityData } from "@/hooks";
import { FilterPerWeekMonthController } from "../FilterPerWeekMonthController/FilterPerWeekMonthController";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { DialogCCListMKT } from "../DialogCCListMKT/DialogCCListMKT";
import { useQuery } from "@tanstack/react-query";

export interface AppActivityTabInterface {
  control_badge: Control<BadgeInfoInterface, any>;
  watch_badge: UseFormWatch<BadgeInfoInterface>;
  setValue_badge: UseFormSetValue<BadgeInfoInterface>;
}

const dataGraphicsActivityApp: DataGraphicObjectType = {
  type_1: [
    {
      title: "Usuarios TyC vs Usuarios Activos",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
    },
    {
      title: "Status validaciones Totales VS Validaciones Incompletas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">
                  Validaciones incompletas:{" "}
                </label>
                contempla únicamente las búsquedas que no fueron concretadas en
                una actividad de reciclaje. Un estado de búsqueda puede mutar a
                los Totales y se resta de esta pila de datos*
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Status Validaciones",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Conteo de KG con validaciones ACEPTADAS",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_stacked",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Centros de acopio con más validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Centros de acopio con más kg en validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos con más validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos VS KG (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_2: [
    {
      title: "Usuarios TyC vs Usuarios Activos",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
    },
    {
      title: "Status validaciones Totales VS Validaciones Incompletas",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">
                  Validaciones incompletas:{" "}
                </label>
                contempla únicamente las búsquedas que no fueron concretadas en
                una actividad de reciclaje. Un estado de búsqueda puede mutar a
                los Totales y se resta de esta pila de datos*
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Status Validaciones",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 2,
      graphicType: "vertical_bar_comparative",
      name_graphic_data: "validation_center_types",
      week_date_filter: true,
      infoButton: (
        <Popover>
          <PopoverTrigger>
            <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
          </PopoverTrigger>
          <PopoverContent className=" bg-admins-input-disabled_bg">
            <div className="flex flex-col justify-between  text-admins-text-active ">
              <p className="fs-6 mt-3 mt-lg-0 text-justify">
                <label className="font-extrabold">Totales: </label> es la suma
                de validaciones pendientes, aprobadas y rechazadas.
              </p>
            </div>
          </PopoverContent>
        </Popover>
      ),
    },
    {
      title: "Centros de acopio con más validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "horizontal_bar",
      name_graphic_data: "validation_center_types",
    },
    {
      title: "Residuos con más validaciones (TOP 10)",
      subtitle: undefined,
      nameFilter: "graphsYear.year_history",
      span: 1,
      graphicType: "vertical_bar",
      name_graphic_data: "validation_center_types",
    },
  ],
  type_3: [
    {
      overview: [
        {
          title: "Residuos con más validaciones",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          monthWeekFilter: "filterMonthWeek.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Usuarios Activos vs Actividad",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
          infoButton: (
            <Popover>
              <PopoverTrigger>
                <Info className="text-admins-text-active text-center cursor-pointer rounded-full flex p-0 m-0" />
              </PopoverTrigger>
              <PopoverContent className=" bg-admins-input-disabled_bg">
                <div className="flex flex-col justify-between  text-admins-text-active ">
                  <p className="fs-6 mt-3 mt-lg-0 text-justify">
                    <label className="font-extrabold">
                      Actividades Escaneo y reciclaje:{" "}
                    </label>
                    Usuarios que han realizado{" "}
                    <span className="font-extrabold">ambas</span> actividades al
                    menos una vez. Ejemplo a considerar: Si un usuario estaba
                    contemplado en la gráfica de solo escaneo y realiza una
                    actividad de reciclaje, se resta de la primera gráfica y se
                    suma al conteo de ambas actividades
                  </p>
                </div>
              </PopoverContent>
            </Popover>
          ),
        },
      ],
      rewards: [],
      demographics: [],
    },
    {
      overview: [
        {
          title: "Historial de Escaneos",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Usuarios Activos vs Actividad",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "SKU's más escaneados (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Categorías más escaneados (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Residuos más escaneados (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Marcas más escaneados (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Productos más escaneados (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
      ],
      rewards: [
        {
          title: "Recompensas otorgadas",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
      ],
      demographics: [
        {
          title: "Género",
          subtitle:
            "* Considerando usuarios que al menos han escaneado una vez",
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "doughnut",
          name_graphic_data: "genders",
        },
        {
          title: "Edad",
          subtitle:
            "* Considerando usuarios que al menos han registrado una compra",
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "vertical_bar",
          name_graphic_data: "ages",
        },
        {
          title: "Ubicaciones con más validaciones (TOP 10)",
          subtitle: "*Estado",
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "states",
        },
        {
          title: "Ubicaciones con más validaciones (TOP 10)",
          subtitle: "*Municipio",
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "towns",
        },
        {
          title: "Residuos con más validaciones  por género (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar_stacked",
          name_graphic_data: "wastes_gender",
        },
        {
          title: "Residuos con más validaciones  por edad (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar_stacked",
          name_graphic_data: "wastes_age",
        },
      ],
    },
    {
      overview: [
        {
          title: "Usuarios TyC vs Usuarios Activos",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Status validaciones Totales VS Validaciones Incompletas",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Status validaciones",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Centros de acopio con más validaciones (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "collection_centers_gender",
        },
        {
          title:
            "Centros de acopio con más kg  en validaciones aceptadas (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "collection_centers_age",
        },
        {
          title: "Residuos con más validaciones (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "vertical_bar",
          name_graphic_data: "collection_centers_age",
        },
        {
          title: "Residuos vs KG (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "vertical_bar",
          name_graphic_data: "collection_centers_age",
        },
      ],
      rewards: [
        {
          title: "Stock INICIAL de recompensas",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "doughnut",
          name_graphic_data: "validation_center_types",
        },
        {
          title: "Control de recompensas otorgadas",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar_stacked",
          name_graphic_data: "validation_center_types",
        },
        {
          title: "Recompensas otorgadas",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
      ],
      demographics: [
        {
          title: "Género",
          subtitle:
            "* Considerando usuarios que al menos han escaneado una vez",
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "doughnut",
          name_graphic_data: "genders",
        },
        {
          title: "Acumulación de KG por Género",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "doughnut",
          name_graphic_data: "genders",
        },
        {
          title: "Edad",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "vertical_bar",
          name_graphic_data: "ages",
        },
        {
          title: "Acumulación de KG por Edad",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "vertical_bar",
          name_graphic_data: "ages",
        },
        {
          title: "Ubicaciones con más validaciones (TOP 10)",
          subtitle: "*Estado",
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "states",
        },
        {
          title: "Ubicaciones con más validaciones (TOP 10)",
          subtitle: "*Municipio",
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar",
          name_graphic_data: "towns",
        },
        {
          title: "Residuos con más validaciones  por género (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar_stacked",
          name_graphic_data: "wastes_gender",
        },
        {
          title: "Residuos con más validaciones  por edad (TOP 10)",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 1,
          graphicType: "horizontal_bar_stacked",
          name_graphic_data: "wastes_age",
        },
      ],
    },
    {
      overview: [
        {
          title: "Usuarios TyC vs Usuarios Activos",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Status validaciones",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
        {
          title: "Validaciones aprobadas de actividades evidencia",
          subtitle: undefined,
          nameFilter: "graphsYear.year_history",
          span: 2,
          graphicType: "vertical_bar_comparative",
          name_graphic_data: "validation_center_types",
          week_date_filter: true,
        },
      ],
      rewards: [],
      demographics: [],
    },
  ],
};
const keysGraphicFilters: KeysFiltersObjectType = {
  type_1: [],
  type_2: [],
  type_3: [
    {
      overview: [],
      rewards: [],
      demographics: [],
    },
    {
      overview: [],
      rewards: [],
      demographics: [],
    },
    {
      overview: [],
      rewards: [],
      demographics: [],
    },
    {
      overview: [],
      rewards: [],
      demographics: [],
    },
  ],
};
export const AppActivityTab = ({
  control_badge,
  watch_badge,
  setValue_badge,
}: AppActivityTabInterface) => {
  const typeBadge = watch_badge("typeBadge");
  const selectedTabCarousel = watch_badge("selectedTabCarousel");
  const carouselIndex = watch_badge("carouselIndex");

  const [dataGraphicsActivity, setDataGraphicsActivity] =
    useState<dataGraphicsType>([]);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (typeBadge == "type_3") {
      setDataGraphicsActivity(
        dataGraphicsActivityApp.type_3 &&
          dataGraphicsActivityApp.type_3[carouselIndex]
          ? dataGraphicsActivityApp.type_3[carouselIndex][selectedTabCarousel]
          : []
      );
    } else {
      setDataGraphicsActivity(dataGraphicsActivityApp[typeBadge]);
    }
  }, [selectedTabCarousel, typeBadge]);
  function handleIsOpen() {
    setIsOpen(!isOpen);
  }
  const { control_filters, reset_filters, stats } = useMKTActivityData([], []);
  return (
    <TabsContent
      value="app_activity"
      className="w-full grid grid-cols-2 gap-4 px-3"
    >
      <DataPerCardMKT
        className="col-span-2"
        onlyCards={typeBadge !== "type_3"}
        typeCard={typeBadge}
        watch_badge={watch_badge}
        control_graphics={control_filters}
        control_badge={control_badge}
        setValue_badge={setValue_badge}
      />
      {(selectedTabCarousel == "overview" && carouselIndex == 2) ||
      typeBadge !== "type_3" ? (
        <TableCardWastes handleIsOpen={handleIsOpen} />
      ) : null}
      {dataGraphicsActivity.map((item, idx) => (
        <WasteValidationsContainer
          key={`graphicCell_${idx * Math.random() * 100}`}
          className={`${
            item.span == 1 ? "" : "col-span-2"
          } bg-admins-centers bg-opacity-15`}
          infoButton={item.infoButton}
          title={item.title}
          subtitle={item.subtitle}
          borderVariant={
            carouselIndex == 0
              ? "activity_general_green"
              : carouselIndex == 1
              ? "activity_scan_yellow"
              : carouselIndex == 2
              ? "activity_recycling_violet"
              : carouselIndex == 3
              ? "activity_evidence_blue"
              : "default"
          }
          filter={
            item.week_date_filter ? (
              <FilterPerWeekMonthController
                name_checkbox={item.monthWeekFilter ?? "filterMonthWeek.total"}
                name_date={item.nameFilter}
                control={control_filters}
              />
            ) : (
              <FilterPerYearController
                name={item.nameFilter}
                control={control_filters}
              />
            )
          }
        >
          <OverviewItem
            graphicType={item.graphicType}
            data={
              // generateGraphic(
              //   stats_collection_centers as any,
              //   item.name_graphic_data,
              //   false,
              //   true
              // ) as any
              undefined
            }
          />
        </WasteValidationsContainer>
      ))}
      <DialogCCListMKT isOpen={isOpen} onClose={handleIsOpen} />
    </TabsContent>
  );
};
