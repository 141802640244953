export { DefaultLayout } from "./layouts/DefaultLayout/DefaultLayout";
export { Navbar } from "./organisms/Navbar/Navbar";
export { Footer } from "./organisms/Footers/Footer";
export { CardsCampaigns } from "./organisms/CardsCampaigns/CardsCampaigns";
export { CardPhoneOptions } from "./organisms/CardPhoneOptions/CardPhoneOptions";
export { CarouselRecycling } from "./organisms/CarouselRecycling/CarouselRecycling";
export { CarouselPress } from "./organisms/CarouselPress/CarouselPress";
export { LatitudRLayout } from "./layouts/LatitudRLayout/LatitudRLayout";
export { ReLayout } from "./layouts/ReLayOut/ReLayout";
export { AdminLayout } from "./layouts/AdminLayout/AdminLayout";
export { PapaloteLayout } from "./layouts/PapaloteLayout/PapaloteLayout";
export { ViajeDelEnvaseLayout } from "./layouts/ViajeDelEnvaseLayout/ViajeDelEnvaseLayout";
export { ViajeDeLaPilaLayout } from "./layouts/ViajeDeLaPilaLayout/ViajeDeLaPilaLayout";
