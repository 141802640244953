import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  Path,
} from "react-hook-form";
import { ValidationsImages } from "../ValidationsImages/ValidationsImages";
import { InputImages } from "../InputImages/InputImages";

type ControlledInputImageSKUProps<T extends FieldValues> = {
  control: Control<T, any>;
  errors?: FieldErrors<T>;
  name_image: Path<T>;
  name_file: Path<T>;
};

export function ControlledInputImage<T extends FieldValues>({
  control,
  name_image,
  name_file,
}: ControlledInputImageSKUProps<T>) {
  return (
    <>
      <Controller
        name={name_image}
        control={control}
        render={({ field: { value } }) => <ValidationsImages className="w-2/3" image={value} />}
      />
      <Controller
        name={name_file}
        control={control}
        render={({ field: { onChange, value } }) => (
          <InputImages value={value} onChange={onChange} />
        )}
      />
    </>
  );
}
