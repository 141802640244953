import {
  analyticsEnterprises,
  campaignEnterprises,
  collectionCenterEnterprises,
  communicationEnterprises,
  internalProgramsEnterprises,
  learningEnterprises,
  programmingEnterprises,
  recycleFriendsEnterprises,
} from "../assets";
import { StaticImageData } from "next/image";
export type serviceEnterprisesProps = {
  sideA: {
    img: StaticImageData;
    text: string;
    orientation?: "vertical" | "horizontal" | null;
    sideText?: "top" | "right" | "left" | "bottom" | null;
    gridOption?: "" | "md:row-span-2" | "md:col-span-2";
  };
  sideB: {
    text: string;
    sideText?: "top" | "right" | "left" | "bottom" | null;
  };
};

export const ServicesEnterprises: serviceEnterprisesProps[] = [
  {
    sideA: {
      img: collectionCenterEnterprises,
      text: "Activación de mercados de reciclaje",
      orientation: "vertical",
      sideText: "bottom",
      gridOption: "md:row-span-2",
    },
    sideB: {
      text: "Generamos mercado para los materiales dificiles de reciclar",
      sideText: "top",
    },
  },
  {
    sideA: {
      img: communicationEnterprises,
      text: "Asesoría para la comunicación correcta sobre reciclaje",
      orientation: "horizontal",
      sideText: "left",
      gridOption: "md:col-span-2",
    },
    sideB: {
      text: "Te asesoramos para que tu mensaje llegue de manera correcta al consumidor",
      sideText: "top",
    },
  },
  {
    sideA: {
      img: analyticsEnterprises,
      text: "Análisis de reciclabilidad",
      orientation: "vertical",
      sideText: "bottom",
      gridOption: "",
    },
    sideB: {
      text: "Descubre si tus empaque son reciclajes en México",
      sideText: "top",
    },
  },
  {
    sideA: {
      img: learningEnterprises,
      text: "Talleres",
      orientation: "vertical",
      sideText: "top",
      gridOption: "",
    },
    sideB: {
      text: "Capacitación sobre reciclaje",

      sideText: "top",
    },
  },
  {
    sideA: {
      img: campaignEnterprises,
      text: "Campañas de reciclaje",
      orientation: "vertical",
      sideText: "top",
      gridOption: "",
    },
    sideB: {
      text: "Educamos y hacemos que tu consumidor recicle",
      sideText: "top",
    },
  },
  {
    sideA: {
      img: internalProgramsEnterprises,
      text: "Programas internos de reciclaje",
      orientation: "vertical",
      sideText: "bottom",
      gridOption: "md:row-span-2",
    },
    sideB: {
      text: "Motiva a tus colaboradores a reciclar",
      sideText: "top",
    },
  },
  {
    sideA: {
      img: programmingEnterprises,
      text: "Programas de acopio a gran volúmen",
      orientation: "horizontal",
      sideText: "left",
      gridOption: "md:col-span-2",
    },
    sideB: {
      text: "Trabajo con centro de acopio para incrementar tus tasas de reciclaje",
      sideText: "top",
    },
  },
  {
    sideA: {
      img: recycleFriendsEnterprises,
      text: "Amigos del reciclaje",
      orientation: "vertical",
      sideText: "top",
      gridOption: "",
    },
    sideB: {
      text: "Ayuda a tus consumidores a reciclar, programas para pymes",
      sideText: "top",
    },
  },
];
