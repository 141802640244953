import type { Config } from "tailwindcss";

const config = {
  darkMode: ["class"],
  content: [
    "./pages/**/*.{ts,tsx}",
    "./components/**/*.{ts,tsx}",
    "./app/**/*.{ts,tsx}",
    "./src/**/*.{ts,tsx}",
  ],
  prefix: "",
  theme: {
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      colors: {
        border: "hsl(var(--border))",
        input: "hsl(var(--input))",
        ring: "hsl(var(--ring))",
        background: "hsl(var(--background))",
        foreground: "hsl(var(--foreground))",
        primary: {
          DEFAULT: "hsl(var(--primary))",
          foreground: "hsl(var(--primary-foreground))",
        },
        secondary: {
          DEFAULT: "hsl(var(--secondary))",
          foreground: "hsl(var(--secondary-foreground))",
        },
        destructive: {
          DEFAULT: "hsl(var(--destructive))",
          foreground: "hsl(var(--destructive-foreground))",
        },
        muted: {
          DEFAULT: "hsl(var(--muted))",
          foreground: "hsl(var(--muted-foreground))",
        },
        accent: {
          DEFAULT: "hsl(var(--accent))",
          foreground: "hsl(var(--accent-foreground))",
        },
        popover: {
          DEFAULT: "hsl(var(--popover))",
          foreground: "hsl(var(--popover-foreground))",
        },
        card: {
          DEFAULT: "hsl(var(--card))",
          foreground: "hsl(var(--card-foreground))",
        },
        ecolana: {
          lightGreen: "#2cad8e",
          black: "#1B2C32",
          lightGray: "#B4B4B4",
          mediumGreen: "#40B59A",
          darkGreen: "#12675b",
          red: "#FF5071",
          wasteGreen: "#2CAD8F",
          wasteYellow: "#EEB932",
          wasteRed: "#CE1126",
          titlesGreen: "#047456",
          mediumBlue: "#21ADE5",
        },
        admins: {
          button: {
            green: "#2CAD8E",
            red: "#F63F34",
            gray: "#E1E1E1",
          },
          input: {
            active_bg: "#FFFFFF",
            disabled_bg: "#F1F1F1",
          },
          graphics: {
            level1: "#0842DA",
            level2: "#48D3E4",
            level3: "#C6E9B4",
            level4: "#FFE78A",
            level5: "#A7ACFF",
            level6: "#083221",
            level7: "#00F0A9",
            level8: "#F3F306",
            level9: "#A363E7",
            level10: "#FFB2C7",
            third_gender: "#F3F306",
            male: "#00F0A9",
            female: "#A363E7",
            other_gender: "#48D3E4",
            age_range_1: "#0842DA",
            age_range_2: "#48D3E4",
            age_range_3: "#C6E9B4",
            age_range_4: "#FFE78A",
            age_range_5: "#A7ACFF",
            other: "#229C79",
            total_scans: "#0842DA",
            scans_without_badge: "#A7ACFF",
            scans_with_badge: "#FFB2C7",
            total_validations: "#0842DA",
            pending_validations: "#F3F306",
            approved_validations: "#229C79",
            rejected_validations: "#F63F34",
          },
          text: {
            default: "#083221",
            active: "#047456",
            select_items: "#313144",
            overview: "#5BB79C",
            inactive: "#9291A5",
          },
          select: {
            selected_items_bg: "#229C7940",
            unselected_items_bg: "#FFFFFF",
          },
          labels: {
            border: "#79747E",
            text: "#AAAAAA",
          },
          collection_centers: "#C6D2FD",
          green_points: "#5BB79D",
          collectors: "#FFDA1B",
          activity_general_green: "#047456",
          activity_scan_yellow: "#FFE78A",
          activity_recycling_violet: "#A7ACFF",
          activity_evidence_blue: "#48D3E4"
        },
      },
      borderRadius: {
        lg: "var(--radius)",
        md: "calc(var(--radius) - 2px)",
        sm: "calc(var(--radius) - 4px)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
      fontFamily: {
        gotham: "var(--font-gotham)",
        avenir: "var(--font-avenirnext)",
      },
      fontSize: {
        xxs: "0.625rem",
      },
    },
  },
  plugins: [require("tailwindcss-animate")],
} satisfies Config;

export default config;
