import { colors_admin } from "@/styles/fullConfig";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
const options = {
  responsive: true,
  plugins: {
    title: {
      display: false,
      text: "Chart.js Line Chart - Cubic interpolation mode",
    },
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      display: true,
      title: {
        display: true,
      },
    },
    y: {
      display: true,
      title: {
        display: true,
        text: "Value",
      },
      suggestedMin: -10,
      suggestedMax: 200,
    },
  },
};
const datapoints = [0, 20, 20, 60, 60, 120, 123, 180, 120, 125, 105, 110, 170];

const DATA_COUNT = datapoints.length;
const labels = [];
for (let i = 0; i < DATA_COUNT; ++i) {
  labels.push(i.toString());
}

const dataChart: ChartData<
  "line",
  (number | [number, number] | null)[],
  unknown
> = {
  labels: labels,
  datasets: [
    {
      label: "Cubic interpolation (monotone)",
      data: datapoints,
      borderColor: colors_admin.graphics.level1,
      fill: false,
      cubicInterpolationMode: "monotone",
      tension: 0.4,
    },
    {
      label: "Cubic interpolation",
      data: datapoints.flatMap((item) => item + 100 * Math.random()),
      borderColor: colors_admin.graphics.level2,
      cubicInterpolationMode: "monotone",
      fill: false,
      tension: 0.4,
    },
    {
      label: "Linear interpolation (default)",
      data: datapoints.flatMap((item) => item * 2),
      cubicInterpolationMode: "monotone",
      tension: 0.4,
      borderColor: colors_admin.graphics.level3,
    },
  ],
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const LineChart = ({
  data = dataChart,
}: {
  data?: ChartData<"line", (number | [number, number] | null)[], unknown>;
}) => {
  return (
    <div className="w-full flex items-center justify-center">
      <Line className="h-full" options={options} data={data} />
    </div>
  );
};
