import { Button } from "@/components/atoms/shadcn/ui/button";
import { CampaignProps } from "@/constants/campaignsData";
import Image from "next/image";
import Link from "next/link";

export const CampaignCardIntro = ({
  campaign,
}: {
  campaign: CampaignProps;
}) => {
  return (
    <div className="flex flex-col w-full md:w-4/12 min-h-[50vh] text-center justify-center items-center space-y-6">
      <Image src={campaign?.logo} alt="Logo Campaña" className="w-2/3 2xl:w-1/2" />
      <p className="text-ecolana-wasteGreen text-2xl 2xl:text-4xl text-center font-extrabold">
        {campaign?.id === "28" ? "¿Qué fomentamos?" : "¿Qué reciclamos?"}
      </p>
      <p className="text-center text-xl 2xl:text-3xl px-10 text-ecolana-black">
        {campaign?.recycleText}
      </p>
      {(campaign?.id !== "28") && (!campaign.hide_description) && (
        <Link className="w-2/3" href="#map-campaign">
          <Button className="text-md w-full" variant={"ecolanaLearnMore"}>
            ¿Dónde reciclar?
          </Button>
        </Link>
      )}
    </div>
  );
};
