import Link from "next/link";
import React from "react";
import { useTranslations } from "next-intl";
import Image from "next/image";

export const FooterRe = () => {
  const t = useTranslations();
  const year = new Date().getFullYear();
  return (
    <section
      className="w-full flex flex-col justify-center items-center px-10"
      style={{
        backgroundImage:
          "url('/images/re/leaves.png'),url('/images/re/green_bg_full.jpg')",
        backgroundSize: "contain, cover",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "center top, center",
        backgroundBlendMode: "normal",
      }}
    >
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 my-4 text-white font-bold text-lg md:mt-80 mt-20">
        <div className="w-full md:w-4/12 p-3">
          <Link
            className="flex w-full h-full items-end align-bottom relative overflow-hidden rounded-3xl"
            href={"https://trashlater.ecolana.com.mx"}
          >
            <Image
              src="/images/re/banner1.png"
              alt="logo"
              className="w-full"
              width={722}
              height={536}
            />
            <div className="absolute bottom-10 left-5">
              <p style={{ textShadow: "1px 1px 1px black" }}>
                Conoce Trash-Later
              </p>
              <p style={{ textShadow: "1px 1px 1px black" }}>
                El primer traductor de basura de Nestlé
              </p>
            </div>
          </Link>
        </div>
        <div className="w-full md:w-4/12 p-3 text-lg">
          <Link
            className="flex w-full h-full items-end align-bottom relative overflow-hidden rounded-3xl"
            href={"https://trashlater.ecolana.com.mx/iniciativa"}
          >
            <Image
              src="/images/re/banner2.png"
              alt="logo"
              className="w-full"
              width={722}
              height={536}
            />
            <div className="absolute bottom-10 left-5">
              <p style={{ textShadow: "1px 1px 2px black" }}>
                Iniciativa Nestlé
              </p>
              <p style={{ textShadow: "1px 1px 2px black" }}>
                El primer traductor de basura de Nestlé
              </p>
            </div>
          </Link>
        </div>
        <div className="w-full md:w-4/12 p-3">
          <Link
            className="flex w-full h-full items-end align-bottom relative overflow-hidden rounded-3xl"
            href={"https://www.nestle.com.mx/csv/reciclando-con-nestle"}
          >
            <Image
              src="/images/re/banner3.png"
              alt="logo"
              className="w-full"
              width={722}
              height={536}
            />
            <div className="absolute bottom-10 left-5 shadow-black">
              <p style={{ textShadow: "1px 1px 2px black" }}>
                Nestlé Corporativo
              </p>
              <p style={{ textShadow: "1px 1px 2px black" }}>
                Conoce más sobre nosotros
              </p>
            </div>
          </Link>
        </div>
      </div>
      <hr className="rounded-3xl border-white border-2 text-white opacity-100 mt-[5%] w-[100%]" />
      <div className="w-full text-white text-center text-sm space-y-5 my-5">
        <p>© {year} Trash-Later</p>
        <p>
          Todos los derechos reservados. El uso y acceso a la información del
          presente sitio está sujero a los Términos y condiciones de uso
          establecidos en nuestro acuerdo legal. <br />
           Consulta nuestro
          <Link href="/aviso" className="hover:underline">
            Aviso de privacidad
          </Link>
        </p>
      </div>
    </section>
  );
};
