import { SelectWithSearch } from "@/components/atoms";
import { CampaignsOptions } from "@/constants/campaignsData";
import { ButtonHTMLAttributes } from "react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";

export type Props<T extends FieldValues> =
  ButtonHTMLAttributes<HTMLButtonElement> & {
    control: Control<T, Object>;
    name: Path<T>;
    isEnable?: boolean;
    placeholder: string;
    errors?: string;
    selectedItems: CampaignsOptions[];
    addToList?: boolean;
  };

export const ControlledSelectWithSearch = <T extends FieldValues>({
  name,
  control,
  isEnable = true,
  selectedItems,
  placeholder,
  className,
  addToList,
}: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { name, onChange, value } }) => (
        <SelectWithSearch
          disabled={!isEnable}
          placeHolder={placeholder}
          name={name}
          addToList={addToList}
          value=""
          className={className}
          setValue={
            addToList
              ? (select) => {
                  const newSelected = value.includes(select)
                    ? value.filter((uni: string) => uni !== select)
                    : [...value, select];
                  onChange(newSelected);
                }
              : onChange
          }
          selectedItems={selectedItems}
        />
      )}
    />
  );
};
