import { CardBGGradient } from "@/components/atoms";
import { TabsContent } from "@/components/atoms/shadcn/ui/tabs";
import {
  FilterPerYear,
  WasteValidationsContainer,
  BarChartHistoricValidations,
  BarChartHorizontal,
} from "@/components/molecules";
import { useCheckRefetch } from "@/hooks/useCheckRefetch";
import { useGraphicsData } from "@/hooks/useGraphicsData";
import { fetcherOverviewData } from "@/pages/admins/escaner/overview";
import { generateGraphic } from "@/shared/functions/generateGraphic";
import { useQuery } from "@tanstack/react-query";
import { Controller } from "react-hook-form";
import { FilterPerYearController } from "../FilterPerYearController/FilterPerYearController";
import { Session } from "next-auth";

type EscanerActivityProps = {
  session: Session | null;
};

export const EscanerActivity = ({ session }: EscanerActivityProps) => {
  const { control, ranges, years, global } = useGraphicsData({
    keysRanges: [
      "enterprises",
      "brands",
      "products",
      "waste_category",
      "wastes",
      "badges",
      "recycler_unique",
      "recycler_average",
      "recyclers",
      "origin",
    ],
    keysYear: ["year_history"],
  });
  const { data: stats, refetch } = useQuery({
    queryKey: ["overviewScan"],
    enabled: session?.user?.token ? true : false,
    queryFn: async () => {
      return session?.user?.token
        ? await fetcherOverviewData({
            token: session?.user?.token!,
            ranges,
            global,
            years,
          })
        : null;
    },
  });
  useCheckRefetch(refetch, global, ranges, years);
  return (
    <TabsContent value="Activity">
      <div className="flex w-full  flex-wrap items-center justify-around px-5 py-3  gap-4">
        <div className="w-full flex justify-end px-5 items-center">
          <Controller
            name={"globalRange"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FilterPerYear value={value} rangeOnChange={onChange} />
            )}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 px-5 py-2 gap-4 my-14">
        <WasteValidationsContainer
          className="col-span-2"
          title="Historial de escaneos"
          filter={
            <FilterPerYearController
              name={"graphsYear.year_history"}
              control={control}
            />
          }
        >
          <BarChartHistoricValidations
            data={generateGraphic(stats as any, "history", true) as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Empresas más escaneadas (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.enterprises"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "enterprises") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Marcas más escaneadas (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.brands"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "brands") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Productos más escaneados (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.products"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "products") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Categorías más escaneadas (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.waste_category"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "waste_category") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Residuos más escaneados (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.wastes"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "wastes") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Escaneos por insignia (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.badges"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "badges") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Usuarios que han escaneado al menos una vez"
          filter={
            <FilterPerYearController
              name={"graphsRanges.recycler_unique"}
              control={control}
            />
          }
        >
          <CardBGGradient
            key={`CardBG_default`}
            variant={"defaultValue"}
            className="w-1/2 aspect-square items-center"
          >
            <p className=" text-admins-text-active text-7xl items-center font-extrabold">
              {stats &&
              stats["unique_recycler"] &&
              stats["unique_recycler"][0]?.value
                ? Number(stats["unique_recycler"][0]?.value).toFixed(0)
                : 0}
            </p>
          </CardBGGradient>
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Promedio de escaneos por usuarios"
          filter={
            <FilterPerYearController
              name={"graphsRanges.recycler_average"}
              control={control}
            />
          }
        >
          <CardBGGradient
            key={`CardBG_default`}
            variant={"defaultValue"}
            className="w-1/2 aspect-square items-center"
          >
            <p className=" text-admins-text-active text-7xl items-center font-extrabold">
              {(stats &&
                stats["average_per_recycler"] &&
                (stats["average_per_recycler"][0]?.value as string)) ??
                0}
            </p>
          </CardBGGradient>
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Usuarios con más escaneos (TOP 10)"
          filter={
            <FilterPerYearController
              name={"graphsRanges.recyclers"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "recyclers") as any}
          />
        </WasteValidationsContainer>
        <WasteValidationsContainer
          title="Origen de escaneos"
          filter={
            <FilterPerYearController
              name={"graphsRanges.origin"}
              control={control}
            />
          }
        >
          <BarChartHorizontal
            data={generateGraphic(stats as any, "origin") as any}
          />
        </WasteValidationsContainer>
      </div>
    </TabsContent>
  );
};
