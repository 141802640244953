import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from "react";

export const SwitchVariants = cva(
  "peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      variantBG: {
        default:
          "data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
        adminGreen:
          "data-[state=checked]:bg-[#2CAD8F] data-[state=unchecked]:bg-input",
      },
    },
    defaultVariants: {
      variantBG: "default",
    },
  }
);

interface SwitchWithVariant
  extends ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>,
    VariantProps<typeof SwitchVariants> {}

const Switch = forwardRef<
  ElementRef<typeof SwitchPrimitives.Root>,
  SwitchWithVariant
>(({ className, variantBG, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(SwitchVariants({ variantBG, className }))}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
