import { FC, RefObject, useCallback, useState } from "react";
import Map, {
  LngLat,
  MapRef,
  Marker,
  MarkerDragEvent,
  NavigationControl,
} from "react-map-gl";
import { DraggableMarker } from "./DraggableMarker";
import { CC_Form } from "@/interfaces";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
const defaultLocale = {
  "AttributionControl.ToggleAttribution": "Alternar atribución",
  "AttributionControl.MapFeedback": "Comentarios del mapa",
  "FullscreenControl.Enter": "Entrar en pantalla completa",
  "FullscreenControl.Exit": "Salir de pantalla completa",
  "GeolocateControl.FindMyLocation": "Encontrar mi ubicación",
  "GeolocateControl.LocationNotAvailable": "Ubicación no disponible",
  "LogoControl.Title": "Logo de Mapbox",
  "Map.Title": "Mapa",
  "NavigationControl.ResetBearing": "Restablecer orientación al norte",
  "NavigationControl.ZoomIn": "Acercar",
  "NavigationControl.ZoomOut": "Alejar",
  "ScrollZoomBlocker.CtrlMessage":
    "Usa ctrl + desplazamiento para acercar el mapa",
  "ScrollZoomBlocker.CmdMessage": "Usa ⌘ + desplazamiento para acercar el mapa",
  "TouchPanBlocker.Message": "Usa dos dedos para mover el mapa",
};

interface MapAdminProps {
  mapRef: RefObject<MapRef>;
  customMarker?: string;
  scrollZoom?: boolean;
  control: Control<CC_Form, any>;
  setValue_CC: UseFormSetValue<CC_Form>;
  watch: UseFormWatch<CC_Form>;
}
export const MapAdmin: FC<MapAdminProps> = ({
  mapRef,
  scrollZoom = false,
  control,
  watch,
  setValue_CC,
}: MapAdminProps) => {
  const onMarkerDrag = useCallback((event: MarkerDragEvent) => {
    setValue_CC("address.lat", event.lngLat.lat);
    setValue_CC("address.lng", event.lngLat.lng);
  }, []);
  return (
    <Map
      id="mapAdmin"
      ref={mapRef}
      initialViewState={{
        longitude: -99.13321738713675,
        latitude: 19.432499068074158,
        zoom: 4,
      }}
      mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v12"
      locale={defaultLocale}
      scrollZoom={scrollZoom}
    >
      <DraggableMarker
        lng={watch("address.lng") || -99.13321738713675}
        lat={watch("address.lat") || 19.432499068074158}
        // onMarkerDrag={onMarkerDrag}
        pin={"https://cdn.ecolana.com.mx/assets/acopio.png"}
      />
      <NavigationControl />
    </Map>
  );
};
